.container {
    padding: 0;
    margin: 0;
    font-family: var(--typo-outline-title-font-family);
    letter-spacing: 0.05rem;
    line-height: 1;
    /* text-shadow: -0.75px 0.75px 0.75px #222,
        0.75px 0.75px 0.75px #222,
        0.75px -0.75px 0.75px #222,
        -0.75px -0.75px 0.75px #222; */
    text-transform: uppercase;
}
