.container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: inherit;
    text-decoration: none;
}

.thumbnail {
    position: relative;
    min-width: var(--card-thumbnail-width, 100px);
    max-width: var(--card-thumbnail-width, 100px);
}

.label {
    flex-grow: 1;
}
