.placeholder {
    /* display: inline-block; */
    opacity: 0.2;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: pulse;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
    letter-spacing: -0.1em !important;
    white-space: pre;
/*
    width: 4em;
    height: 1em;
    background: #999; */

    &::after {
        content: '███████';
    }

    [data-transitioning] & {
        animation-play-state: paused;
    }
}

@keyframes pulse {
    0% {
        opacity: 0.2;
    }

    50% {
        opacity: 0.1;
    }

    100% {
        opacity: 0.2;
    }
}
