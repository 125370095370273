.container {
    --card-color: var(--brand-text-color);

    position: relative;
    padding-bottom: var(--block-spacing);

    .featuredBlock {
        --card-color-title: var(--brand-primary-color);
    }

    .blocks {
        --block-spacing: 60px;
    }
}
