.container {

}

.date {
    margin-bottom: 5px;
}

.items {
    margin: -2px;
}

.button {
    margin: 2px;
}
