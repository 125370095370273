.container {
    color: var(--control-color);

    .fieldGroup {
        position: relative;
        width: 100%;

        .field {
            width: 100%;
        }

        &.hasStatus {
            .input {
                padding-right: calc(var(--control-padding) + 1em);
            }
        }
    }

    .status {
        position: absolute;
        top: 50%;
        right: calc(var(--control-padding) / 2);
        transform: translateY(-50%);
    }

    .button {
        width: 100%;
        border-radius: var(--control-border-radius);
        margin-top: 5px;
        font-size: 1em;
    }

    .spacer {
        margin-top: 10px;
    }

    .sso {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: var(--spacing);
        margin: -5px;

        .ssoButton {
            width: auto;
            margin: 5px;
        }
    }
}
