[data-brand='urbania'] {
    --brand-primary-color: var(--color-black);
    --brand-text-color: var(--color-black);
    --text-on-primary-color: var(--color-white);

    /* Background */
    /* --article-background-color: #fff; */
    --page-background-color: #fff4e9;
    --page-background: var(--page-background-color) var(--paper1) top left / 200px 200px repeat;

    /* Menu */
    --menu-header-background: #fff var(--paper1) top left / 128px 128px repeat;
    --menu-header-color: var(--color-black);
    --menu-header-section-background: var(--color-black);
    --menu-header-section-color: var(--color-white);
    --menu-header-index-background: var(--color-white);
    --menu-header-index-color: var(--color-black);
    --menu-header-border: solid 1px rgba(0 0 0 / 0.2);
    --menu-top-button-background: var(--color-black);
    --menu-top-button-color: var(--color-white);
}

[data-brand='quatre95'] {
    --brand-primary-color: var(--color-quatre95);
    --brand-text-color: var(--color-quatre95-text);
    --text-on-primary-color: var(--color-white);

    /* Background */
    /* --article-background-color: #fff; */
    --page-background-color: #EDEEFE;
    --page-background: var(--page-background-color) url('../assets/img/quatre95_texture.png') top left / 200px 200px repeat;

    /* Typo */
    --typo-section-title-font-family: var(--font-quatre95);

    /* Menu */
    --menu-header-background: linear-gradient(180deg, var(--color-quatre95) 0%, #8f9ff1 100%);
    --menu-header-color: #EDEEFE;
    /* --menu-header-section-background: var(--color-white);
    --menu-header-section-color: var(--color-quatre95); */

    /* Header */
    --header-surtitle-font-family: var(--typo-quatre95-font-family);
    --header-surtitle-font-weight: 400;

    /* Body */
    --body-heading-font-family: var(--typo-quatre95-font-family);
    --body-heading-font-size: 1.1em;
    --body-heading-font-size-medium: 1.2em;
    --body-heading-font-weight: var(--typo-quatre95-font-weight);
    --body-heading-text-transform: none;
    --body-heading-letter-spacing: -0.01em;
    --body-quote-font-family: var(--typo-quatre95-font-family);
    --body-quote-font-weight: var(--typo-quatre95-font-weight);
    --body-quote-font-size: 1.25em;
    --body-quote-font-size-medium: 1.7em;
    --body-quote-letter-spacing: -0.05em;
    --body-quote-line-height: 1.1;
    --body-quote-caption-font-family: var(--typo-quatre95-font-family);
    --body-quote-caption-font-weight: 400;
}

[data-brand='dehors'] {
    --brand-primary-color: var(--color-dehors);
    --brand-text-color: var(--color-dehors-text);
    --text-on-primary-color: var(--color-white);

    /* Background */
    /* --article-background-color: #f0f3f6; */
    --page-background-color: var(--color-beige);
    --page-background: var(--page-background-color) var(--paper3) top left / 200px 200px repeat;

    /* Typo */
    --typo-section-title-font-family: var(--font-dehors);

    /* Menu */
    --menu-header-background: linear-gradient(180deg, var(--color-dehors) 0%, #738e45 100%);
    --menu-header-color: #fec;
    /* --menu-header-section-background: #fec;
    --menu-header-section-color: var(--color-dehors); */

    /* Header */
    --header-index-border: solid 0.15em var(--color-dehors);
    --header-surtitle-font-family: var(--typo-dehors-font-family);
    --header-surtitle-font-weight: var(--typo-dehors-font-weight);

    /* Body */
    --body-heading-font-family: var(--typo-dehors-font-family);
    --body-heading-font-size: 1em;
    --body-heading-font-size-medium: 1.15em;
    --body-heading-text-transform: none;
    --body-quote-font-family: var(--typo-dehors-font-family);
    --body-quote-font-size: 1.5em;
    --body-quote-font-size-medium: 2em;
    --body-quote-font-weight: 500;
    --body-quote-text-transform: none;
    --body-quote-letter-spacing: -0.04em;
    --body-quote-caption-font-family: var(--typo-dehors-font-family);
    --body-quote-caption-font-weight: 400;
    --body-quote-caption-letter-spacing: -0.025em;
}

[data-brand='mollo'] {
    --brand-primary-color: var(--color-mollo);
    --brand-text-color: var(--color-mollo-text);
    --text-on-primary-color: var(--color-white);

    /* Background */
    /* --article-background-color: #fff8ee; */
    --page-background-color: #fff8ee;
    --page-background: var(--page-background-color) var(--paper2) top left / 200px 200px repeat;

    /* Typo */
    --typo-section-title-font-family: var(--font-wide);
    --typo-section-title-text-transform: none;

    /* Menu */
    --menu-header-background: linear-gradient(180deg, var(--color-mollo) 0%, #b659ff 100%);
    --menu-header-color: #dfccff;
    /* --menu-header-section-background: var(--color-white);
    --menu-header-section-color: var(--color-mollo); */

    /* Body */
    --body-heading-font-family: var(--font-tight);
    --body-heading-font-size: 1.15em;
    --body-heading-font-size-medium: 1.25em;
    --body-heading-text-transform: none;
    --body-quote-font-family: var(--font-wide);
    --body-quote-font-size: 1.2em;
    --body-quote-font-size-medium: 1.6em;
    --body-quote-letter-spacing: -0.01em;
    --body-quote-line-height: 1.1;
    --body-quote-text-transform: none;
}

[data-brand='periodelibre'] {
    --brand-primary-color: var(--color-periodelibre);
    --brand-text-color: var(--color-periodelibre-text);
    --text-on-primary-color: var(--color-white);

    /* Background */
    /* --article-background-color: #fff; */
    --page-background-color: #fff4e9;
    --page-background: var(--page-background-color) var(--paper1) top left / 200px 200px repeat;

    /* Typo */
    --typo-section-title-font-style: italic;
    --typo-section-title-font-weight: 800;

    /* Menu */
    --menu-header-background: linear-gradient(180deg, var(--color-periodelibre) 0%, #ff7a47 100%);
    --menu-header-color: #eaeaea;
    /* --menu-header-section-background: #eaeaea;
    --menu-header-section-color: var(--color-periodelibre); */

    /* Body */
    --body-quote-font-family: var(--font-tight);
    --body-quote-font-size: 1.25em;
    --body-quote-font-size-medium: 1.8em;
    --body-quote-font-style: italic;
    --body-quote-letter-spacing: -0.02em;
    --body-quote-line-height: 1.05;
    --body-quote-caption-font-family: var(--font-tight);
}

[data-brand='france'] {
    --brand-primary-color: var(--color-france);
    --brand-text-color: var(--color-france-text);
    --text-on-primary-color: var(--color-white);

    /* Background */
    /* --article-background-color: #fff; */
    --page-background-color: #def;
    --page-background: var(--page-background-color) var(--paper1) top left / 200px 200px repeat;

    /* Menu */
    --menu-header-background: linear-gradient(180deg, var(--color-france) 0%, #465FF7 100%);
    --menu-header-color: #fff;
    --menu-header-border: solid 1px rgba(255 255 255 / 0.2);
    /* --menu-header-color: var(--color-white);
    --menu-header-section-background: var(--color-white);
    --menu-header-section-color: var(--color-france); */
}
