.container {
    .image {
        position: relative;
        width: 150px;
        height: 150px;
        grid-area: image;
    }

    .description {
        /* display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden; */

        p, br {
            display: none;
            margin: 0;
        }

        p:first-child {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
        }
    }
}
