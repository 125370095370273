.container {
    .brandIcon {
        position: absolute;
        z-index: 2;
        top: 0.5em;
        right: 0.5em;
        display: block;
        width: 1em;
        height: 1em;
    }

    &.empty {
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        background: rgba(255 255 255 / 0.5);
        color: rgba(0 0 0 / 0.1);
        font-family: var(--font-garage);
        letter-spacing: -0.04em;
        font-size: 6rem;
        line-height: 0.9;
        text-transform: uppercase;
        /* word-break: break-all; */
    }
}

