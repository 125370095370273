.container {
    padding: 0;
    margin: 0;
    font-family: var(--typo-category-font-family);
    font-weight: var(--typo-category-font-weight);
    letter-spacing: var(--typo-category-letter-spacing);
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
}
