.container {
    display: block;
    text-align: inherit;
    text-decoration: none;
}

.inner {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
}

.thumbnail {
    position: relative;
    width: 100%;
}

.footer,
.label {
    display: block;
    width: 100%;
}

.label {
    flex-grow: 1;
}
