.container {
    position: relative;
    display: flex;
    flex-direction: column;

    .shape {
        position: relative;
        z-index: 1;
        flex-grow: 1;
    }

    .embed {
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .blockButton {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        appearance: none;
        background: transparent;
    }

    .muteIcon {
        position: absolute;
        z-index: 2;
        top: 1em;
        right: 1em;
        width: 2em;
        height: 2em;
        color: var(--color-white);
        opacity: 0.9;

        &::after {
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            display: block;
            width: 300%;
            height: 300%;
            background: radial-gradient(circle, rgba(0 0 0 / 0.4) 0%, rgba(0 0 0 / 0) 46%);
            content: '';
            transform: translate(-50%, -50%);
        }
    }

    .controls {
        position: relative;
        z-index: 2;
        width: 100%;
    }

    .captions {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
        padding: 10px;
        background: #000;
        color: #fff;
        font-size: 2em;
    }

    &.controlsOver {
        .controls {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    &.cover {
        .embed {
            object-fit: cover;
        }
    }
}
