.container {
    background: var(--color-white);

    .inner {
        padding: 10px 0;
    }

    .urbania {
        position: absolute;
        top: var(--spacing);
        left: var(--spacing);
        opacity: 0;
    }

    &.entered,
    &.entering {
        .urbania {
            opacity: 1;
        }
    }

    &.leaving {
        .urbania {
            opacity: 0;
        }
    }
}
