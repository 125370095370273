.container {
    .label {
        display: block;
        font-size: var(--control-label-font-size);
    }

    .errors {
        margin-top: var(--control-spacing);
        font-size: var(--control-label-font-size);

        ul,
        li {
            padding: 0;
            margin: 0;
            list-style: none;
        }
    }

    &.isRequired {
        .label::after {
            display: inline-block;
            margin-left: 0.25em;
            content: '*';
        }
    }
}
