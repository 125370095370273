:root {
    /* Spacing */
    --map-top-spacing-small: 60px;
    --map-top-spacing: 100px;
    --map-side-spacing-small: 10px;
    --map-side-spacing: 20px;
    --map-bottom-spacing: 40px;

    /* Colors */
    --map-button-color: var(--color-beige);
    --map-button-transparency-color: rgba(0, 0, 0, 0.06);
    --map-hover-color: #d5c8bd;
    --map-active-color: #d5c8bd;
    --map-pin-active-color: #ccff00;
    --map-sidebar-background-color: var(--color-beige);

    /* Element font size */
    --map-title-font-size: 1.875em;

    /* Buttons */
    --map-button-border-radius: 10px;

    /* Markers */
    --map-marker-hover-color: var(--map-hover-color);

    /* Cluster Marker */
    --map-cluster-marker-box-shadow: 0 1px 4px 0px rgba(0 0 0 / 0.25);
    --map-cluster-marker-border: 1px solid var(--color-urbania-black);

    /* Sidebar */
    --map-sidebar-width: 400px;
    --map-sidebar-min-height: 200px;
    --map-sidebar-box-shadow-mobile: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
    --map-sidebar-box-shadow: 0px 4px rgba(0, 0, 0, 0.25);
}
