.container {
    --card-color: var(--brand-text-color);

    padding-bottom: var(--spacing);
    background: var(--page-background);

    .menuLabel {
        font-size: 0.75rem;
    }

    .filters {
        padding: 0 var(--side-spacing);
        margin-bottom: var(--block-spacing);
    }

    .series {
        margin-bottom: var(--block-spacing);
    }

    .sectionTitle {
        padding: 0 var(--side-spacing);
        margin-bottom: var(--spacing);
    }
}
