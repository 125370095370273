.container {
    display: inline-block;
    padding: 0;
    border: 0;
    margin: 0;
    appearance: none;
    background: transparent;
    color: inherit;
    cursor: pointer;
    font-family: var(--button-font-family);
    font-weight: var(--button-font-weight);
    letter-spacing: var(--button-letter-spacing);
}
