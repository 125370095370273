.container {
    position: relative;

    .inner {
        position: relative;
        z-index: 2;
        padding: 0 var(--side-spacing);
    }

    .item {
        margin-bottom: var(--block-spacing);

        &.isOver {
            height: 50vh;
            height: 50lvh;
            min-height: 500px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (--medium-viewport) {
        .item {
            &.isOver {
                height: 80vh;
                height: 80lvh;
                min-height: 500px;
            }
        }
    }

    @media (--large-viewport) {
        .item {
            &.isOver {
                min-height: 700px;
            }
        }
    }
}
