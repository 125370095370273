.container {
    border: var(--control-border);
    border-radius: var(--control-border-radius);
    appearance: none;
    background: var(--control-background);

    .input {
        width: 100%;
        border: 0;
        background: transparent;
    }
}

.phone {
    width: 100%;
}
