html,
body {
    padding: 0;
    margin: 0;
    min-height: 100%;
}

body {

}

a {
    color: inherit;
}
