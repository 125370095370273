.container {
    width: 100%;
    font-family: var(--block-table-font-family, var(--font-tight));

    [data-theme='quatre95_account'] & {
        padding: 0 var(--spacing) var(--spacing);
        border: 1px solid var(--color-quatre95);
        border-radius: 1em;
        font-family: var(--font-quatre95);
        font-size: 1rem;
        font-weight: 500;
        text-transform: uppercase;

        tr {
            width: 100%;
        }

        td {
            p {
                margin: 0;
            }
        }

        thead {
            th {
                padding: var(--spacing);
                text-align: center;
            }
        }

        tbody {
            tr {
                display: flex;
                align-items: center;
                padding: var(--spacing);
                border: 1px solid var(--color-quatre95);
                border-radius: 1em;
                margin-bottom: var(--spacing-small);
                background-color: var(--color-quatre95-very-light-blue);
            }

            td {
                font-size: 16px;

                span,
                p:not(:first-of-type) {
                    font-size: 14px;
                    text-transform: none;
                }

                &:first-child {
                    margin-right: 20px;
                }

                &:last-child {
                    margin-left: auto;
                    font-family: var(--font-garage);
                    font-size: 3em;
                    text-align: right;
                    white-space: nowrap;
                }
            }
        }

        tfoot {
            tr {
                display: block;
                border-radius: 14px;
                background-color: var(--color-quatre95);
                color: var(--color-quatre95-very-light-blue);
            }

            td {
                display: block;
                padding: 10px;
                line-height: 1.1;
                text-align: center;

                strong {
                    font-family: var(--font-garage);
                    font-size: 3em;
                }
            }
        }

        &[data-theme='red'] {
            border-color: var(--color-quatre95-red);
            color: var(--color-quatre95-red);

            tbody {
                tr {
                    border-color: var(--color-quatre95-red);
                    background-color: var(--color-quatre95-pink);
                }
            }

            tfoot {
                tr {
                    background-color: var(--color-quatre95-red);
                    color: var(--color-quatre95-pink);
                }
            }
        }

        &[data-theme='inverted-red'] {
            background-color: var(--color-quatre95-red);
            color: var(--color-quatre95-pink);
        }
    }
}
