.container {
    svg {
        width: 100%;
        height: 100%;
    }

    .count {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        width: 1.5em;
        height: 1.5em;
        align-items: center;
        justify-content: center;
        padding-top: 0.1em;
        border-radius: 50%;
        background-color: var(--color-black);
    }
}
