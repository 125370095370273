.container {
    --card-color: var(--brand-text-color);

    position: relative;
    padding-bottom: var(--block-spacing);

    .blocks{
        --block-spacing: 60px;
    }
}
