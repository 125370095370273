.container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100vw;
    width: 100svw;
    height: 100vh;
    height: 100svh;
    contain: size layout style;
    overflow-y: auto;
    pointer-events: all;

    .inner {
        position: relative;
        z-index: 2;
        max-width: 100%;
        padding: var(--side-spacing);
        margin: auto;
    }

    .backdrop {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        appearance: none;
        background: var(--modal-backdrop-color);
        color: inherit;
        cursor: default;
        font-family: inherit;

        &:focus {
            outline: none;
        }
    }

    &.isTransitioning {
        overflow: hidden;
    }

    &.top {
        .inner {
            margin-top: 0;
        }
    }

    &.bottom {
        .inner {
            margin-bottom: 0;
        }
    }

    &.left {
        .inner {
            margin-left: 0;
        }
    }

    &.right {
        .inner {
            margin-right: 0;
        }
    }
}
