.container {
    padding: 0;
    padding-top: 0.3em;
    margin: 0;
    font-family: var(--typo-section-title-font-family);
    font-size: var(--typo-section-title-font-size);
    font-style: var(--typo-section-title-font-style, normal);
    font-weight: var(--typo-section-title-font-weight);
    letter-spacing: var(--typo-section-title-letter-spacing);
    line-height: 1;
    text-transform: var(--typo-section-title-text-transform, uppercase);

    a {
        text-decoration: inherit;
    }

    &.colored {
        color: var(--brand-primary-color);
    }
}
