[data-theme='urbania'] {
    /* Popups */
    --messages-background: var(--color-urbania-yellow);
    --messages-color: #000;
}

[data-theme='quatre95'] {
    /* Popups */
    --messages-background: var(--color-quatre95);
    --messages-color: var(--color-white);
}

[data-theme='dehors'] {
    /* Popups */
    --messages-background: var(--color-dehors-turquoise);
    --messages-color: var(--color-black);
}

[data-theme='mollo'] {
    /* Popups */
    --messages-background: var(--color-mollo);
    --messages-color: var(--color-white);
}

[data-theme='periodelibre'] {
    /* Popups */
    --messages-background: var(--color-periodelibre-red);
    --messages-color: var(--color-white);
}

[data-theme='france'] {
    /* Popups */
    --messages-background: var(--color-france-blue);
    --messages-color: var(--color-white);
}

[data-theme='dark'] {
    --button-background: var(--color-white);
    --button-color: var(--color-black);
    --button-pill-color: var(--color-white);
}

[data-theme='normal'] {
    --button-background: var(--color-black);
    --button-color: var(--color-white);
}
