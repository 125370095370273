.iframeContainer {
    display: flex;
    max-width: 100%;
    flex-direction: column;

    > * {
        align-self: center;
        margin: auto;
    }
}
