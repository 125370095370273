.container {
    padding: var(--button-rounded-padding);
    border-radius: var(--button-rounded-border-radius);
    background: var(--button-rounded-background, var(--button-background, var(--color-black)));
    color: var(--button-rounded-color, var(--button-color, var(--color-white)));
    text-decoration: none;

    &.compact {
        padding: var(--button-rounded-padding-compact);
        line-height: 0.8;
    }

    &.transparent {
        background-color: transparent;
        color: inherit;
    }

    &:disabled {
        opacity: 0.5;
    }
}
