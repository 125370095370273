.container {
    overflow: hidden;
    color: inherit;
    font-size: var(--card-font-size);
    text-decoration: none;

    .label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: var(--card-spacing);
    }

    .initials {
        font-size: var(--card-font-size-initials);
    }

    .role {
        display: block;
        font-size: var(--card-font-size-category);
    }

    .thumbnail {
        /* border-radius: 0; */
        /* background: transparent; */
    }

    .title {
        display: flex;
        align-items: center;
        white-space: normal;
    }

    &.withBorder {
        border-bottom: var(--card-border-bottom);
    }

    &.big {
        font-size: var(--card-font-size-big);
    }

    &.small {
        font-size: var(--card-font-size-small);
    }

    &.menu {
        .shape {
            border-radius: 50%;
            background: linear-gradient(180deg, #eee 0%, rgb(217 217 217 / 0) 100%);
        }

        .title {
            overflow: hidden;
            height: auto;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &.withBorder {
            border-bottom: var(--card-border-bottom);

            .title {
                border-bottom: 0;
            }
        }
    }
}
