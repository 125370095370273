.container {
    width: 1em;
    height: 1em;
    padding: 0.2em;
}

.icon {
    display: block;
    width: 100%;
    height: 100%;
}

