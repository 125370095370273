.container {
    opacity: 0.2;
    /* display: inline-block; */
    /* width: 100%; */
    /* margin: 0.1em 0; */
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: pulse;
    animation-play-state: running;
    animation-timing-function: ease-in-out;
    font-size: 0.8em;
    letter-spacing: -0.01em;
    line-height: 1.25em;
}

@keyframes pulse {
    0% {
        opacity: 0.2;
    }

    50% {
        opacity: 0.1;
    }

    100% {
        opacity: 0.2;
    }
}
