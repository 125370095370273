.container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .title {
        margin-right: auto;
    }

    .button {
        margin-left: 10px;
        font-size: 1rem;
        white-space: nowrap;
    }
}
