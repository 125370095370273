.container {
    overflow: hidden;

    .label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
    }

    .title {
        display: flex;
        align-items: center;
        font-size: var(--card-font-size-title);
        white-space: normal;
    }

    .subtitle {
        font-size: var(--card-font-size-subtitle);
    }
}
