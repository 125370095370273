.container {
    .form {
        overflow: hidden;
        margin-top: var(--spacing);
        font-size: 0.9em;
    }

    .button {
        font-size: 1em;
    }
}
