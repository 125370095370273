.container {
    .dialog {
        > * {
            margin-bottom: var(--spacing);

            &:last-child {
                margin: 0;
            }
        }
    }

    .section {
        > * {
            margin-bottom: var(--spacing);

            &:last-child {
                margin: 0;
            }
        }
    }

    .title {
        margin: 0;
        margin-bottom: var(--consent-modal-spacing);
        font-size: var(--consent-modal-font-size-title);
        text-transform: uppercase;
    }

    .description {
        font-size: var(--consent-modal-font-size-description);
        font-weight: 400;

        p {
            margin-top: 0;
            margin-bottom: var(--spacing);

            &:last-child {
                margin: 0;
            }
        }

        strong {
            font-weight: 500;
        }

        &.quiet {
            margin-top: 20px;
            font-size: var(--consent-modal-font-size-description-quiet);
            font-weight: 400;
        }
    }

    .main {
        .description {
            font-size: 1.25em;
        }
    }

    .form {
        font-size: var(--consent-modal-font-size-form);
    }

    .spacer {
        margin-top: var(--spacing-medium);
        margin-bottom: var(--spacing-medium);
    }

    .button {
        display: block;
        width: 100%;
        padding: 0.75em 1em;
        margin-top: var(--spacing);
        background-color: #fff;
        color: var(--color-black);
        border: solid 1px #000;
        border-radius: var(--control-border-radius);
        font-size: 12px;
        /* width: 100%;
        text-decoration: underline; */
    }
}
