.container {
    font-size: var(--card-font-size);

    .summary {
        margin-bottom: var(--spacing-medium);
    }

    .summaryDescription {
        p {
            display: none;
            margin-bottom: 0;

            &:first-child {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
            }
        }
    }

    .title {
        margin-bottom: var(--spacing-small);
    }

    .list {
        --grid-spacing: var(--spacing);
    }

    &.square {
        .summaryInner {
            grid-template-columns: 30% auto;
        }

        .summaryImage {
            overflow: hidden;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            border-radius: var(--card-border-radius);
        }
    }
}
