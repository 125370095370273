.container {
    position: relative;
    display: block;

    .link {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
    }

    .icon {
        display: none;
        font-size: 1.1rem;
        margin-right: 10px;
    }
}
