.container {
    overflow: hidden;
    color: inherit;
    font-size: var(--card-font-size);
    text-decoration: none;

    .label {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        padding-left: var(--card-spacing);
    }

    .sponsor {
        font-size: var(--card-font-size-sponsor);
    }

    &.withBorder {
        padding-bottom: var(--card-spacing);
        border-bottom: var(--card-border-bottom);
    }

    &.big {
        font-size: var(--card-font-size-big);
    }

    &.small {
        font-size: var(--card-font-size-small);
    }

    &.menu {
        .thumbnail {
            overflow: hidden;
            border-radius: var(--card-border-radius);

            &::after {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                box-shadow: var(--card-emboss-box-shadow);
                content: '';
            }
        }
    }
}
