/* stylelint-disable selector-class-pattern */
.container {
    --body-text-color: var(--article-text-color, var(--brand-text-color));
    --body-primary-color: var(--article-primary-color, var(--brand-primary-color));
    --header-color: var(--article-text-color, var(--brand-text-color));
    --header-primary-color: var(--article-primary-color, var(--brand-primary-color));

    position: relative;
    background: var(--article-background, var(--page-background));

    .header {
        margin: 0 auto;
        margin-bottom: var(--spacing-large);
    }

    .topAdContainer {
        display: flex;
        justify-content: center;
    }

    .topAd {
        width: auto;
        margin: 0 auto;
        margin-bottom: var(--spacing-medium);
    }

    .body {
        padding-bottom: var(--spacing-large);
    }

    .metadata {
        --button-pill-color: var(--body-text-color);

        max-width: var(--block-max-width-text);
        padding: 0 var(--spacing);
        padding-bottom: var(--spacing-large);
        margin: 0 auto;
        color: var(--body-text-color);
        font-size: 0.85em;
    }

    &[data-theme='quatre95_account'] {
        h3 {
            text-transform: uppercase;
        }
    }

    [data-theme='reader'] & {
        .metadata {
            display: none;
        }
    }

    &[data-theme='saq-quiz-genz'] {
        --header-color: var(--color-white);
    }
}
