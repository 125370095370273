.container {
    overflow: hidden;
    padding: var(--card-spacing) 0;
    border-bottom: 0;
    border-bottom: var(--card-border-bottom);
    color: inherit;
    text-decoration: none;

    .label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 var(--card-spacing);
    }

    .title {
        display: flex;
        align-items: center;
        font-size: var(--card-font-size-title);
        white-space: normal;
    }

    .subtitle {
        font-size: var(--card-font-size-subtitle);
    }

    .iconContainer {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: linear-gradient(180deg, #eee 0%, rgb(217 217 217 / 0) 100%);
    }

    .typeIcon {
        width: 100%;
        height: 100%;
    }

    .iconPin {
        height: 100%;
    }

    &.box {
        border-radius: var(--card-border-radius);
        border-bottom: none;
        transition: background 0.2s ease-out;

        .inner {
            padding: var(--card-spacing-large);
        }

        .iconContainer {
            padding: var(--card-spacing);
        }
    }

    &.boxTranslucentDark {
        background: var(--card-box-translucent-dark-background);

        .iconContainer {
            background: transparent;
        }

        &.hasSponsor {
            .meta {
                background: var(--card-sponsor-background-translucent);
            }
        }
    }
}
