.container {
    .inner {
        padding: var(--spacing-medium) var(--side-spacing);
        background: var(--color-white);
        font-size: 1rem;
    }

    .wait {
        margin: var(--spacing-medium) 0;
        font-size: 1.2em;
        font-weight: 500;
        text-align: center;
    }

    @media (--small-viewport) {
        .inner {
            border-radius: 10px;
        }
    }
}
