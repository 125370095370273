.container {
    padding: 0;
    margin: 0;
    font-family: var(--typo-summary-description-font-family);
    font-weight: var(--typo-summary-description-font-weight);
    line-height: 1.2;

    p {
        margin: 0;
    }
}
