/* stylelint-disable selector-class-pattern */
/* stylelint-disable selector-pseudo-class-no-unknown */
.container {
    --image-overlap: 60px;

    position: relative;
    display: flex;
    min-height: 300px;
    flex-direction: column;

    .inner {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-end;
    }

    .imagesContainer {
        position: relative;
    }

    .backgroundContainer {
        position: relative;
        z-index: 1;

        .background {
            position: relative;
            z-index: 1;
            height: auto;
        }

        .image {
            position: relative;
            z-index: 1;
        }

        .picture {
            position: relative;
        }

        /* &::after {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) 80%,
                #fff 100%
            );
            content: '';
        } */
    }

    .boxContainer {
        position: relative;
        z-index: 3;
    }

    .imageContainer {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .image {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .picture {
            object-fit: contain;
        }
    }

    .box {
        padding: var(--spacing-medium) var(--side-spacing);
        /* background-color: var(--color-white); */
    }

    .surtitle {
        margin-bottom: var(--spacing);
        font-size: var(--header-font-size-surtitle);
        text-transform: uppercase;
    }

    .title {
        font-size: var(--header-font-size-collection-title);
    }

    .sponsor {
        margin-top: var(--spacing);
        font-size: var(--header-font-size-sponsor-small);
    }

    .description {
        width: 100%;
        margin-top: var(--spacing);
        font-size: var(--header-font-size-description);
        line-height: 1.3;

        p {
            margin-top: 0;
            margin-bottom: 1em;
            font-size: inherit;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .button {
        margin-top: var(--spacing);
        font-size: var(--header-font-size-button);
    }

    .sponsorImage {
        height: 2em;
    }

    &.imageOnly {
        .imageContainer {
            position: relative;
            z-index: 1;

            .background {
                position: relative;
                z-index: 1;
                height: auto;
            }

            .image {
                position: relative;
                z-index: 1;
            }

            .picture {
                position: relative;
                object-fit: contain;
            }
        }
    }

    @media (--medium-viewport) {
        --box-width: 25em;

        min-height: 500px;

        .inner {
            display: flex;
            height: 100%;
            flex-direction: row;
            justify-content: flex-start;
        }

        .imagesContainer {
            position: static;
        }

        .backgroundContainer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .background {
                position: absolute;
                height: 100%;
            }

            .image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .picture {
                position: absolute;
            }

            &::after {
                display: none;
            }
        }

        .boxContainer {
            z-index: 3;
            max-width: var(--box-width);
            padding: var(--spacing);
        }

        .box {
            padding: var(--spacing-medium) var(--spacing);
            border-radius: var(--header-collection-border-radius);
            background-color: var(--color-white);
        }

        .imageContainer {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            left: auto;
            width: calc(100% - var(--box-width));
            height: 100%;

            .image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .box {
            padding: var(--spacing-medium);
        }

        .surtitle {
            font-size: var(--header-font-size-surtitle-medium);
        }

        .title {
            font-size: var(--header-font-size-collection-title-medium);
        }

        .sponsor {
            font-size: var(--header-font-size-sponsor-medium);
            font-weight: 500;
        }

        .description {
            font-size: var(--header-font-size-subtitle-medium);
        }

        .button {
            font-size: var(--header-font-size-button-medium);
        }

        &.quatre95 {
            .title {
                font-size: 2.7em;
            }
        }
    }

    @media (--large-viewport) {
        --box-width: 30em;

        min-height: 700px;

        .surtitle {
            margin-bottom: var(--spacing);
            font-size: var(--header-font-size-surtitle-large);
        }

        .title {
            font-size: var(--header-font-size-collection-title-large);
        }

        .sponsor {
            margin-top: var(--spacing-medium);
            font-size: var(--header-font-size-sponsor-large);
        }

        .description {
            margin-top: var(--spacing-medium);
        }

        .button {
            margin-top: var(--spacing-medium);
            font-size: var(--header-font-size-button-large);
        }

        /* &.quatre95 {
            .title {
                font-size: 3.4em;
            }
        } */
    }
}
