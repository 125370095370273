.container {
    color: var(--color-white);
    font-size: var(--card-font-size-over);
    text-align: center;

    .inner {
        align-items: flex-start;
    }

    .thumbnail {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;

        &::after {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.6) 0%,
                rgba(0, 0, 0, 0) 15%,
                rgba(0, 0, 0, 0) 50%,
                rgba(0, 0, 0, 0.5) 70%,
                rgba(0, 0, 0, 0.8) 80%
            );
            content: '';
        }
    }

    .thumbnailIcon {
        z-index: 3;
    }

    /* .label {
        position: absolute;
        width: 100%;
        height: 100%;
    } */

    .label {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: auto;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        padding: var(--card-spacing);
        padding-bottom: 1.5em;
        background: transparent;
        color: var(--color-white);
        text-align: center;
        text-shadow: var(--card-featured-text-shadow);

        > :last-child {
            margin-bottom: 0;
        }
    }

    .meta {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        padding: var(--card-spacing-large);
        margin-top: auto;
    }

    .category {
        font-size: var(--card-font-size-over-category);

        &:first-child {
            margin-bottom: auto;
        }
    }

    .title {
        margin-bottom: var(--spacing);
        font-size: var(--card-font-size-title);

        &:first-child {
            margin-top: auto;
        }
    }

    .surtitle {
        margin-bottom: var(--card-spacing-surtitle);
        font-size: var(--card-font-size-over-surtitle);

        &:first-child {
            margin-top: 0;
            margin-bottom: auto;
        }
    }

    .sponsor {
        width: 100%;
        /* padding: var(--card-sponsor-padding); */
        /* background: var(--card-sponsor-background); */
        font-size: var(--card-font-size-over-sponsor);
    }

    .author {
        font-size: var(--card-font-size-over-author);
    }

    .duration {
        position: absolute;
        z-index: 2;
        right: var(--spacing);
        bottom: var(--spacing);
        padding: var(--card-duration-padding);
        border-radius: var(--card-duration-border-radius);
        margin-left: auto;
        background: var(--card-duration-background);
        font-size: var(--card-font-size-over-duration);
        text-shadow: none;
    }

    &.withSponsor {
        .meta {
            padding: 0;
            border-bottom: 0;
            background: transparent;
        }
    }

    &.micromag {
        .category,
        .surtitle,
        .title,
        .author {
            display: none;
        }

        .thumbnail {
            &::after {
                display: none;
            }
        }
    }

    &.big {
        font-size: var(--card-font-size-over-big);

        .label {
            padding-top: var(--card-spacing-large);
            padding-right: var(--card-spacing-large);
            padding-left: var(--card-spacing-large);
        }
    }

    &.small {
        font-size: var(--card-font-size-over-small);

        .label {
            padding-top: var(--spacing-small);
            padding-right: var(--spacing-small);
            padding-left: var(--spacing-small);
        }


        .thumbnailIcon {
            margin: var(--spacing-small);
        }

        .duration {
            right: var(--spacing-small);
            bottom: var(--spacing-small);
        }
    }

    &.withoutText,
    &.outline {
        overflow: hidden;
        border-radius: var(--card-border-radius);
    }

    &.withoutText {
        .thumbnail {
            &::after {
                display: none;
            }
        }

        .label {
            display: none;
        }
    }
}
