.container {
    display: block;
    overflow: hidden;
    font-size: inherit;

    .thumbnail {
        /* position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; */
    }

    .picture {
        object-fit: contain;
    }

    .label {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;
    }

    .name {
        margin-bottom: 0.2em;
    }

    .description {
        opacity: var(--card-meta-opacity);
    }

    .button {
        margin-top: var(--spacing);
    }

    .actions {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: var(--spacing);
    }

    .toggle {
        display: block;
        font-size: 2em;
    }

    .via {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: var(--spacing);
        font-size: 0.85rem;
        white-space: nowrap;
    }

    .viaButton {
        margin-left: var(--spacing);
    }

    .unsubscribeButton {
        padding-right: 0;
        padding-left: 0;
        text-decoration: underline;
    }

    .icon {
        display: block;
        width: var(--card-subscription-icon-size);
        height: var(--card-subscription-icon-size);
        align-self: center;
        margin-left: var(--card-spacing);
        transition: opacity 0.2s ease-out;
    }

    .loadingIcon {
        color: var(--control-placeholder-color);
    }

    .checkIcon {
        color: #0c9;
        opacity: 0;
    }

    .checkbox {
        width: 2em;
        height: 2em;
    }

    &.subscribed {
        .checkIcon {
            opacity: 1;
        }
    }

    &.box {
        padding: var(--card-spacing-large);
        border-radius: var(--card-border-radius);
        background-color: var(--color-white);
        transition: background 0.2s ease-out;

        .inner {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            /* margin: var(--card-spacing-subscription) auto; */
        }
    }

    &.notification {
        padding: var(--card-spacing-large);
        border-radius: var(--card-border-radius);
        background-color: rgba(0, 0, 0, 0.05);
        transition: background 0.2s ease-out;

        .inner {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            /* margin: var(--card-spacing-subscription) auto; */
        }
    }
}
