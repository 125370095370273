/* stylelint-disable selector-class-pattern */
@import url('sanitize.css');
@import url('@niche-js/blocks');
@import url('./theme.css');

body {
    font-family: var(--font-tight);
    font-size: var(--base-font-size);
    overflow-y: scroll;
}

html,
body {
    overscroll-behavior: none;
    overscroll-behavior-x: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

.modal-open {
    overflow: hidden;
    padding-right: 0;
}

.contain-size {
    contain: size layout style paint;
}
