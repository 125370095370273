.container {
    position: relative;

    img {
        width: auto;
        height: 100%;
    }

    .sponsor {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
