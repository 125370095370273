.container {
    padding: var(--button-pill-padding);
    border-radius: var(--button-pill-border-radius);
    background: var(--button-pill-background, var(--button-background, var(--button-pill-color)));
    color: var(--button-pill-text-color, var(--button-color, var(--color-white)));
    line-height: 1;
    text-decoration: none;

    &.compact {
        padding: var(--button-pill-padding-compact);
    }

    &.bordered {
        border: var(--button-pill-border);
        border-color: var(--button-pill-border-color, var(--button-border-color, var(--button-pill-color, var(--button-background))));
        background: transparent;
        color: var(--button-pill-color, var(--button-background));
    }

    &.active {
        --button-pill-color: var(--button-pill-active-color, var(--button-pill-color));
        --button-pill-text-color: var(
            --button-pill-active-text-color,
            var(--button-pill-text-color)
        );

        &.bordered {
            background: var(--button-pill-background, var(--button-pill-color));
            color: var(--button-pill-text-color, var(--color-white));
        }
    }

    &.branded {
        --button-pill-color: var(--brand-primary-color, var(--color-black));
        --button-pill-text-color: var(--page-background-color, var(--color-white));

        &.active {
            &.bordered {
                background: var(--button-pill-background, var(--button-pill-color));
                color: var(--button-pill-text-color, var(--color-white));
            }
        }
    }
}
