.container {
    position: relative;

    .row {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
    }

    .field {
        position: relative;
        flex-grow: 1;
        margin-right: var(--spacing);

        .input {
            display: block;
            width: 100%;
        }

        &.hasStatus {
            .input {
                padding-right: calc(var(--control-padding) + 1em);
            }
        }
    }

    .status {
        position: absolute;
        z-index: 2;
        top: 50%;
        right: calc(var(--control-padding) / 2);
        transform: translateY(-50%);
    }

    .loadingIcon {
        display: block;
        width: 1em;
        height: 1em;
    }

    .buttons {
        display: flex;
        align-items: center;

        > * {
            margin-right: var(--form-button-spacing);

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .button {
        height: 100%;
        font-size: var(--form-button-font-size);
    }
}
