.container {
    position: relative;

    .formRow {
        margin-bottom: var(--form-row-spacing);
    }

    .formControl {
        &.birthdate {
            width: 10em;
        }

        @media (--small-viewport) {
            &.firstName {
                width: 30%;
            }
        }
    }

    .field {
        width: 100%;
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: var(--block-spacing);
    }

    .button {
        display: flex;
        font-size: var(--form-button-large-font-size);
    }

    .status {
        margin-left: var(--control-spacing);
    }

    .deleteButton {
        margin-left: auto;
        text-decoration: underline;
    }
}
