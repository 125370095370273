.container {
    position: relative;

    .inner {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .title {
        padding: 0 var(--side-spacing);
        margin-bottom: var(--spacing);
    }

    .main {
        position: relative;
        display: block;
        min-height: 500px;
        flex-grow: 1;
        margin-right: var(--side-spacing);
        margin-left: var(--side-spacing);
    }

    .featuredCard {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        /* &.withRatio {
            position: relative;
            height: auto;
        } */
    }

    .featuredInner {
        /* flex-direction: column; */
    }

    .featuredImage {
        /* width: 50%; */
    }

    .sidebar {
        position: relative;
        width: 100%;
    }

    .list {
        height: 100%;
        padding-top: var(--spacing-medium);
    }

    .items {
        height: 100%;
        padding: 0 var(--side-spacing);
        scroll-padding: 0 var(--side-spacing);
    }

    .item {
        /* min-width: 150px; */
        height: 100%;
        margin-right: var(--spacing);
        scroll-margin-left: 0;

        &:last-child {
            margin-right: 0;
        }
    }

    /* .card {
        border-bottom: 0;
    } */

    .grid {
        .items {
            grid-template-columns: repeat(2, 1fr);
        }

        .item {
            margin-right: 0;
        }
    }

    &.responsive {
        .main {
            min-height: 0;
        }

        .featuredCard {
            position: relative;
            height: auto;

            &.isOver {
                height: 500px;
            }
        }

        .items,
        .item {
            height: auto;
        }
    }

    &.withShadow {
        .featuredImage {
            /* width: 50%; */
            box-shadow: var(--card-box-shadow);
        }

        /* .items {
            padding-bottom: 5px;
            margin-bottom: -5px;
        } */

        .card {
            box-shadow: var(--card-box-shadow);
            transition:
                box-shadow 0.2s ease-out,
                transform 0.2s ease-out;

            &:active {
                box-shadow: var(--card-box-shadow-down);
                transform: translate(1px, 1px);
            }
        }
    }

    @media (--small-viewport) {
        .grid {
            .items {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }

    @media (--medium-viewport) {
        .main {
            min-height: 600px;
        }

        .grid {
            .items {
                grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            }
        }

        &.responsive {
            .main {
                min-height: 0;
            }

            .isOver {
                height: 600px;
            }
        }
    }

    @media (--large-viewport) {
        .inner {
            min-height: 800px;
            flex-direction: row;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }

        .main {
            min-height: 0;
            margin-right: 0;
        }

        .sidebar {
            width: calc(25% + var(--list-spacing) + var(--list-spacing));
            min-width: calc(25% + var(--list-spacing) + var(--list-spacing));
            max-width: calc(25% + var(--list-spacing) + var(--list-spacing));
            padding-right: var(--side-spacing);
            padding-left: var(--list-spacing);
        }

        .list {
            padding-top: 0;
        }

        .grid {
            .items {
                padding: 0;
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .card {
            padding-bottom: 0;
        }

        &.oneColumn {
            .sidebar {
                width: calc(15% + var(--list-spacing) + var(--list-spacing));
                min-width: calc(15% + var(--list-spacing) + var(--list-spacing));
                max-width: calc(15% + var(--list-spacing) + var(--list-spacing));
                padding-right: var(--side-spacing);
                padding-left: var(--list-spacing);
            }

            .grid {
                .items {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }

        &.responsive {
            .inner {
                min-height: 0;
            }

            .featuredCard {
                height: auto;
                /* position: absolute; */
                min-height: 100%;
            }
        }
    }
}
