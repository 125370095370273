.container {
    display: inline-block;
    width: 1em;
    height: 1em;

    .slash {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }

    .volume {
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
    }
}
