.wrapper {
    display: flex;
    flex-direction: row;
}

.container {
    overflow: hidden;
    font-size: var(--card-font-size-horizontal);
    color: var(--card-color, inherit);
    /*
    .thumbnail {
        position: relative;
        overflow: hidden;
        width: 100%;
    } */

    .thumbnail {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .thumbnailIcon {
        margin: var(--spacing-small);
    }

    .label {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 0.1em;
        margin-left: var(--card-spacing);
    }

    .category {
        margin-bottom: var(--card-spacing-category);
        font-size: var(--card-font-size-category);
    }

    .title {
        overflow: hidden;
        flex-grow: 1;
        padding-bottom: var(--card-spacing-title);
        font-size: var(--card-font-size-title);
    }

    .surtitle {
        margin-bottom: var(--card-spacing-surtitle);
        font-size: var(--card-font-size-surtitle);
        opacity: var(--card-surtitle-opacity);
    }

    .meta {
        position: relative;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-end;

        > * {
            opacity: var(--card-meta-opacity);
        }
    }

    .author {
        align-items: center;
        font-size: var(--card-font-size-author);
    }

    .sponsor {
        width: 100%;
        flex-grow: 1;
        font-size: var(--card-font-size-sponsor);
        line-height: 1;
    }

    .time {
        padding-left: var(--spacing);
        margin-left: auto;
        font-size: var(--card-font-size-time);
        /* text-transform: uppercase; */
    }

    .duration {
        position: absolute;
        z-index: 2;
        right: var(--spacing-small);
        bottom: var(--spacing-small);
        padding: var(--card-duration-padding);
        border-radius: var(--card-duration-border-radius);
        background: var(--card-duration-background);
        color: var(--color-white);
        font-size: var(--card-font-size-duration);
    }

    .containImage {
        object-fit: contain;
    }

    &.hasSponsor {
        .meta {
            padding: var(--card-sponsor-padding);
            background: var(--card-sponsor-background);
            opacity: var(--card-sponsor-opacity);
        }

        .time {
            display: none;
        }
    }

    &.withBorder {
        .meta {
            padding-bottom: var(--card-spacing-bottom);
            border-bottom: var(--card-border-bottom);
        }

        &.hasSponsor {
            .meta {
                padding: var(--card-sponsor-padding);
                /* border-bottom: 0; */
            }
        }
    }

    &.responsive {
        .label {
            padding-left: var(--card-spacing);
            margin-left: 0;
        }

        .meta {
            /* left: var(--card-spacing); */
        }
    }

    &.tiny {
        .label {
            justify-content: center;
        }

        .title {
            flex-grow: 0;
            padding-bottom: 0.1em;
        }

        .time {
            display: none;
            margin-left: 0;
        }

        &.hasSponsor {
            .meta {
                padding: 0;
                background: transparent;
            }

            .time {
                display: none;
            }
        }

        &.hasSurtitle {
            .category {
                display: none;
            }
        }
    }

    &.small {
        font-size: var(--card-font-size-horizontal-small);

        .category {
            font-size: var(--card-font-size-horizontal-category-small);
        }

        .surtitle {
            font-size: var(--card-font-size-horizontal-surtitle-small);
        }

        .author {
            font-size: var(--card-font-size-horizontal-author-small);
        }

        .sponsor {
            font-size: var(--card-font-size-horizontal-sponsor-small);
        }

        .duration {
            font-size: var(--card-font-size-horizontal-duration-small);
        }

        .time {
            font-size: var(--card-font-size-horizontal-time-small);
        }

        &.hasSurtitle {
            .category {
                display: none;
            }
        }
    }

    &.big {
        font-size: var(--card-font-size-horizontal-big);

        .thumbnailIcon {
            margin: var(--spacing);
        }

        .category {
            font-size: var(--card-font-size-horizontal-category-big);
        }

        .surtitle {
            font-size: var(--card-font-size-horizontal-surtitle-big);
        }

        .author {
            font-size: var(--card-font-size-horizontal-author-big);
        }

        .sponsor {
            font-size: var(--card-font-size-horizontal-sponsor-big);
        }

        .duration {
            right: var(--spacing);
            bottom: var(--spacing);
            font-size: var(--card-font-size-horizontal-duration-big);
        }

        .time {
            font-size: var(--card-font-size-horizontal-time-big);
        }
    }

    &.box {
        border-radius: var(--card-border-radius);
        background-color: var(--color-white);
        color: var(--card-color, var(--color-black));
        transition: background 0.2s ease-out;

        .label {
            padding: var(--spacing);
            padding-bottom: var(--spacing-small);
            margin-left: 0;
        }

        &.small,
        &.tiny {
            .label {
                /* padding: var(--spacing-small);
                padding-left: var(--spacing); */
            }
        }

        &.hasSponsor {
            .label {
                padding-right: 0;
                padding-bottom: 0;
                padding-left: 0;

                > * {
                    padding-right: var(--spacing);
                    padding-left: var(--spacing);
                }
            }

            .meta {
                width: 100%;
                background: var(--card-sponsor-background-white);
                padding-left: var(--spacing);
            }
        }

        &.withBorder {
            .meta {
                padding-bottom: 0;
                border-bottom: none;
            }

            &.hasSponsor {
                .meta {
                    padding: var(--card-sponsor-padding);
                    padding-left: var(--spacing);
                    border-bottom: 0;
                }
            }
        }
    }

    &.boxTranslucent {
        background-color: var(--card-box-translucent-background);
        color: var(--card-color, var(--color-black));
    }

    &.boxTranslucentDark {
        background: var(--card-box-translucent-dark-background);

        &.hasSponsor {
            .meta {
                background: var(--card-sponsor-background-translucent);
            }
        }
    }

    &.boxDark {
        background-color: var(--color-black);
        color: var(--card-color, var(--color-grey));
    }

    &.boxBrand {
        background-color: var(--brand-primary-color, var(--color-white));
        color: var(--card-color, var(--text-on-primary-color, var(--color-black)));
    }

    &.rounded {
        .thumbnail {
            border-radius: var(--card-border-radius);
        }
    }

    &.menu {
        .thumbnail {
            border-radius: var(--card-border-radius);

            &::after {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                box-shadow: var(--card-emboss-box-shadow);
                content: '';
                transition: box-shadow 0.2s ease-in-out;
            }

            &.gradiant {
                background: linear-gradient(182deg, #cbcbcb 0%, rgba(217 217 217 / 0.42) 100%);

                &::after {
                    display: none;
                }
            }
        }

        &.withBorder {
            padding-bottom: var(--card-spacing);
            border-bottom: var(--card-border-bottom);

            .meta {
                padding-bottom: 0;
                border-bottom: 0;
            }
        }

        &:active,
        &:hover {
            .thumbnail {
                &::after {
                    box-shadow: var(--card-emboss-down-box-shadow);
                }
            }
        }
    }
}

.locations {
    position: relative;
    display: flex;
    min-width: 3em;
    max-width: 3em;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-left: 2px;

    .locationIcon {
        min-width: 3em;
    }

    .locationCount {
        color: var(--card-locations-count-color);
        font-size: var(--card-font-size-icon-count);
    }
}
