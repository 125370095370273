.container {
    position: relative;
    min-height: 100svh;
    background: var(--page-background);
    transition: background-color 0.5s ease-out;

    .wrapper {
        display: flex;
        width: 100%;
        min-height: 100svh;
        flex-direction: column;
    }

    .inner {
        position: relative;
        width: 100%;
        max-width: 40em;
        padding: var(--spacing-medium) var(--side-spacing);
        margin: auto;
    }

    .title {
        margin-bottom: var(--spacing-medium);
        font-size: 2rem;
    }

    .description {
        margin-bottom: var(--spacing-large);
        font-size: 1.5rem;
    }

    .subscribed {
        padding: var(--control-padding);
        border-radius: var(--control-border-radius);
        background: rgba(255 255 255 / 0.5);
        font-weight: bold;
        text-align: center;
    }

    .link {
        width: 100%;
        font-size: 0.75em;
        font-weight: normal;
        text-align: center;
        text-decoration: underline;
    }

    .fields {
        font-size: 1.25rem;
    }

    .placeholder {
        padding: var(--control-padding);
        border-radius: var(--control-border-radius);
        background: var(--control-translucent-dark-background-color);
        color: rgba(0 0 0 / 0.5);
    }

    .urbania {
        position: absolute;
        top: var(--spacing);
        left: var(--spacing);
        opacity: 0;
    }

    &.entered,
    &.entering {
        .urbania {
            opacity: 1;
        }
    }

    &.leaving {
        .urbania {
            opacity: 0;
        }
    }

    @media (--small-viewport) {
        .title {
            font-size: 3rem;
        }

        .fields {
            font-size: 1.5rem;
        }
    }
}
