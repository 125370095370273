.container {
    margin: 0;
}

.frame {
    position: relative;
    width: 100%;
    height: 0;
}

.image {
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; */
    position: relative;
    width: 100%;
    height: auto;

    .picture {
        position: relative;
        width: 100%;
        height: auto;
        max-height: 100vh;
        object-fit: contain;
    }
}

.credits {
    width: 100%;
    margin-top: 5px;
    color: inherit;
    font-size: 10px;
    opacity: 0.5;
    text-align: right;

    .fullwidth & {
        margin-right: 5px;
    }
}

.caption {
    margin-top: 10px;
    font-size: 0.85em;

    .fullwidth & {
        margin-left: 10px;
    }

    &.withCredits {
        margin-top: 5px;
    }
}

@media (--medium-viewport) {
    .credits {
        font-size: 12px;
    }
}
