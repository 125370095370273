.container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.label {
    display: block;
    padding: 0 5px;
    margin: 0 auto;
    font-family: var(--font-tight);
    line-height: 1;
}

.line {
    display: block;
    height: 1px;
    flex-grow: 1;
    background-color: var(--color-black);
}
