.container {
    position: relative;

    .items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
    }
}
