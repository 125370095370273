.container {
    padding: 10px;
    border-radius: var(--promotion-border-radius);
    background: var(--promotion-background, var(--color-white));
    color: var(--promotion-color, inherit);
    font-family: var(--font-tight);
    transform-origin: bottom right;

    .inner {
        display: grid;
        grid-template-areas:
                'content'
                'cta';
    }

    .link {
        display: block;
        text-decoration: none;
    }

    .image {
        overflow: hidden;
        width: 80px;
        height: 80px;
        border-radius: var(--promotion-border-radius);
        margin-right: var(--spacing);
        grid-area: image;

        .picture {
            object-fit: contain;
            margin: 0 !important;
        }
    }

    .content {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        grid-area: content;
    }

    .title {
        margin: 0;
        margin-bottom: var(--spacing-small);
        font-size: var(--promotion-font-size-title);
    }

    .description {
        font-size: var(--promotion-font-size-description);
    }

    .callToAction {
        margin-top: var(--spacing);
        grid-area: cta;
    }

    &.hasImage {
        .inner {
            grid-template-areas:
                'image content'
                'cta cta';
        }

        &.invert {
            .inner {
                grid-template-areas:
                    'content image'
                    'cta cta';
            }

            .image {
                margin-right: auto;
                margin-left: var(--spacing);
            }
        }
    }

    @media (--small-viewport) {
        .inner {
            grid-template-areas:
                'content'
                'cta';
        }

        .image {
            width: 100px;
            height: 100px;
        }

        /* .content {
            justify-content: flex-end;
        } */

        .callToAction {
            margin-bottom: auto;
        }

        &.hasImage {
            .inner {
                grid-template-areas:
                    'image content'
                    'image cta';
                grid-template-columns: 110px auto;
            }
        }
    }
}
