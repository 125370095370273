.container {
    position: relative;
    color: var(--header-color);

    .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .imageContainer {
        position: relative;
        display: flex;
        overflow: hidden;
        width: 100%;
        min-height: 300px;
        max-height: 500px;
        flex-direction: column;
        justify-content: center;
    }

    .image {
        /* position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; */
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        object-fit: cover;
        object-position: center center;
    }

    .imageCredits {
        width: 100%;
        padding: 5px;
        color: inherit;
        font-size: 10px;
        opacity: 0.5;
        text-align: right;
    }

    .content {
        position: relative;
        padding: var(--spacing-medium) 20px 0;
    }

    .picture {
        position: relative;
        flex-grow: 1;
    }

    .before {
        display: flex;
        flex-direction: column;
        align-items: center;

        /* margin-bottom: var(--spacing-medium);

        > * {
            margin-top: var(--spacing-medium);
        }

        > :first-child {
            margin-top: 0;
        } */
    }

    .after {
        display: flex;
        flex-direction: column;
        align-items: center;

        > * {
            margin-top: var(--spacing-large);
        }
    }

    .category {
        width: auto;
        padding: var(--header-category-padding);
        border: 1px solid;
        border-radius: var(--header-category-border-radius);
        margin-bottom: 2em;
        font-size: var(--header-font-size-category);
        text-align: center;
        text-decoration: none;

        &.placeholder {
            border-color: rgb(0 0 0 / 0.2);
        }
    }

    .surtitle {
        margin-bottom: 0.5em;
        color: var(--header-surtitle-color, inherit);
        font-family: var(--header-surtitle-font-family, var(--typo-surtitle-font-family));
        font-size: var(--header-font-size-surtitle);
        font-weight: var(--header-surtitle-font-weight, var(--typo-surtitle-font-family));
        text-transform: uppercase;
    }

    .title {
        font-size: var(--header-font-size-title);
        text-align: center;
        text-transform: uppercase;
    }

    .subtitle {
        margin-top: var(--spacing-medium);
        font-size: var(--header-font-size-subtitle);
        font-weight: 700;
        letter-spacing: -0.01em;
        line-height: 1.3;
        text-align: center;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;

        > * {
            margin-right: var(--spacing);
        }
    }

    .author {
        font-size: var(--header-font-size-author);
    }

    .authorInitials {
        font-size: var(--header-font-size-initials);
    }

    .authorName {
        font-weight: 700;

        /* text-transform: uppercase; */
    }

    .date {
        font-size: var(--header-font-size-date);
        font-weight: 500;

        /* text-transform: uppercase; */

        &.hasAuthor {
            margin-top: var(--spacing);
        }
    }

    .sponsor {
        width: 100%;
        justify-content: center;
        font-size: var(--header-font-size-sponsor);
    }

    .toolbarButton {
        --button-background: var(--header-color);
    }

    .staffToolbar {
        display: flex;
        flex-direction: row;
        align-items: center;

        .toolbarButton {
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media (--small-viewport) {
        .title {
            max-width: 13em;
            margin-right: auto;
            margin-left: auto;
        }
    }

    @media (--medium-viewport) {
        .content {
            padding-right: 0;
            padding-left: 0;
        }

        .imageContainer {
            /* min-height: 500px; */
            max-height: 700px;
        }

        .imageCredits {
            font-size: 12px;
        }

        .category {
            font-size: var(--header-font-size-category-medium);
        }

        .surtitle {
            font-size: var(--header-font-size-surtitle-medium);
        }

        .title {
            font-size: var(--header-font-size-title-medium);
        }

        .author {
            font-size: var(--header-font-size-author-medium);
        }

        .date {
            font-size: var(--header-font-size-date-medium);
        }

        .sponsor {
            font-size: var(--header-font-size-sponsor-medium);
        }

        .subtitle {
            max-width: 25em;
            font-size: var(--header-font-size-subtitle-medium);
        }
    }

    @media (--large-viewport) {
        .imageContainer {
            /* min-height: 600px; */
            max-height: 750px;
        }

        .after {
            > * {
                margin-top: var(--spacing-large);
            }
        }

        .category {
            font-size: var(--header-font-size-category-large);
        }

        .surtitle {
            font-size: var(--header-font-size-surtitle-large);
        }

        .title {
            font-size: var(--header-font-size-title-large);
        }

        .subtitle {
            margin-top: 30px;
            font-size: var(--header-font-size-subtitle-large);
        }

        .author {
            font-size: var(--header-font-size-author-large);
        }

        .date {
            font-size: var(--header-font-size-date-large);

            &.hasAuthor {
                margin-top: var(--spacing-medium);
            }
        }

        .sponsor {
            font-size: var(--header-font-size-sponsor-large);
        }
    }
}
