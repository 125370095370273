.container {
    padding-bottom: var(--side-spacing);
    background: var(--color-white);

    .header {
        margin-bottom: var(--side-spacing);
    }

    .blocks {
        margin-bottom: var(--block-spacing);

        &:last-child {
            margin-bottom: 0;
        }
    }

    .sectionTitle {
        flex-direction: column;

        > *:last-child {
            margin-top: 10px;
            margin-left: auto;
        }
    }

    .row {
        margin-bottom: 60px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .podcast {
        max-width: 1000px;
        margin-right: auto;
        margin-left: auto;
    }

    .childSummary {
        text-transform: none;

        .childInner {
            grid-template-columns: auto
        }

        .childImage {
            width: 3em;
            height: 3em;
        }

        .childDescription {
            margin-top: 0;

            p {
                margin-bottom: 0;
            }
        }
    }

    @media (--small-viewport) {
        .sectionTitle {
            flex-direction: row;
            align-items: flex-end;

            > *:last-child {
                margin-top: 0;
                margin-left: 10px;
            }
        }
    }
}
