.container {
    position: relative;
    height: 4px;

    .bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--loading-bar-color, var(--brand-primary-color, var(--color-yellow)));
        box-shadow: 0 4px 6px #000;
        opacity: 0;
        transform: scaleX(0);
        transform-origin: 0 0;
        transition: background 0.5s ease-out;
    }

    &.loading {
        .bar {
            animation-duration: 10s;
            animation-fill-mode: forwards;
            animation-name: loading-iddle;
            opacity: 1;
        }
    }

    &.loaded {
        .bar {
            /* animation-delay: 2s; */
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
            animation-name: fade-out;
            opacity: 1;
        }
    }
}

@keyframes fade-out {
    95% {
        opacity: 1;
        transform: scaleX(1);
    }

    100% {
        opacity: 0;
    }
}

@keyframes loading-iddle {
    100% {
        transform: scaleX(0.9);
    }
}
