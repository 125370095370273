:root {
    --form-row-spacing: var(--spacing);

    /*  Controls */
    --control-font-family: var(--font-tight);
    --control-font-weight: 500;
    --control-padding: 0.75em;
    --control-spacing: var(--spacing-small);
    --control-border-radius: calc(var(--control-padding) / 2);
    --control-search-border-radius: calc(var(--control-padding) + 0.5em);
    --control-border-color: var(--color-black);
    --control-border: solid 1px var(--control-border-color);
    --control-disabled-border: solid 1px rgba(34 34 34 / 0.1);
    --control-color: var(--color-black);
    --control-disabled-color: rgba(0 0 0 / 0.5);
    --control-background: #eee;
    --control-translucent-background-color: rgba(255 255 255 / 0.4);
    --control-translucent-dark-background-color: rgba(34 34 34 / 0.05);
    --control-placeholder-color: #6c6c6c;
    --control-label-color: rgba(34 34 34 / 0.75);
    --control-max-width: 20em;
    --control-verify-max-width: 5.2em;
    --control-errors-color: var(--color-red);
    --control-errors-background-color: rgba(200 0 0 / 0.05);
    --control-focus-box-shadow: inset 0 0 0 0.1em var(--control-border-color);

    /*  Buttons */
    --button-font-family: var(--font-tight);
    --button-font-weight: 500;
    --button-letter-spacing: 0.02em;
    --form-button-spacing: var(--spacing-small);

    /*  Urbania */
    --button-urbania-size: var(--menu-urbania-size);

    /*  Button border */
    --button-pill-padding-compact: 0.4em 0.7em 0.3em;
    --button-pill-padding: 0.75em 1.25em 0.6em;
    --button-pill-border-radius: 1.5em;
    --button-pill-color: var(--color-black);
    --button-pill-border: solid 1px var(--button-pill-color);

    /*  Button border */
    --button-rounded-padding-compact: 0.625em 0.625em 0.5em;
    --button-rounded-padding: 0.75em 1.25em 0.6em;
    --button-rounded-border-radius: 0.625em;

    /* Elements font size */
    --control-label-font-size: 0.875em;
    --form-button-font-size: 0.75em;
    --form-button-large-font-size: 0.875em;
}
