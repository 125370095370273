.container {
    font-size: 1rem;

    .inner {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;

        /* margin-bottom: calc(-1 * var(--block-spacing)); */

        > * {
            padding-right: var(--spacing);
            padding-bottom: var(--block-spacing);
            margin-right: auto;

            &:last-child {
                padding-right: 0;
                margin-right: 0;
            }
        }
    }

    .group {
        > * {
            margin-bottom: var(--spacing);

            &:last-child {
                margin-bottom: 0;
            }

            @media (--medium-viewport) {
                margin-bottom: var(--spacing-small);
            }
        }
    }

    .items {
        margin: -2px -1px;
    }

    .item {
        margin: 2px 1px;
    }

    /* .button {
        padding: 0.4em 0.5em 0.3em;
    } */

    /* .items {
        overflow: auto;
        flex-wrap: nowrap;
    }

    .button {
        white-space: nowrap;
    } */
}
