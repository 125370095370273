.container {
    .shape {
        position: relative;
        width: 100%;
        height: 0;
    }

    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.vertical {
        max-width: 400px;
        margin: 0 auto;
    }
}
