:root {
    --modal-backdrop-color: rgba(0 0 0 / 0.4);
    --modals-z-index: 9998;
    --notifications-z-index: 9997;
    --messages-z-index: 9999;

    /* Dialog */
    --dialog-border-radius: 20px;
    --dialog-max-width: 375px;
    --dialog-background: var(--color-white);
    --dialog-color: var(--color-black);
    --dialog-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    /* Colors */
    --consent-modal-input-background-color: rgba(255 255 255 / 0.4);

    /* Spacing */
    --consent-modal-spacing: var(--spacing);

    /* Font size */
    --consent-modal-font-size-title: 2.25rem;
    --consent-modal-font-size-description: 1.125rem;
    --consent-modal-font-size-description-quiet: 1rem;
    --consent-modal-font-size-form: 1rem;
}
