.container {
    width: 1em;
    height: 1em;
    padding: 0.2em 0;
}

.icon {
    display: block;
    width: auto;
    height: 100%;
    margin: 0 auto;
}

