.container {
    position: relative;

    .inner {
        position: relative;
        min-width: 100%;
    }

    .title {
        padding: 0 var(--side-spacing);
        margin-bottom: var(--spacing);
    }

    .items {
        padding: 0 var(--side-spacing);
        scroll-padding: 0 var(--side-spacing);

        > * {
            scroll-margin-left: 0;
        }
    }

    .background {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            z-index: 1;
            object-position: top center;
        }
    }

    &.textIsWhite {
        --card-border-color: #fff;

        color: #fff;
    }

    &.withBackground {
        .inner {
            padding: var(--block-spacing) 0;
        }

        .background {
            overflow: hidden;
        }
    }

    &.withBorderRadius {
        .inner {
            padding: var(--block-spacing) 0;
            border-radius: var(--card-border-radius);
        }

        .background {
            overflow: hidden;
            border-radius: var(--card-border-radius);
        }
    }

    &.withShadow {
        .card {
            box-shadow: var(--card-box-shadow);
            transition: box-shadow 0.2s ease-out, transform 0.2s ease-out;

            &:active {
                box-shadow: var(--card-box-shadow-down);
                transform: translate(1px, 1px);
            }
        }
    }
}
