.container {
    display: flex;
    flex-direction: column;
    margin: calc(var(--control-spacing) * -1);

    > * {
        flex-grow: 1;
        margin: var(--control-spacing);
    }

    &.alwaysHorizontal {
        flex-direction: row;
    }

    @media (--small-viewport) {
        flex-direction: row;
    }
}
