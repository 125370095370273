.container {
}

.block {
    margin-bottom: var(--infinite-list-spacing);

    &.separator {
        margin: var(--infinite-list-separator-spacing) 0;
    }

    &:last-child {
        margin: 0;
    }
}

.endOfPage {
    height: 20px;
}
