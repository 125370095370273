:root {
    /*  Fonts */
    --font-tight: 'AgrandirTight';
    --font-wide: 'AgrandirWide';
    --font-garage: 'Garage Gothic';
    --font-agrandir: 'Agrandir';
    --font-text: 'Sina Nova';
    --font-dehors: 'Avant Garde';
    --font-quatre95: 'Maax';

    /*  Font size */
    --base-font-size: 16px;

    /* Ad */
    --typo-ad-font-family: var(--font-wide);

    /* Category */
    --typo-category-font-family: var(--font-wide);
    --typo-category-font-weight: 700;
    --typo-category-letter-spacing: 0.05em;

    /* Surtitle */
    --typo-surtitle-font-family: var(--font-tight);
    --typo-surtitle-font-weight: 400;
    --typo-surtitle-letter-spacing: 0.01em;

    /* Author name */
    --typo-author-name-font-family: var(--font-tight);
    --typo-author-name-font-weight: 500;

    /* Author initials */
    --typo-author-initials-font-family: var(--font-wide);
    --typo-author-initials-font-weight: 700;

    /* Duration */
    --typo-duration-font-family: var(--font-tight);
    --typo-duration-font-weight: 500;

    /* Sponsor content */
    --typo-sponsor-content-font-family: var(--font-tight);
    --typo-sponsor-content-font-weight: 400;
    --typo-sponsor-content-strong: 500;

    /* Button content */
    --typo-button-font-family: var(--font-tight);
    --typo-button-font-weight: 500;

    /* Card title */
    --typo-card-title-font-family: var(--font-tight);
    --typo-card-title-font-weight: 500;
    --typo-card-title-letter-spacing: 0.01em;

    /* Card subtitle */
    --typo-card-subtitle-font-family: var(--font-tight);
    --typo-card-subtitle-font-weight: 500;
    --typo-card-subtitle-letter-spacing: 0.01em;

    /* Card description */
    --typo-card-description-font-family: var(--font-tight);
    --typo-card-description-font-weight: 400;
    --typo-card-description-letter-spacing: 0.01em;

    /* Outline */
    --typo-outline-title-font-family: var(--font-garage);
    --typo-outline-title-font-weight: 700;

    /* List title */
    --typo-list-title-font-family: var(--font-tight);
    --typo-list-title-font-weight: 500;

    /* Tagline */
    --typo-tagline-font-family: var(--font-tight);
    --typo-tagline-font-weight: 500;

    /* Menu section */
    --typo-menu-section-font-family: var(--font-wide);
    --typo-menu-section-font-weight: 500;
    --typo-menu-section-letter-spacing: 0.01em;

    /* Menu sub-section */
    --typo-menu-sub-section-font-family: var(--font-tight);
    --typo-menu-sub-section-font-weight: 500;
    --typo-menu-sub-section-letter-spacing: 0.01em;

    /* Menu label */
    --typo-menu-label-font-family: var(--font-tight);
    --typo-menu-label-font-weight: 500;

    /* Brand - dehors */
    --typo-dehors-font-family: var(--font-dehors);
    --typo-dehors-font-weight: 500;

    /* Brand - quatre95 */
    --typo-quatre95-font-family: var(--font-quatre95);
    --typo-quatre95-font-weight: 500;

    /* Body */
    --typo-body-font-family: var(--font-text);
    --typo-body-font-weight: 400;
    --typo-body-font-letter-spacing: 0.01em;
    --typo-body-heading-font-family: var(--font-wide);
    --typo-body-heading-font-weight: 700;
    --typo-body-heading-letter-spacing: 0.015em;

    /* Header title */
    --typo-header-title-font-family: var(--font-garage);
    --typo-header-title-font-weight: 500;
    --typo-header-title-letter-spacing: 0.015em;
    --typo-header-title-font-family-quatre95: var(--font-quatre95);
    --typo-header-title-font-weight-quatre95: 700;
    --typo-header-title-line-height-quatre95: 1;
    --typo-header-title-letter-spacing-quatre95: -0.03em;
    --typo-header-title-font-family-dehors: var(--font-dehors);
    --typo-header-title-font-weight-dehors: 700;
    --typo-header-title-line-height-dehors: 0.9;
    --typo-header-title-font-family-mollo: var(--font-tight);
    --typo-header-title-font-weight-mollo: 800;
    --typo-header-title-line-height-mollo: 1.05;

    /* Header description */
    --typo-header-description-font-family: var(--font-tight);
    --typo-header-description-font-weight: 500;
    --typo-header-description-letter-spacing: 0.015em;

    /* Index Title */
    --typo-index-title-font-weight: 700;

    /* Collection Header description */
    --typo-collection-header-description-font-family: var(--font-text);
    --typo-collection-header-description-font-weight: 500;
    --typo-collection-header-description-letter-spacing: 0.01em;

    /* Document title */
    --typo-document-title-font-family: var(--font-garage);
    --typo-document-title-font-weight: 500;
    --typo-document-title-letter-spacing: 0.015em;

    /* Document subtitle */
    --typo-document-subtitle-font-family: var(--font-text);
    --typo-document-subtitle-font-weight: 500;
    --typo-document-subtitle-letter-spacing: 0.01em;

    /* Document metadata */
    --typo-document-metadata-font-family: var(--font-tight);
    --typo-document-metadata-font-weight: 500;
    --typo-document-metadata-letter-spacing: 0em;

    /* Home section title */
    --typo-section-title-font-family: var(--font-tight);
    --typo-section-title-font-weight: 500;
    --typo-section-title-font-size: 1.25rem;
    --typo-section-title-letter-spacing: 0em;

    /* Home section description */
    --typo-section-description-font-family: var(--font-tight);
    --typo-section-description-font-weight: 400;
    --typo-section-description-letter-spacing: 0em;

    /* Summary */
    --typo-summary-title-font-family: var(--font-tight);
    --typo-summary-title-font-weight: 500;
    --typo-summary-description-font-family: var(--font-tight);
    --typo-summary-description-font-weight: 400;

    /* Subscription */
    --typo-promotion-title-font-family: var(--font-tight);
    --typo-promotion-title-font-weight: 500;
    --typo-promotion-description-font-family: var(--font-tight);
    --typo-promotion-description-font-weight: 400;

    /* Cookies */
    --typo-cookies-title-font-family: var(--font-garage);
    --typo-cookies-title-font-weight: 700;
    --typo-modal-description-font-family: var(--font-tight);
    --typo-modal-description-font-weight: 500;

    /* Map */
    --typo-sidebar-title-font-family: var(--font-wide);
    --typo-sidebar-title-font-weight: 700;
    --typo-sidebar-subtitle-font-weight: 500;
    --typo-sidebar-list-title-font-family: var(--font-wide);
    --typo-sidebar-list-title-font-weight: 500;
}
