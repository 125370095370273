/**
 * Sina nova
 */
@font-face {
    font-display: swap;
    font-family: 'Sina Nova';
    src: url('../assets/fonts/sina-nova/SinaNovaReg.eot?') format('eot'),
        url('../assets/fonts/sina-nova/SinaNovaReg.woff2') format('woff2'),
        url('../assets/fonts/sina-nova/SinaNovaReg.woff') format('woff'),
        url('../assets/fonts/sina-nova/SinaNovaReg.otf') format('opentype'),
        url('../assets/fonts/sina-nova/SinaNovaReg.ttf') format('truetype'),
        url('../assets/fonts/sina-nova/SinaNovaReg.svg#SinaNovaReg') format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Sina Nova';
    font-style: italic;
    src: url('../assets/fonts/sina-nova/SinaNovaIta.eot?') format('eot'),
        url('../assets/fonts/sina-nova/SinaNovaIta.woff2') format('woff2'),
        url('../assets/fonts/sina-nova/SinaNovaIta.woff') format('woff'),
        url('../assets/fonts/sina-nova/SinaNovaIta.otf') format('opentype'),
        url('../assets/fonts/sina-nova/SinaNovaIta.ttf') format('truetype'),
        url('../assets/fonts/sina-nova/SinaNovaIta.svg#SinaNovaIta') format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Sina Nova';
    font-weight: 700;
    src: url('../assets/fonts/sina-nova/SinaNovaBol.eot?') format('eot'),
        url('../assets/fonts/sina-nova/SinaNovaBol.woff2') format('woff2'),
        url('../assets/fonts/sina-nova/SinaNovaBol.woff') format('woff'),
        url('../assets/fonts/sina-nova/SinaNovaBol.otf') format('opentype'),
        url('../assets/fonts/sina-nova/SinaNovaBol.ttf') format('truetype'),
        url('../assets/fonts/sina-nova/SinaNovaBol.svg#SinaNovaBol') format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Sina Nova';
    font-style: italic;
    font-weight: 700;
    src: url('../assets/fonts/sina-nova/SinaNovaBolIta.eot?') format('eot'),
        url('../assets/fonts/sina-nova/SinaNovaBolIta.woff2') format('woff2'),
        url('../assets/fonts/sina-nova/SinaNovaBolIta.woff') format('woff'),
        url('../assets/fonts/sina-nova/SinaNovaBolIta.otf') format('opentype'),
        url('../assets/fonts/sina-nova/SinaNovaBolIta.ttf') format('truetype'),
        url('../assets/fonts/sina-nova/SinaNovaBolIta.svg#SinaNovaBolIta') format('svg');
}

/**
 * Garage Gothic
 */
@font-face {
    font-display: swap;
    font-family: 'Garage Gothic';
    font-weight: 700;
    src: url('../assets/fonts/garage-gothic/GarageGothic.eot?') format('eot'),
        url('../assets/fonts/garage-gothic/GarageGothic.woff2') format('woff2'),
        url('../assets/fonts/garage-gothic/GarageGothic.woff') format('woff'),
        url('../assets/fonts/garage-gothic/GarageGothic.otf') format('opentype'),
        url('../assets/fonts/garage-gothic/GarageGothic.ttf') format('truetype'),
        url('../assets/fonts/garage-gothic/GarageGothic.svg#GarageGothic-Bold') format('svg');
}

/**
 * Agrandir
 */
@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-weight: 100;
    src: url('../assets/fonts/agrandir/Agrandir-Thin.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-Thin.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-Thin.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-style: italic;
    font-weight: 100;
    src: url('../assets/fonts/agrandir/Agrandir-ThinItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-ThinItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-ThinItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-weight: 200;
    src: url('../assets/fonts/agrandir/Agrandir-Light.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-Light.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-Light.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-style: italic;
    font-weight: 200;
    src: url('../assets/fonts/agrandir/Agrandir-LightItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-LightItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-LightItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-weight: 400;
    src: url('../assets/fonts/agrandir/Agrandir-Regular.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-Regular.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-Regular.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-style: italic;
    font-weight: 400;
    src: url('../assets/fonts/agrandir/Agrandir-Italic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-Italic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-Italic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-weight: 500;
    src: url('../assets/fonts/agrandir/Agrandir-Medium.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-Medium.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-Medium.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-style: italic;
    font-weight: 500;
    src: url('../assets/fonts/agrandir/Agrandir-MediumItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-MediumItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-MediumItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-weight: 700;
    src: url('../assets/fonts/agrandir/Agrandir-Bold.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-Bold.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-Bold.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-style: italic;
    font-weight: 700;
    src: url('../assets/fonts/agrandir/Agrandir-BoldItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-BoldItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-weight: 800;
    src: url('../assets/fonts/agrandir/Agrandir-Heavy.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-Heavy.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-Heavy.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-style: italic;
    font-weight: 800;
    src: url('../assets/fonts/agrandir/Agrandir-HeavyItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-HeavyItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-HeavyItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-weight: 900;
    src: url('../assets/fonts/agrandir/Agrandir-Black.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-Black.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-Black.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: Agrandir;
    font-style: italic;
    font-weight: 900;
    src: url('../assets/fonts/agrandir/Agrandir-BlackItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-BlackItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-BlackItalic.woff') format('woff');
}

/* Tight */

@font-face {
    font-display: swap;
    font-family: AgrandirTight;
    font-weight: 100;
    src: url('../assets/fonts/agrandir/Agrandir-TightThin.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-TightThin.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-TightThin.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirTight;
    font-style: italic;
    font-weight: 100;
    src: url('../assets/fonts/agrandir/Agrandir-TightThinItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-TightThinItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-TightThinItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirTight;
    font-weight: 200;
    src: url('../assets/fonts/agrandir/Agrandir-TightLight.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-TightLight.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-TightLight.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirTight;
    font-style: italic;
    font-weight: 200;
    src: url('../assets/fonts/agrandir/Agrandir-TightLightItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-TightLightItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-TightLightItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirTight;
    font-weight: 400;
    src: url('../assets/fonts/agrandir/Agrandir-Tight.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-Tight.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-Tight.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirTight;
    font-style: italic;
    font-weight: 400;
    src: url('../assets/fonts/agrandir/Agrandir-TightItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-TightItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-TightItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirTight;
    font-weight: 500;
    src: url('../assets/fonts/agrandir/Agrandir-TightMedium.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-TightMedium.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-TightMedium.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirTight;
    font-style: italic;
    font-weight: 500;
    src: url('../assets/fonts/agrandir/Agrandir-TightMediumItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-TightMediumItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-TightMediumItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirTight;
    font-weight: 700;
    src: url('../assets/fonts/agrandir/Agrandir-TightMedium.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-TightMedium.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-TightMedium.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirTight;
    font-style: italic;
    font-weight: 700;
    src: url('../assets/fonts/agrandir/Agrandir-TightMediumItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-TightMediumItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-TightMediumItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirTight;
    font-weight: 800;
    src: url('../assets/fonts/agrandir/Agrandir-TightHeavy.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-TightHeavy.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-TightHeavy.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirTight;
    font-style: italic;
    font-weight: 800;
    src: url('../assets/fonts/agrandir/Agrandir-TightHeavyItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-TightHeavyItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-TightHeavyItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirTight;
    font-weight: 900;
    src: url('../assets/fonts/agrandir/Agrandir-TightBlack.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-TightBlack.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-TightBlack.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirTight;
    font-style: italic;
    font-weight: 900;
    src: url('../assets/fonts/agrandir/Agrandir-TightBlackItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-TightBlackItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-TightBlackItalic.woff') format('woff');
}

/* Wide */

@font-face {
    font-display: swap;
    font-family: AgrandirWide;
    font-weight: 100;
    src: url('../assets/fonts/agrandir/Agrandir-WideThin.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-WideThin.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-WideThin.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirWide;
    font-style: italic;
    font-weight: 100;
    src: url('../assets/fonts/agrandir/Agrandir-WideThinItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-WideThinItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-WideThinItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirWide;
    font-weight: 200;
    src: url('../assets/fonts/agrandir/Agrandir-WideLight.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-WideLight.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-WideLight.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirWide;
    font-style: italic;
    font-weight: 200;
    src: url('../assets/fonts/agrandir/Agrandir-WideLightItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-WideLightItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-WideLightItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirWide;
    font-weight: 400;
    src: url('../assets/fonts/agrandir/Agrandir-Wide.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-Wide.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-Wide.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirWide;
    font-style: italic;
    font-weight: 400;
    src: url('../assets/fonts/agrandir/Agrandir-WideItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-WideItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-WideItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirWide;
    font-weight: 500;
    src: url('../assets/fonts/agrandir/Agrandir-WideMedium.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-WideMedium.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-WideMedium.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirWide;
    font-style: italic;
    font-weight: 500;
    src: url('../assets/fonts/agrandir/Agrandir-WideMediumItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-WideMediumItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-WideMediumItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirWide;
    font-weight: 700;
    src: url('../assets/fonts/agrandir/Agrandir-WideBold.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-WideBold.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-WideBold.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirWide;
    font-style: italic;
    font-weight: 700;
    src: url('../assets/fonts/agrandir/Agrandir-WideBoldItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-WideBoldItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-WideBoldItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirWide;
    font-weight: 800;
    src: url('../assets/fonts/agrandir/Agrandir-WideHeavy.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-WideHeavy.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-WideHeavy.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirWide;
    font-style: italic;
    font-weight: 800;
    src: url('../assets/fonts/agrandir/Agrandir-WideHeavyItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-WideHeavyItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-WideHeavyItalic.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirWide;
    font-weight: 900;
    src: url('../assets/fonts/agrandir/Agrandir-WideBlack.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-WideBlack.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-WideBlack.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: AgrandirWide;
    font-style: italic;
    font-weight: 900;
    src: url('../assets/fonts/agrandir/Agrandir-WideBlackItalic.eot?') format('eot'),
        url('../assets/fonts/agrandir/Agrandir-WideBlackItalic.woff2') format('woff2'),
        url('../assets/fonts/agrandir/Agrandir-WideBlackItalic.woff') format('woff');
}

/**
 * Avant Garde
 */
@font-face {
    font-display: swap;
    font-family: 'Avant Garde';
    src: url('../assets/fonts/avant-garde/avantgarde.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/avantgarde.woff') format('woff'),
        url('../assets/fonts/avant-garde/avantgarde.otf') format('opentype');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde';
    font-weight: bold;
    src: url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Bold.eot?') format('eot'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Bold.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Bold.woff') format('woff'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Bold.otf') format('opentype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Bold.ttf') format('truetype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Bold.svg#AvantGardeITCbyBT-Bold')
            format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde Condensed';
    font-weight: bold;
    src: url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BoldCondensed.eot?') format('eot'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BoldCondensed.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BoldCondensed.woff') format('woff'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BoldCondensed.otf') format('opentype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BoldCondensed.ttf') format('truetype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BoldCondensed.svg#AvantGardeITCbyBT-BoldCondensed')
            format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde';
    font-style: italic;
    font-weight: bold;
    src: url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BoldOblique.eot?') format('eot'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BoldOblique.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BoldOblique.woff') format('woff'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BoldOblique.otf') format('opentype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BoldOblique.ttf') format('truetype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BoldOblique.svg#AvantGardeITCbyBT-BoldOblique')
            format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde';
    font-weight: 300;
    src: url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Book.eot?') format('eot'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Book.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Book.woff') format('woff'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Book.otf') format('opentype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Book.ttf') format('truetype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Book.svg#AvantGardeITCbyBT-Book')
            format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde Condensed';
    font-weight: 300;
    src: url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BookCondensed.eot?') format('eot'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BookCondensed.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BookCondensed.woff') format('woff'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BookCondensed.otf') format('opentype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BookCondensed.ttf') format('truetype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BookCondensed.svg#AvantGardeITCbyBT-BookCondensed')
            format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde';
    font-style: italic;
    font-weight: 300;
    src: url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BookOblique.eot?') format('eot'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BookOblique.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BookOblique.woff') format('woff'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BookOblique.otf') format('opentype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BookOblique.ttf') format('truetype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-BookOblique.svg#AvantGardeITCbyBT-BookOblique')
            format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde';
    font-weight: 600;
    src: url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Demi.eot?') format('eot'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Demi.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Demi.woff') format('woff'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Demi.otf') format('opentype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Demi.ttf') format('truetype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Demi.svg#AvantGardeITCbyBT-Demi')
            format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde Condensed';
    font-weight: 600;
    src: url('../assets/fonts/avant-garde/AvantGardeITCbyBT-DemiCondensed.eot?') format('eot'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-DemiCondensed.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-DemiCondensed.woff') format('woff'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-DemiCondensed.otf') format('opentype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-DemiCondensed.ttf') format('truetype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-DemiCondensed.svg#AvantGardeITCbyBT-DemiCondensed')
            format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde';
    font-style: italic;
    font-weight: 600;
    src: url('../assets/fonts/avant-garde/AvantGardeITCbyBT-DemiOblique.eot?') format('eot'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-DemiOblique.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-DemiOblique.woff') format('woff'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-DemiOblique.otf') format('opentype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-DemiOblique.ttf') format('truetype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-DemiOblique.svg#AvantGardeITCbyBT-DemiOblique')
            format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde';
    font-weight: 100;
    src: url('../assets/fonts/avant-garde/AvantGardeITCbyBT-ExtraLight.eot?') format('eot'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-ExtraLight.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-ExtraLight.woff') format('woff'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-ExtraLight.otf') format('opentype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-ExtraLight.ttf') format('truetype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-ExtraLight.svg#AvantGardeITCbyBT-ExtraLight')
            format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde';
    font-style: italic;
    font-weight: 100;
    src: url('../assets/fonts/avant-garde/AvantGardeITCbyBT-ExtraLightObl.eot?') format('eot'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-ExtraLightObl.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-ExtraLightObl.woff') format('woff'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-ExtraLightObl.otf') format('opentype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-ExtraLightObl.ttf') format('truetype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-ExtraLightObl.svg#AvantGardeITCbyBT-ExtraLightObl')
            format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde';
    font-weight: 500;
    src: url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Medium.eot?') format('eot'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Medium.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Medium.woff') format('woff'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Medium.otf') format('opentype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Medium.ttf') format('truetype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-Medium.svg#AvantGardeITCbyBT-Medium')
            format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde Condensed';
    font-weight: 500;
    src: url('../assets/fonts/avant-garde/AvantGardeITCbyBT-MediumCond.eot?') format('eot'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-MediumCond.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-MediumCond.woff') format('woff'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-MediumCond.otf') format('opentype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-MediumCond.ttf') format('truetype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-MediumCond.svg#AvantGardeITCbyBT-MediumCond')
            format('svg');
}

@font-face {
    font-display: swap;
    font-family: 'Avant Garde';
    font-style: italic;
    font-weight: 500;
    src: url('../assets/fonts/avant-garde/AvantGardeITCbyBT-MediumOblique.eot?') format('eot'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-MediumOblique.woff2') format('woff2'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-MediumOblique.woff') format('woff'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-MediumOblique.otf') format('opentype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-MediumOblique.ttf') format('truetype'),
        url('../assets/fonts/avant-garde/AvantGardeITCbyBT-MediumOblique.svg#AvantGardeITCbyBT-MediumOblique')
            format('svg');
}

/**
 * Maax
 */
@font-face {
    font-display: swap;
    font-family: Maax;
    font-weight: 400;
    src: url('../assets/fonts/maax/maax.eot?') format('eot'),
        url('../assets/fonts/maax/maax.woff2') format('woff2'),
        url('../assets/fonts/maax/maax.woff') format('woff'),
        url('../assets/fonts/maax/maax.otf') format('opentype'),
        url('../assets/fonts/maax/maax.ttf') format('truetype'),
        url('../assets/fonts/maax/maax.svg#maax') format('svg');
}

@font-face {
    font-display: swap;
    font-family: Maax;
    font-style: italic;
    font-weight: 400;
    src: url('../assets/fonts/maax/maaxitalic.eot?') format('eot'),
        url('../assets/fonts/maax/maaxitalic.woff2') format('woff2'),
        url('../assets/fonts/maax/maaxitalic.woff') format('woff'),
        url('../assets/fonts/maax/maaxitalic.otf') format('opentype'),
        url('../assets/fonts/maax/maaxitalic.ttf') format('truetype'),
        url('../assets/fonts/maax/maaxitalic.svg#maaxitalic') format('svg');
}

@font-face {
    font-display: swap;
    font-family: Maax;
    font-weight: 700;
    src: url('../assets/fonts/maax/maaxbold.eot?') format('eot'),
        url('../assets/fonts/maax/maaxbold.woff2') format('woff2'),
        url('../assets/fonts/maax/maaxbold.woff') format('woff'),
        url('../assets/fonts/maax/maaxbold.otf') format('opentype'),
        url('../assets/fonts/maax/maaxbold.ttf') format('truetype'),
        url('../assets/fonts/maax/maaxbold.svg#maaxbold') format('svg');
}

@font-face {
    font-display: swap;
    font-family: Maax;
    font-style: italic;
    font-weight: 700;
    src: url('../assets/fonts/maax/maaxbolditalic.eot?') format('eot'),
        url('../assets/fonts/maax/maaxbolditalic.woff2') format('woff2'),
        url('../assets/fonts/maax/maaxbolditalic.woff') format('woff'),
        url('../assets/fonts/maax/maaxbolditalic.otf') format('opentype'),
        url('../assets/fonts/maax/maaxbolditalic.ttf') format('truetype'),
        url('../assets/fonts/maax/maaxbolditalic.svg#maaxbolditalic') format('svg');
}

@font-face {
    font-display: swap;
    font-family: Maax;
    font-weight: 500;
    src: url('../assets/fonts/maax/maaxmedium.eot?') format('eot'),
        url('../assets/fonts/maax/maaxmedium.woff2') format('woff2'),
        url('../assets/fonts/maax/maaxmedium.woff') format('woff'),
        url('../assets/fonts/maax/maaxmedium.otf') format('opentype'),
        url('../assets/fonts/maax/maaxmedium.ttf') format('truetype'),
        url('../assets/fonts/maax/maaxmedium.svg#maaxmedium') format('svg');
}

@font-face {
    font-display: swap;
    font-family: Maax;
    font-style: italic;
    font-weight: 500;
    src: url('../assets/fonts/maax/maaxmediumitalic.eot?') format('eot'),
        url('../assets/fonts/maax/maaxmediumitalic.woff2') format('woff2'),
        url('../assets/fonts/maax/maaxmediumitalic.woff') format('woff'),
        url('../assets/fonts/maax/maaxmediumitalic.otf') format('opentype'),
        url('../assets/fonts/maax/maaxmediumitalic.ttf') format('truetype'),
        url('../assets/fonts/maax/maaxmediumitalic.svg#maaxmediumitalic') format('svg');
}
