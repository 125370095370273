.container {
    color: var(--control-color);
}

.fields {
    width: 100%;
}

.fieldGroup {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;

    .field {
        width: 100%;
    }

    .input {
        min-width: 16em;
        transition: padding-right 0.2s;
    }

    .primaryButton {
        margin-top: var(--spacing-small);
    }

    .loadingIcon {
        position: absolute;
        top: 0.75em;
        right: 0.5em;
        width: 1em;
        height: 1em;
        opacity: 0;
        pointer-events: none;

        .loading & {
            opacity: 1;
        }
    }

    .alwaysHorizontal & {
        .input {
            padding-right: 6em;
        }

        .loadingIcon {
            right: 5.5em;
        }

        .primaryButton {
            position: absolute;
            top: 0.325em;
            right: 0.3em;
            padding: 0.5em 0.6em 0.3em;
            border-radius: var(--control-border-radius);
            margin-top: 0;
            font-size: 0.9em;
            transition: transform 0.2s;
        }
    }

    @media (--small-viewport) {
        .input {
            padding-right: 6em;
        }

        .loadingIcon {
            right: 5.5em;
        }

        .primaryButton {
            position: absolute;
            top: 0.325em;
            right: 0.3em;
            padding: 0.5em 0.6em 0.3em;
            border-radius: var(--control-border-radius);
            margin-top: 0;
            font-size: 0.9em;
            transition: transform 0.2s;
        }
    }
}

.sso {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 10px;
    margin: -2.5px;
    font-size: 0.9rem;

    .or {
        padding: 0 10px 0 5px;
        font-size: 0.85rem;
    }

    .button {
        width: auto;
        margin: 2.5px;

        /* &:last-child {
            margin-right: 0;
        } */
    }
}

.subscriptions,
.buttons {
    display: none;
    padding-top: var(--spacing);
    font-size: 1em;
}

.subscription {
    margin-bottom: var(--block-spacing);

    &:last-child {
        margin-bottom: 0;
    }
}

.linkButton {
    padding: 0;
    font-weight: normal;
    text-decoration: underline;
}

.buttons {
    .secondaryButton {
        padding-left: var(--spacing);
        margin-right: auto;
    }

    .primaryButton {
        margin-left: auto;
    }
}

.subscribed {
    .input {
        padding-right: 0;
    }

    .fieldGroup {
        overflow: hidden;

        .primaryButton {
            position: absolute;
            right: 0.5em;
            font-size: 0.9rem;
            transform: translateX(calc(100% + 0.5em));
        }
    }
}

.buttonOnly {
    display: flex;

    .fields {
        width: auto;
    }

    .fieldGroup {
        display: block;
        overflow: hidden;
        width: auto;

        .field {
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            width: 200%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
        }

        .input {
            cursor: pointer;
        }

        .primaryButton {
            position: relative;
            top: auto;
            right: auto;
            padding: var(--button-rounded-padding);
            font-size: 1em;
        }
    }

    .sso {
        display: none;
    }

    &.hasUser {
        .field {
            display: none;
        }
    }
}

.full {
    .button {
        opacity: 0;
        transform: translateX(100%);
    }

    .subscriptions,
    .buttons {
        padding-top: var(--block-spacing);
    }

    .subscriptions {
        display: block;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;

        .secondaryButton {
            display: block;
        }
    }
}
