.container {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    color: var(--card-color, inherit);
    font-size: var(--card-font-size-featured);
    text-decoration: none;

    .inner {
        display: flex;
        width: 100%;
        flex-grow: 1;
    }

    .imageContainer {
        position: relative;
        z-index: 1;
        /* overflow: hidden; */
        width: 100%;

        .image {
            width: 100%;
            height: 100%;
        }
    }

    .label {
        position: relative;
        z-index: 1;
        display: flex;
        width: 100%;
        min-width: 6em;
        max-width: 10em;
        height: 100%;
        flex-direction: column;
        justify-content: center;
    }

    .subscriptionForm {
        font-size: 1rem;
    }

    .before {
        display: flex;
        flex-direction: column;

        > * {
            margin-top: var(--card-spacing-featured);
        }

        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: var(--card-spacing-featured);

            &.surtitle {
                margin-bottom: var(--card-spacing-surtitle);
            }
        }
    }

    .after {
        display: flex;
        flex-direction: column;

        > * {
            margin-top: var(--card-spacing-featured);
        }
    }

    .category {
        font-size: var(--card-font-size-featured-category);
    }

    .surtitle {
        font-size: var(--card-font-size-featured-surtitle);
    }

    .title {
        color: var(--card-color-title, inherit);
        font-size: var(--card-font-size-featured-title);
        font-style: normal;
        font-weight: 500;
        line-height: 0.88;
        text-transform: uppercase;
    }

    .meta {
        display: flex;
        flex-direction: row;
        align-items: center;

        > * {
            margin-right: var(--spacing);

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .date {
        font-size: var(--card-font-size-featured-date);
        font-style: normal;
        font-weight: 500;
    }

    .description {
        font-size: var(--card-font-size-featured-description);
    }

    .author {
        z-index: 2;
        font-size: var(--card-font-size-featured-author);

        &.withBox {
            padding: 0.5em 0;
        }
    }

    .sponsor {
        font-size: var(--card-font-size-featured-sponsor);

        .sponsorLabel {
            font-weight: 400;
            white-space: nowrap;
        }
    }

    .urbaniaLogo {
        position: absolute;
        top: 0;
        left: 0;
        width: var(--card-urbania-logo-width, 50%);
        margin-top: var(--side-spacing);
        margin-left: var(--side-spacing);
    }

    &.withoutText {
        .label {
            display: none;
        }
    }

    &.withBorderRadius {
        .image {
            overflow: hidden;
            border-radius: var(--card-border-radius);
        }
    }

    &.small {
        font-size: var(--card-font-size-featured-small);

        .category {
            font-size: var(--card-font-size-featured-category-small);
        }

        .surtitle {
            font-size: var(--card-font-size-featured-surtitle-small);
        }

        .date {
            font-size: var(--card-font-size-featured-date-small);
        }

        .description {
            font-size: var(--card-font-size-featured-description-small);
        }

        .author {
            font-size: var(--card-font-size-featured-author-small);
        }

        .sponsor {
            font-size: var(--card-font-size-featured-sponsor-small);
        }
    }

    &.big {
        font-size: var(--card-font-size-featured-big);
    }

    &.withBreakpoints {
        font-size: var(--card-font-size-featured-small);

        .category {
            font-size: var(--card-font-size-featured-category-small);
        }

        .surtitle {
            font-size: var(--card-font-size-featured-surtitle-small);
        }

        .date {
            font-size: var(--card-font-size-featured-date-small);
        }

        .description {
            font-size: var(--card-font-size-featured-description-small);
        }

        .author {
            font-size: var(--card-font-size-featured-author-small);
        }

        .sponsor {
            font-size: var(--card-font-size-featured-sponsor-small);
        }

        @media (--medium-viewport) {
            font-size: var(--card-font-size-featured);

            .category {
                font-size: var(--card-font-size-featured-category);
            }

            .surtitle {
                font-size: var(--card-font-size-featured-surtitle);
            }

            .date {
                font-size: var(--card-font-size-featured-date);
            }

            .description {
                font-size: var(--card-font-size-featured-description);
            }

            .author {
                font-size: var(--card-font-size-featured-author-medium);
            }

            .sponsor {
                font-size: var(--card-font-size-featured-sponsor);
            }
        }

        @media (--large-viewport) {
            font-size: var(--card-font-size-featured-big);

            .author {
                font-size: var(--card-font-size-featured-author-large);
            }
        }
    }

    &.over,
    &.leftCenter,
    &.rightCenter {
        .label {
            display: flex;
            align-items: center;
            margin: auto;
        }

        .before,
        .after {
            align-items: center;
        }

        .surtitle,
        .title,
        .description {
            text-align: center;
        }

        .sponsor {
            text-align: center;
        }
    }

    &.right {
        .inner {
            justify-content: flex-end;
        }

        .label {
            text-align: right;
        }

        .before,
        .after {
            align-items: flex-end;
        }
    }

    &.right,
    &.rightCenter {
        .label {
            align-items: flex-start;
            padding-right: inherit;
            padding-left: var(--spacing);
        }

        .before,
        .after {
            align-items: flex-start;
        }

        .title,
        .description {
            text-align: left;
        }

        @media (--small-viewport) {
            .inner {
                flex-direction: row-reverse;
            }

            .label {
                padding-right: 0;
                padding-left: var(--spacing-medium);
            }
        }
    }

    &.topLeft,
    &.bottomLeft,
    &.topRight,
    &.bottomRight {
        .sponsor {
            position: relative;
            bottom: inherit;
        }
    }

    &.topLeft,
    &.bottomLeft {
        .label {
            margin-right: auto;
            justify-content: flex-start;
        }

        .before,
        .after {
            align-items: flex-start;
        }
    }

    &.bottomLeft {
        .label {
            margin-top: auto;
            justify-content: flex-end;
        }
    }

    &.topRight,
    &.bottomRight {
        .inner {
            margin-right: left;
            justify-content: flex-end;
        }

        .label {
            justify-content: flex-start;
            text-align: right;
        }

        .before,
        .after {
            align-items: flex-end;
        }
    }

    &.bottomRight {
        .label {
            margin-top: auto;
            justify-content: flex-end;
        }
    }

    &.topLeft,
    &.topRight {
        .urbaniaLogo {
            top: auto;
            bottom: 0;
            margin-top: 0;
            margin-bottom: var(--side-spacing);
        }
    }

    &.isOutside {
        .inner {
            flex-direction: column-reverse;
        }

        .label {
            min-width: 0;
            padding-top: var(--spacing-medium);
            margin: auto 0;
        }

        .title {
            font-size: 0.9em;
        }

        &.withRatio {
            .imageContainer {
                display: flex;
                flex-direction: column;
            }

            .image {
                display: block;
                width: 100%;
                height: 0;
                margin: auto;
            }
        }

        &.horizontal {
            .image {
                padding-bottom: 80%;
            }
        }

        &.square {
            .image {
                padding-bottom: 100%;
            }
        }

        &.vertical {
            .image {
                padding-bottom: 125%;
            }
        }

        &.micromag {
            .image {
                padding-bottom: 150%;
            }
        }

        &.responsive {
            .imageContainer {
                flex-grow: 1;
            }

            .image {
                height: 100%;
                padding-bottom: 0;
            }
        }

        &.top,
        &.topRight,
        &.topLeft,
        &.bottom,
        &.bottomRight,
        &.bottomLeft {
            .label {
                max-width: none;
                height: auto;
                margin: 0;
            }
        }

        &.top,
        &.topRight,
        &.topLeft {
            .inner {
                flex-direction: column;
            }
        }

        &.bottom,
        &.bottomRight,
        &.bottomLeft {
            .inner {
                flex-direction: column-reverse;
            }
        }

        @media (--small-viewport) {
            .inner {
                flex-direction: row;
            }

            .label {
                min-width: 6em;
                max-width: 10em;
                padding-top: 0;
                padding-right: var(--spacing-medium);
            }

            .summary {
                max-width: 400px;
            }

            &.top,
            &.topRight,
            &.topLeft,
            &.bottom,
            &.bottomRight,
            &.bottomLeft {
                .label {
                    min-width: 0;
                    max-width: none;
                    padding-top: 0;
                    padding-right: 0;
                }
            }

            &.top,
            &.topRight,
            &.topLeft {
                .inner {
                    flex-direction: column;
                }
            }

            &.bottom,
            &.bottomRight,
            &.bottomLeft {
                .inner {
                    flex-direction: column-reverse;
                }
            }

            &.isBox {
                .label {
                    padding: var(--side-spacing);
                }
            }
        }
    }

    &.over {
        .label {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &.isBox {
        .label {
            padding: var(--side-spacing);
            background-color: var(--card-label-background, var(--card-box-translucent-background));
        }
    }

    &.isOver {
        color: var(--card-color, var(--color-white));

        .inner {
            position: relative;
        }

        .imageContainer {
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .image {
            z-index: 1;

            &::after {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background-color: rgb(0 0 0 / 0.1);
                background-image: url('../../assets/img/over_tile.png');
                background-repeat: repeat;
                background-size: 500px 500px;
                content: '';
            }
        }

        .label {
            /* width: 100%; */
            padding: var(--side-spacing);
            text-shadow: var(--card-featured-text-shadow);
        }

        .author {
            text-shadow: none;
        }

        .sponsor {
            bottom: var(--spacing);
        }
    }
}
