.container {
    overflow: hidden;
    color: inherit;
    font-size: var(--card-font-size);
    text-decoration: none;

    .thumbnail {
        position: relative;
        overflow: hidden;
        width: 100%;
        border-radius: var(--card-border-radius);
        background: var(--color-grey);

        &::after {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            box-shadow: var(--card-emboss-box-shadow);
            content: '';
        }
    }

    .thumbnailContainer {
        margin-right: var(--card-spacing);
    }

    .title {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
    }

    .icon {
        display: block;
        width: 1.5em;
        height: 1.5em;
    }

    &.withBorder {
        padding-bottom: var(--card-spacing);
        border-bottom: var(--card-border-bottom);
    }

    &.big {
        font-size: var(--card-font-size-big);
    }

    &.small {
        font-size: var(--card-font-size-small);
    }

    &.pill {
        width: auto;
        border-bottom: none;

        .inner {
            display: flex;
            padding: var(--button-pill-padding-compact);
            border: var(--button-pill-border);
            border-radius: var(--button-pill-border-radius);
            background-color: var(--map-button-transparency-color);
            transition: background-color 0.2s ease-out;

            &:hover {
                background-color: var(--map-button-hover-color);
            }
        }

        &.withBorder {
            padding-bottom: 0;
            border-bottom: 0;
        }

        &.active {
            .inner {
                background-color: var(--map-active-color);
            }
        }
    }
}
