.container {
    overflow: hidden;
    color: var(--card-color, inherit);
    font-size: var(--card-font-size);
    text-decoration: none;

    .inner {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .thumbnail {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .surtitle {
        margin-bottom: var(--card-spacing-surtitle);
        font-size: var(--card-font-size-surtitle);
        opacity: var(--card-surtitle-opacity);
    }

    .label {
        /* position: relative; */
        /* display: flex; */
        width: 100%;
        padding-top: var(--card-spacing);
    }

    .description {
        font-size: 0.8em;

        p, br {
            display: none;
            margin: 0;
        }

        p:first-child {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }
    }

    &.withBorder {
        border-bottom: var(--card-border-bottom);
    }

    &.big {
        font-size: var(--card-font-size-big);
    }

    &.small {
        font-size: var(--card-font-size-small);
    }

    &.labelOnTop {
        padding-top: 0;
        padding-bottom: var(--card-spacing);
        border-bottom: 0;

        .label {
            position: relative;
            display: flex;
            width: 100%;
        }
    }

    &.box {
        position: relative;
        overflow: hidden;
        width: 100%;
        border-radius: var(--card-border-radius);
        background-color: var(--color-white);
        color: var(--card-color, var(--color-black));

        .label {
            min-height: 3em;
            padding-right: var(--card-spacing-label);
            padding-bottom: var(--card-spacing);
            padding-left: var(--card-spacing-label);
        }
    }

    &.boxTranslucent {
        background-color: var(--card-box-translucent-background);
        color: var(--card-color, var(--color-black));
    }

    &.boxTranslucentDark {
        background-color: var(--card-box-translucent-dark-background);
        color: var(--card-color, var(--color-black));

        &.hasSponsor {
            .meta {
                background: var(--card-sponsor-background);
            }
        }
    }

    &.boxDark {
        background-color: var(--color-black);
        color: var(--card-color, var(--color-grey));
    }

    &.boxBrand {
        background-color: var(--brand-primary-color, var(--color-white));
        color: var(--card-color, var(--text-on-primary-color, var(--color-black)));
    }

    &.suggestion {
        border-bottom: 0;
        font-size: var(--card-font-size-suggestion);

        .thumbnail {
            overflow: hidden;
            border-radius: var(--card-border-radius);

            &::after {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                box-shadow: var(--card-emboss-box-shadow);
                content: '';
            }
        }
    }
}
