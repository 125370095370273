.container {
}

.items {
    display: flex;
    overflow: hidden;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    margin: -2.5px;
}

.item {
    margin: 2.5px;
}



