:root {
    --spacing-small: 5px;
    --spacing: 10px;
    --spacing-medium: 20px;
    --spacing-large: 40px;
    --spacing-larger: 60px;

    /* Sides */
    --side-spacing: 10px;
    --side-spacing-medium: 40px;
    --side-spacing-menu: 10px;
    --block-spacing: 20px;
    --list-spacing: var(--spacing);
    --grid-spacing: var(--list-spacing);
    --row-spacing: var(--list-spacing);
    --infinite-list-spacing: var(--block-spacing);
    --infinite-list-separator-spacing: var(--block-spacing);
}
