.container {
    --promotion-image-size: 4em;

    padding-bottom: calc((var(--promotion-image-size) - 2em) / 2);
    box-shadow: none !important;
    text-decoration: none;

    .inner {
        position: relative;
        display: block;
    }

    .label {
        display: block;
        padding: 0.5em 0.75em 0.4em;
        padding-right: calc((var(--promotion-image-size) / 2) + 0.5em);
        border-radius: var(--promotion-border-radius, 0.5rem);
        margin-right: calc(var(--promotion-image-size) / 2);
        background: var(--promotion-background, var(--color-white));
        box-shadow: var(--dialog-box-shadow);
        color: var(--promotion-color, inherit);
        transform-origin: center right;
    }

    .imageContainer {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: var(--promotion-image-size);
        height: var(--promotion-image-size);
        transform: translateY(-50%);
    }

    .imageFrame {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }

    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: solid 2px #fff;
        border-radius: var(--promotion-image-size);
        box-shadow: var(--dialog-box-shadow);
    }
}
