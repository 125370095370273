.container {
    display: block;
    overflow: hidden;
    font-size: 1rem;

    .thumbnail {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: var(--card-border-radius);
        background-color: var(--brand-primary-color);

        &::after {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            box-shadow: var(--card-emboss-box-shadow);
            content: '';
            transition: box-shadow 0.2s ease-in-out;
        }
    }

    .picture {
        object-fit: contain;
    }

    &:active,
    &:hover {
        .thumbnail {
            &::after {
                box-shadow: var(--card-emboss-down-box-shadow);
            }
        }
    }

    .label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 10px;
    }

    .name {
        margin-bottom: 0.2em;
    }

    &.menuReverse {
        border-top: 1px solid var(--card-border-color-light);

        .inner {
            flex-direction: row-reverse;
            padding: var(--spacing-small) var(--spacing) 0;
        }

        .thumbnail {
            width: 120px;
            height: 50px;
            border-radius: 0;
            background-color: transparent;

            &::after {
                box-shadow: none;
            }
        }

        .picture {
            width: 120px;
            height: 50px;
        }

        .label {
            padding-right: var(--spacing);
            padding-left: 0;
        }

        .name {
            display: none;
        }

        .description {
            max-width: 10em;
            font-size: var(--card-font-size-brand-description-large);
        }
    }
}
