.container {
    font-family: var(--font-tight);

    .description {
        margin-bottom: 1em;
    }

    .success {
        margin: var(--spacing-medium) 0;
        font-size: 1.2em;
        font-weight: 500;
        text-align: center;
    }

    .formRow {
        margin-bottom: var(--form-row-spacing);
    }

    .formControl {
        &.birthDate {
            width: 10em;
        }

        @media (--small-viewport) {
            &.firstName {
                width: 30%;
            }
        }
    }

    .field {
        width: 100%;
    }

    .optins {
        margin-top: var(--block-spacing);
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: var(--block-spacing);
        text-align: left;

        &.withCancel {
            justify-content: flex-start;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .submitButton {
        font-size: 1em;
    }
}
