.row {
    .micromag {
        width: 120px;
    }

    .video {
        width: 120px;
    }

    .article {
        width: 180px;
    }
}
