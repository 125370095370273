.container {
    /* font-size: 0.75rem; */
}

.control {
    border-radius: 1.7em;
    background: rgba(34, 34, 34, 0.3);
}

.methods {
    display: flex;
    flex-direction: row;
}

.method {
    padding: 0.5em 0.75em 0.3em;
    border-radius: 1.1em;
    margin: 0.2em;
    text-transform: capitalize;
    transition: background 0.2s ease-out, padding 0.2s ease-out;

    &:first-child {
        padding-right: 0.2em;
    }

    &:last-child {
        padding-left: 0.2em;
    }

    &.active {
        padding: 0.5em 0.75em 0.3em;
        background: rgba(255, 255, 255, 0.9);
    }
}
