.container {
    display: block;
    overflow: hidden;
    border-radius: var(--card-border-radius);
    background-color: var(--brand-primary-color);
    font-size: 0.75rem;

    .thumbnail {
        position: absolute;
        width: 100%;
        height: 100%;

        &::after {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            box-shadow: var(--card-emboss-box-shadow);
            content: '';
            transition: box-shadow 0.2s ease-in-out;
        }
    }

    .picture {
        object-fit: contain;
    }

    &:active,
    &:hover {
        .thumbnail {
            &::after {
                box-shadow: var(--card-emboss-down-box-shadow);
            }
        }
    }

    .label {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: var(--card-spacing-large);
    }

    .description {
        color: var(--color-white);
        text-align: center;
        text-shadow: var(--card-text-shadow);
    }
}
