.container {
    position: relative;
    width: 100%;
    height: 100%;

    .image {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.imageRepeat {
    background-position: top center;
    background-repeat: repeat;
}

.fade {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: var(--color-black);
}
