.container {
    position: relative;
    width: 100%;
    background-image: url('../../assets/img/paper1.png');
    background-repeat: repeat;
    background-size: 200px 200px;
    color: var(--color-black);

    .background {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .inner {
        display: flex;
        max-width: 1200px;
        flex-direction: column;
        align-items: flex-start;
        padding: var(--side-spacing);
        text-align: left;
    }

    .imageContainer {
        z-index: 1;
        width: 100%;
        max-width: 500px;
        margin-bottom: var(--block-spacing);
    }

    .image {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }

    .label {
        display: flex;
        flex-direction: column;

        > :last-child {
            margin-bottom: var(--spacing-medium);
        }

        .name {
            margin-bottom: 0;
        }
    }

    .name {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        /* align-items: center; */
        width: 100%;
        font-size: var(--header-font-size-author-name);
    }

    .subscription {
        font-size: 1rem;
        max-width: 400px;
        margin-top: 10px;
        font-family: var(--font-tight);
        text-transform: none;

        .title {
            margin-bottom: var(--spacing-small);
        }

        .link {
            font-weight: normal;
            text-decoration: underline;
        }
    }

    .roles,
    .description,
    .links {
        font-size: var(--header-font-size-description);
    }

    .roles {
        width: 100%;
        padding: var(--spacing) 0;
        border-top: 2px solid;
        border-bottom: 2px solid;
        margin: 0 auto;
        margin-top: var(--block-spacing);
        text-transform: uppercase;
    }

    .description {
        max-width: 40em;
        margin: 0 auto;
        margin-top: var(--spacing);
    }

    .links {
        margin-top: var(--block-spacing);

        .items,
        .item {
            padding: 0;
            margin: 0;
            list-style: none;
        }

        .items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: -5px;
        }

        .item {
            margin: 5px;
        }

        .link {
            padding-bottom: 0.05em;
            border-bottom: solid 2px var(--color-black);
            font-family: var(--font-tight);
            font-weight: 500;
            text-decoration: none;
            text-transform: uppercase;
        }
    }

    @media (--small-viewport) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 0;

        .inner {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            margin: 0;
        }

        .imageContainer {
            width: 50%;
            max-width: 400px;
            margin: 0;
            margin-right: var(--spacing-medium);
        }

        .label {
            margin-right: auto;
        }
    }

    @media (--medium-viewport) {
        .name {
            font-size: var(--header-font-size-author-name-medium);
        }

        .roles,
        .description,
        .links {
            font-size: var(--header-font-size-subtitle-medium);
        }
    }

    @media (--large-viewport) {
        .name {
            font-size: var(--header-font-size-author-name-large);
        }
    }
}
