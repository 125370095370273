.container {
    .title {
        padding: 0 var(--side-spacing);
        margin-bottom: var(--spacing);
        font-size: 1rem;
    }

    .items {
        padding: 0 var(--side-spacing);
        scroll-padding: 0 var(--side-spacing);
    }

    .item {
        scroll-margin-left: 0;
    }

    &.withShadow {
        .items {
            padding-bottom: 5px;
            margin-bottom: -5px;
        }

        .card {
            box-shadow: var(--card-box-shadow);
            transition:
                box-shadow 0.2s ease-out,
                transform 0.2s ease-out;

            &:active {
                box-shadow: var(--card-box-shadow-down);
                transform: translate(1px, 1px);
            }
        }
    }
}
