.container {
    display: inline-flex;
    overflow: hidden;
    width: auto;
    height: 40px;
    flex-direction: row;
    align-items: center;
    padding: 0;
    border: 1px solid #dadce0;
    border-radius: 4px;
    background: #fff;
    color: #3c4043;
    font-size: 1em;

    .iconContainer {
        display: flex;
        width: 36px;
        height: 100%;
        align-items: center;
        justify-content: center;
        background: #fff;
    }

    .icon {
        width: 20px;
        height: 20px;
        height: auto;
        color: #1877f2;

        &.loading {
            color: #000;
        }
    }

    .label {
        display: flex;
        height: 100%;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        padding-right: 10px;
        padding-left: 10px;
        font-family: 'Google Sans', arial, sans-serif;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.25px;
    }

    &.dark {
        border: 2px solid #202124;
        background: #202124;
        color: #e8eaed;
    }

    &.medium {
        height: 32px;

        .iconContainer {
            width: 28px;
        }

        .icon {
            width: 18px;
            height: 18px;
        }
    }

    &.small {
        height: 20px;

        .iconContainer {
            width: 16px;
        }

        .icon {
            width: 12px;
            height: 12px;
        }

        .label {
            font-size: 11px;
            font-weight: 300;
        }
    }

    &.iconOnly {
        .label {
            display: none;
        }
    }
}

.external {
    position: relative;
    display: block;
    overflow: hidden;
    min-width: 100px;
    min-height: 40px;
    border-radius: 5px;
    background: rgba(0 0 0 / 0.1);
    contain: layout paint style;
    transform: translate3d(0, 0, 0);

    .loading {
        position: absolute;
        top: 3px;
        left: 3px;
        width: 26px;
        height: 26px;
        padding: 4px;
        background: #fff;

        .icon {
            display: block;
            width: 18px;
            height: 18px;
        }
    }

    &.medium {
        min-width: 100px;
        min-height: 20px;

        &.google {
            height: 32px;
        }
    }

    &.small {
        min-width: 100px;
        min-height: 20px;

        &.google {
            height: 20px;
        }
    }

    &.iconOnly {
        min-width: 40px;

        &.medium,
        &.small {
            min-width: 20px;
        }
    }
}
