.container {
    .wrapper {
        overflow: hidden;
        opacity: 1;
        transition:
            max-height 0.2s ease-out,
            opacity 0.2s ease-out;
    }

    &.collapsed {
        .wrapper {
            opacity: 0;
        }
    }
}
