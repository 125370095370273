.container {
    .image {
        position: relative;
        width: 100px;
        height: 100px;
        grid-area: image;
    }

    .picture {
        overflow: hidden;
        border-radius: 50%;
    }

    .subscription {
        width: auto;
        margin-left: var(--spacing);
        font-size: 1rem;
    }
}
