.container {
    .sectionButton {
        font-size: 1em;
    }

    .count {
        margin-left: var(--spacing-small);
        font-size: 0.825rem;
        opacity: 0;
        transition: opacity 0.2s ease-out;
    }

    .caret {
        transform: rotate(180deg);
        transition: transform 0.2s ease-out;

        &.rotate {
            transform: rotate(0deg);
        }
    }

    .dateSection {
        margin-bottom: var(--block-spacing);

        &.collapsed {
            .count {
                opacity: 0.7;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .dateButton {
        padding-bottom: var(--spacing);
    }

    .dateTitle {
        text-transform: none;
    }

    .brandSection {
        display: flex;
        width: 100%;
        margin-bottom: var(--spacing);

        &:last-child {
            margin-bottom: 0;
        }
    }

    .brandIcon {
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        margin-right: var(--spacing);

        @media (--medium-viewport) {
            width: 1.375em;
            height: 1.375em;
        }
    }

    .brandItems {
        display: block;
        width: 100%;
        padding-left: var(--spacing);
        border-left: 1px solid var(--color-white);
    }

    .item {
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        text-decoration: none;

        .date {
            position: absolute;
            right: var(--spacing-small);
            color: rgba(0 0 0 / 0.5);
            font-size: 0.75em;
            opacity: 0;
        }

        .card {
            display: block;
            width: 100%;
            padding: var(--spacing-small);
            padding-right: 5em;
        }
/*
        &:hover {
            background: rgba(255 255 255 / 0.3);

            .date {
                opacity: 1;
            }
        } */

        &:last-child {
            margin-bottom: 0;
        }
    }
}
