.container {
    position: relative;
    /* padding: 10px var(--side-spacing); */
    /* background: var(--page-background); */

    /* :global(.nl-container) {
        padding: 0 !important;
        background: transparent !important;
    } */

    :global(.row-content) {
        border: 0 !important;
    }
}
