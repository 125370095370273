.container {
    .title {
        margin-bottom: var(--spacing);
    }

    .actions,
    .control {
        margin-top: var(--spacing);
    }

    .actions {
        margin-top: 20px;
    }

    .button {
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }
    }
}
