.container {
    .section {
        margin-bottom: var(--block-spacing);

        .title {
            margin-bottom: 0;
            text-transform: none;
        }

        .subscriptions {
            margin-top: var(--block-spacing);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .brands {
        display: grid;
        margin-top: var(--block-spacing);
        gap: var(--list-spacing);
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .authors {
        display: grid;
        margin-top: var(--block-spacing);
        gap: var(--list-spacing);
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    .subscription {
        width: 100%;
        margin-bottom: var(--list-spacing);
        font-size: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .notification {
        width: 100%;
        font-size: 0.85rem;
    }
}
