.container {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--spacing) var(--spacing-medium);
    border-radius: 12px;
    background-color: var(--color-white);
    gap: 30px;

    .price {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0;
        gap: var(--spacing-medium);
    }

    .amount {
        position: relative;
        padding: 4px;
        border-radius: 2px;
        background-color: #f04e3e;
        color: var(--color-white);
        line-height: 1.1;
        text-transform: uppercase;

        .label {
            display: block;
            font-size: 0.8rem;
            text-align: center;

            &.bold {
                font-size: 1rem;
                font-weight: bold;
            }
        }

        .arrow {
            position: absolute;
            top: 50%;
            left: 100%;
            display: block;
            width: 0;
            height: 0;
            box-sizing: border-box;
            border-top: var(--spacing-small) solid transparent;
            border-bottom: var(--spacing-small) solid transparent;
            border-left: var(--spacing-small) solid #f04e3e;
            content: '';
            transform: translateY(-50%);
        }
    }

    .document {
        margin: 0 auto;
        box-shadow: 2px 2px 12px rgb(0 0 0 / 0.5);
    }

    .content {
        display: flex;
        flex-direction: column;

        .title, .description, .category {
            margin-top: 0;
        }

        .title {
            color: var(--brand-primary-color);
            font-family: var(--typo-section-title-font-family);
            line-height: 1.15;
        }

        .description {
            margin-bottom: 0;
            color: #45a;
            font-family: var(--typo-section-title-font-family);
            line-height: 1.3;
        }

        .category {
            margin-bottom: 2px;
            color: #45a;
            font-family: var(--typo-section-title-font-family);
            font-size: 0.9em;
            font-weight: 700;
        }
    }

    .link {
        padding: var(--spacing);
        border-radius: 50%;
        background-color: var(--brand-primary-color);
        color: var(--color-white);
    }

    .icon {
        width: 25px;
        height: auto;
        transform: translateX(3px);
    }

    @media (--small-viewport) {
        flex-direction: row;

        .price {
            flex-direction: column;
        }

        .amount {
            margin-bottom: var(--spacing-small);

            .arrow {
                top: auto;
                left: 50%;
                border-top: var(--spacing) solid #f04e3e;
                border-right: var(--spacing) solid transparent;
                border-bottom: 0;
                border-left: var(--spacing) solid transparent;
            transform: translateX(-50%);
            }
        }

    }
}
