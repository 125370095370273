.container {
    .icon {
        position: absolute;
        z-index: 2;
        display: block;
        width: 1em;
        height: 1em;
        margin: var(--spacing);
    }

    .typeIcon {
        bottom: 0;
        left: 0;
        color: var(--color-urbania-black);
    }

    .brandIcon {
        top: 0;
        right: 0;
    }
}
