/* stylelint-disable selector-class-pattern */
:global {
    :local(.container) {
        background: var(--body-background-color, transparent);
        color: var(--body-text-color, var(--body-primary-color, #222));
        font-family: var(--body-font-family);
        font-size: var(--body-font-size);
        font-weight: var(--body-font-weight);
        line-height: var(--body-line-height);

        p {
            padding: 0;
            margin: 0;
            margin: var(--body-text-margin, 0 0 1em 0);
            font-family: inherit;
            font-size: inherit;
            font-style: normal;
            font-weight: inherit;
            letter-spacing: inherit;
            line-height: inherit;

            &:last-child {
                margin: var(--body-last-text-margin, 0);
            }
        }

        >,
        .block-text,
        .block-heading {
            h1,
            h2,
            h3,
            h4,
            h5 {
                padding: 0;
                margin: var(--body-heading-margin, 1.5em 0 0.4em 0);
                color: var(--body-heading-color, var(--body-primary-color, #222));
                font-family: var(--body-heading-font-family);
                font-size: var(--body-heading-font-size);
                font-weight: var(--body-heading-font-weight);
                letter-spacing: var(--body-heading-letter-spacing);
                line-height: var(--body-heading-line-weight);
                text-transform: var(--body-heading-text-transform);

                b,
                strong {
                    font-weight: inherit;
                }
            }
        }

        .block-heading {
            &:first-child {
                h1,
                h2,
                h3,
                h4,
                h5 {
                    margin-top: 0;
                }
            }
        }

        > h1,
        .block-text h1,
        .block-heading h1 {
            font-family: var(--font-garage);
            font-size: 3em;
        }

        .block-text,
        .block-placeholder,
        .block-heading,
        .block-quote,
        .block-subscription,
        .block-table {
            max-width: var(--block-max-width-text);
            padding: 0 var(--spacing);
            margin: 0 auto;
        }

        .block-items,
        .block-contest {
            max-width: var(--block-max-width-text);
            margin: 0 auto;
        }

        .wp-video,
        video {
            max-width: 100%;
            height: auto;
            max-height: 90vh;
            background: #000;
        }

        .block-text {
            li {
                padding: 0;
                margin: 0;
                margin-bottom: 1em;
                font-family: inherit;
                font-size: inherit;
                font-style: normal;
                font-weight: inherit;
                letter-spacing: inherit;
                line-height: inherit;
            }
        }

        .block {
            margin-top: var(--body-block-margin-top, 0);
            margin-bottom: var(--body-block-margin-bottom, 1em);

            &:last-child {
                margin-bottom: var(--body-last-block-margin-bottom, 0);
            }
        }

        .block-quote {
            /* seulement quand c'est une citation */
            margin-top: var(--body-quote-margin-top, 1.5em);
            margin-bottom: var(--body-quote-margin-bottom, 1.5em);
            color: var(--body-quote-color, var(--body-primary-color, #222));

            figure {
                position: relative;
                margin: 0;
                color: inherit;

                &.withCaption {
                    padding-left: var(--spacing);

                    &::after {
                        position: absolute;
                        top: 0.3em;
                        left: 0;
                        display: block;
                        height: calc(100% - 0.6em);
                        border-left: 2px solid var(--body-quote-border-color);
                        content: '';
                    }
                }
            }

            blockquote {
                margin: 0;
                color: inherit;
                font-family: var(--body-quote-font-family);
                font-size: var(--body-quote-font-size);
                font-style: var(--body-quote-font-style);
                font-weight: var(--body-quote-font-weight);
                letter-spacing: var(--body-quote-letter-spacing);
                line-height: var(--body-quote-line-height);
                text-transform: var(--body-quote-text-transform);

                b,
                strong {
                    font-weight: inherit;
                }

                p {
                    margin: 0;
                    font-family: inherit;
                    font-size: inherit;
                    font-style: inherit;
                    font-weight: inherit;
                    letter-spacing: inherit;
                    line-height: inherit;
                }
            }

            figcaption {
                padding-top: var(--spacing-medium);
                color: inherit;
                font-family: var(--body-quote-caption-font-family);
                font-size: var(--body-quote-caption-font-size);
                font-weight: var(--body-quote-caption-font-weight, var(--body-quote-font-weight));
                letter-spacing: var(
                    --body-quote-caption-letter-spacing,
                    var(--body-quote-letter-spacing)
                );
                text-transform: var(
                    --body-quote-caption-text-transform,
                    var(--body-quote-text-transform)
                );

                strong {
                    font-size: var(--body-quote-caption-name-font-size);
                    font-weight: var(--body-quote-caption-name-font-weight, inherit);
                }
            }
        }

        &:local(.withoutMaxWidth) {
            .block-text,
            .block-heading,
            .block-quote,
            .block-image,
            .block-video,
            .block-ad,
            .block-embed,
            .block-items,
            .block-animation {
                max-width: none;
                padding-right: 0;
                padding-left: 0;
                margin-right: 0;
                margin-left: 0;
            }
        }

        &:local(.inherit) {
            background: transparent;
            color: inherit;
        }

        [data-brand='quatre95'] & {
            .block-quote {
                figure {
                    &::after {
                        top: 0;
                        height: calc(100% - 0.3em);
                    }
                }
            }
        }

        [data-brand='dehors'] & {
            .block-quote {
                figure {
                    &::after {
                        top: 0.2em;
                        height: calc(100% - 0.4em);
                    }
                }
            }
        }

        [data-brand='mollo'] & {
            .block-quote {
                figure {
                    &::after {
                        top: 0;
                        height: calc(100% - 0.3em);
                    }
                }
            }
        }

        [data-brand='periodelibre'] & {
            .block-quote {
                figure {
                    &::after {
                        top: 0;
                        height: calc(100% - 0.3em);
                    }
                }
            }
        }

        @media (--medium-viewport) {
            --body-heading-font-size: var(--body-heading-font-size-medium);
            --body-quote-font-size: var(--body-quote-font-size-medium);
        }
    }
}
