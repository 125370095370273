.container {
    --list-spacing: 20px;

    position: relative;

    .featuredItem {
        width: 100%;
        height: 85vh;
        height: 85lvh;
        min-height: 300px;
        padding-bottom: 60px;
    }

    .list {
        width: 100%;
        margin-top: -50px;
    }

    .items {
        padding: 0 var(--spacing) var(--spacing);
    }

    .item {
        margin-right: var(--spacing-small);

        &:last-child {
            margin-right: 0;
        }
    }

    .grid {
        .item {
            margin-right: 0;
        }
    }

    .card {
        border-bottom: 0;
    }

    &.withShadow {
        .card {
            box-shadow: var(--card-box-shadow);
            transition:
                box-shadow 0.2s ease-out,
                transform 0.2s ease-out;

            &:active {
                box-shadow: var(--card-box-shadow-down);
                transform: translate(1px, 1px);
            }
        }
    }

    @media (--medium-viewport) {
        .featuredItem {
            min-height: 700px;
        }
    }

    @media (--large-viewport) {
        .featuredItem {
            min-height: 800px;
        }
    }
}
