.container {
    padding: var(--spacing);
    border-radius: var(--card-border-radius);

    .form {
        color: inherit;
    }

    .spacer {
        display: none;
        margin: var(--spacing-medium) 0 10px;
        font-size: 1rem;
    }

    .or {
        padding: 0 10px 0 5px;
        font-size: 1rem;
        font-weight: bold;
    }

    .sso {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 10px;
        margin: -2.5px;
        font-size: 0.9rem;

        .button {
            width: auto;
            margin: 2.5px;

            /* &:last-child {
                margin-right: 0;
            } */
        }
    }

    .login {
        display: none;
        margin-top: 20px;
        font-size: 0.8em;
        text-align: center;

        .link {
            text-decoration: underline;
        }
    }

    @media (--small-viewport) {
        padding: var(--spacing-medium);

        .sso {
            justify-content: flex-end;
        }

    }

    &[data-theme='micromag'] {
        width: 100%;
        min-width: 330px;
        padding: var(--spacing);
        background: var(--color-yellow);

        .callToAction {
            z-index: 2;
            margin-top: 0;
        }

        .image {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            transform: translate(10%, -40%);
        }
    }

    &[data-theme='mollo'] {
        background: var(--color-mollo);
        color: var(--color-white);
    }

    &[data-theme='full'] {
        .inner {
            grid-template-areas:
                'content'
                'cta';
        }

        .title {
            margin-right: 25%;
            font-family: var(--font-garage) !important;
            font-size: 2.5em;
            line-height: 0.9 !important;
            text-transform: uppercase !important;
        }

        .description {
            font-size: 1.25em;
            font-weight: bold !important;
        }

        .callToAction {
            margin-top: var(--spacing-medium);
            font-size: 1em;
        }

        .image {
            position: absolute;
            top: 0;
            right: 5%;
            overflow: hidden;
            width: 30%;
            height: 30%;
            margin: 0;
            box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.25);
            transform: rotate(-4deg) translate(-10%, -30%);
        }

        .typeIcon {
            position: absolute;
            top: 0;
            right: 2%;
            width: 40px;
            height: 40px;
        }

        .spacer {
            display: flex;
        }

        .sso {
            justify-content: center;
        }

        .login {
            display: block;
        }

        &.isMicromag {
            .image {
                position: absolute;
                top: 0;
                right: 2%;
                overflow: hidden;
                width: 22%;
                height: auto;
                border-radius: 10px;
                margin: 0;
                box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.25);
                transform: rotate(-4deg) translate(-10%, -40%);

                &::after {
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 2px solid #000;
                    background: linear-gradient(
                            180deg,
                            #000 2%,
                            rgba(0, 0, 0, 0) 10%,
                            rgba(0, 0, 0, 0) 100%
                        ),
                        linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 90%, #000 97%);
                    content: '';
                }
            }

            .picture {
                position: relative;
                height: auto;
            }

            .inner {
                grid-template-columns: auto;
            }
        }

        @media (--small-viewport) {
            .title {
                font-size: 3em;
            }

            .description {
                font-size: 1.5em;
            }

            .callToAction {
                font-size: 1.25em;
            }
        }
    }
}
