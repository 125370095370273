:root {
    /* Headers */
    --header-title-text-shadow: 0px 2px 0px #222;

    /* Featured */
    --header-featured-border-radius: 20px;

    /* Category */
    --header-category-border-radius: 1.5em;
    --header-category-padding: 0.375em 0.75em 0.1em;

    /* Collection */
    --header-collection-border-radius: 10px;

    /* Index */
    --header-index-font-size: 1.5rem;
    --header-index-font-size-medium: 2rem;
    --header-index-font-size-large: 2.5rem;

    /* Element margin */
    --header-margin: 1rem;
    --header-margin-medium: 1.25rem;
    --header-margin-large: 1.5rem;

    /* Element font size */
    --header-font-size-category: 0.75rem;
    --header-font-size-surtitle: 1.25rem;
    --header-font-size-title: 2.5rem;
    --header-font-size-author-name: 3rem;
    --header-font-size-subtitle: 1.3rem;
    --header-font-size-author: 1rem;
    --header-font-size-description: 1rem;
    --header-font-size-initials: 0.75rem;
    --header-font-size-date: 0.85rem;
    --header-font-size-button: 0.85rem;
    --header-font-size-sponsor: 1rem;
    --header-font-size-sponsor-small: 0.85rem;
    --header-font-size-author-initials: 4rem;

    /* Medium font size */
    --header-font-size-category-medium: 1rem;
    --header-font-size-surtitle-medium: 1.5rem;
    --header-font-size-title-medium: 3.5rem;
    --header-font-size-author-name-medium: 4rem;
    --header-font-size-subtitle-medium: 1.25rem;
    --header-font-size-author-medium: 1rem;
    --header-font-size-date-medium: 0.85rem;
    --header-font-size-button-medium: 1rem;
    --header-font-size-sponsor-medium: 1rem;

    /* Large font size */
    --header-font-size-category-large: 1rem;
    --header-font-size-surtitle-large: 1.75rem;

    /* quatre95 */
    --header-font-size-title-large: 4rem;
    --header-font-size-author-name-large: 4rem;
    --header-font-size-subtitle-large: 1.5rem;
    --header-font-size-author-large: 1.15rem;
    --header-font-size-date-large: 0.85rem;
    --header-font-size-button-large: 1.125rem;
    --header-font-size-sponsor-large: 1rem;

    /* Collection */
    --header-font-size-collection-title: 1.5rem;
    --header-font-size-collection-title-medium: 2.5rem;
    --header-font-size-collection-title-large: 2.5rem;

    /* Author */
    --header-author-title-font-size-large: 5rem;

    /* Video */
    --header-video-title-font-size-large: 3rem;
}
