.container {
    position: relative;
    /* width: 100%; */
    /* border: var(--control-border);
    border-radius: var(--control-border-radius);
    appearance: none;
    background: var(--control-background); */

    .input {
        width: 100%;
        /* width: 100%;
        border: 0;
        background: transparent; */
    }

    .phoneSelector {
        --react-international-phone-height: 100%;
        --react-international-phone-font-size: 1em;
        --react-international-phone-country-selector-background-color: transparent;
        --react-international-phone-country-selector-border-color: transparent;
        --react-international-phone-country-selector-background-color-hover: transparent;
        --react-international-phone-dial-code-preview-background-color: transparent;
        --react-international-phone-dial-code-preview-border-color: transparent;
        --react-international-phone-dial-code-preview-background-color-hover: transparent;

        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        height: 100%;
        flex-direction: row;
        align-items: center;
        border: 0;
        appearance: none;
        background: transparent;
        color: var(--control-color);

        .dialCode {
            padding: 0;
        }
    }
}

.tel {
    .input {
        padding-left: calc(0.75em + 70px);
    }
}
