.container {
    --ad-bumper-button-background: rgba(0, 0, 0, 0.8);
    --ad-bumper-button-border: solid 1px rgba(255, 255, 255, 0.6);
    --ad-bumper-ui-background: rgba(0, 0, 0, 0.8);
    --ad-bumper-ui-color: #fff;
    --ad-bumper-ui-border: solid 1px rgba(255, 255, 255, 0.2);

    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    content-visibility: hidden;
    pointer-events: none;
    transition: background 0.5s ease-out;

    .player {
        position: absolute;
        top: 10%;
        left: 5%;
        overflow: hidden;
        width: 90%;
        height: 80%;
        border-radius: 10px;
        background: #000;
        box-shadow: 0 0 40px 20px rgba(0, 0, 0, 0.6);
        clip-path: none;
        opacity: 0;
    }

    .video {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .muteButton {
        position: absolute;
        z-index: 2;
        bottom: 20px;
        left: 0;
        display: block;
        padding: 10px;
        color: #fff;

        .icon {
            width: 30px;
            height: 30px;
        }
    }

    .adContainer {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .remainingTime {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 50%;
        padding: 10px 20px;
        border: var(--ad-bumper-ui-border);
        border-bottom: 0;
        background: var(--ad-bumper-ui-background);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: var(--ad-bumper-ui-color);
        text-align: center;
        transform: translate(-50%, 100%);
        transition: transform 0.5s ease-out;
        white-space: nowrap;

        span {
            display: inline-block;
            width: 1.5em;
        }
    }

    .bannerContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .closeButton {
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
        width: 40px;
        height: 40px;
        padding: 0;
        border: var(--ad-bumper-button-border);
        border-radius: 50%;
        background: var(--ad-bumper-button-background);
        color: var(--ad-bumper-ui-color);
        opacity: 0;
        pointer-events: none;
        transform: translateX(100%);
        transition:
            transform 0.5s ease-out,
            opacity 0.5s ease-out;

        circle {
            fill: var(--ad-bumper-button-background);
        }
    }

    .skipButton {
        position: absolute;
        right: 0;
        bottom: 45px;
        padding: 0.5em 1em;
        border: var(--ad-bumper-button-border);
        border-right: 0;
        background: var(--ad-bumper-button-background);
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        color: var(--ad-bumper-ui-color);
        opacity: 0;
        pointer-events: none;
        transform: translateX(100%);
        transition:
            transform 0.5s ease-out,
            opacity 0.5s ease-out;
        font-size: 0.75rem;
    }

    &.noVideo {
        .adContainer {
            pointer-events: none;
        }

        .player {
            background: transparent;
            box-shadow: none;
        }

        .banner {
            box-shadow: 0 0 40px 20px rgba(0, 0, 0, 0.6);
        }

        .muteButton {
            display: none;
        }
    }

    &.visible {
        content-visibility: visible;

        .player {
            clip-path: circle(0%);
            opacity: 1;
            transition: clip-path 0.5s ease-out;
        }
    }

    &.playing {
        background: rgba(0, 0, 0, 0.6);
        pointer-events: all;

        .player {
            clip-path: circle(100%);
            transition: clip-path 0.5s ease-out;
        }

        &.visible {
            .player {
                clip-path: circle(100%);
                transition: clip-path 0.5s ease-out;
            }
        }

        .remainingTime {
            transform: translate(-50%, 0);
        }

        &.noVideo {
            background: rgba(0, 0, 0, 0.8);
        }

        &.canSkip {
            .skipButton,
            .closeButton {
                opacity: 1;
                pointer-events: all;
                transform: translateX(0);
            }
        }
    }
}
