.container {
    display: block;
    width: 100%;
    font-size: var(--card-font-size);
    text-decoration: none;

    .inner {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }

    .thumbnail {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .title {
        display: block;
        width: 100%;
        text-align: left;
    }

    .label {
        position: absolute;
        z-index: 2;
        bottom: var(--card-spacing);
        left: var(--card-spacing);
        width: calc(100% - var(--card-spacing) * 2);
        padding: var(--card-spacing);
        border-radius: var(--card-border-radius);
        background: var(--color-yellow);
    }

    &.big {
        font-size: var(--card-font-size-big);
    }

    &.small {
        font-size: var(--card-font-size-small);
    }

    &.square {
        .inner {
            padding-bottom: 100%;
        }
    }

    &.squareDouble {
        .inner {
            padding-bottom: 200%;
        }
    }

    &.horizontal {
        .inner {
            padding-bottom: 80%;
        }
    }

    &.vertical {
        .inner {
            padding-bottom: 125%;
        }
    }

    &.micromag {
        .inner {
            padding-bottom: 150%;
        }
    }

    &.withoutText {
        .label {
            display: none;
        }
    }

    &.outline {
        .label {
            background: transparent;
        }

        .title {
            color: var(--color-beige);
            text-align: center;
        }
    }

    &.withoutText,
    &.outline {
        overflow: hidden;
        border-radius: var(--card-border-radius);
        background: var(--card-author-gradient);
    }
}
