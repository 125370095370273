.container {
    display: inline-block;
    width: 1em;
    height: 1em;
    background-image: url('../../assets/img/icons/brands_icons.svg');
    background-repeat: no-repeat;
    background-size: 900% 100%;

    &.quatre95 {
        background-position: 0 0;
    }

    &.dehors {
        background-position: 12.5% 0;
    }

    &.periodelibre {
        background-position: 25% 0;
    }

    &.urbania {
        background-position: 37.5% 0;
    }

    &.mollo {
        background-position: 50% 0;
    }

    &.france {
        background-position: 100% 0;
    }
}
