.container {
    overflow: hidden;
    border-radius: 50%;
    background: linear-gradient(180deg, #d9d9d9 0%, #fff 100%);
    font-size: 2rem;

    .circle {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }

    .initials {
        position: absolute;
        top: 50%;
        left: 50%;
        padding-top: 0.2em;
        background: linear-gradient(
            160deg,
            rgba(126 126 126 / 0.22) 9.35%,
            rgba(34 34 34 / 0.5) 86.57%
        );
        background-clip: text;
        color: transparent;
        transform: translate(-50%, -50%);
        white-space: nowrap;
    }

    &.withoutCircle {
        overflow: visible;
        border-radius: 0;
        background: transparent;

        .picture {
            border-radius: 0;
        }
    }
}
