.container {
    .input {
        display: flex;
        overflow: hidden;
        width: 100%;
        align-items: center;
        padding: var(--control-padding, 0.5em);
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        border: var(--control-disabled-border);
        border-radius: var(--control-border-radius);
        appearance: none;
        background: var(--control-translucent-background-color);
        color: var(--control-disabled-color);
        font-family: var(--control-font-family);
        font-size: inherit;
        font-weight: var(--control-font-weight);
        line-height: 1;
    }

    .icon {
        min-width: 1em;
        min-height: 1em;
        margin: 0 0.2em;
        color: var(--color-black);
    }

    .label {
        overflow: hidden;
        height: calc(var(--control-padding, 0.5em) * 2 + 1em);
        padding: var(--control-padding, 0.5em);
        padding-right: 0;
        padding-left: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .logout {
        margin-top: var(--spacing-small);
        font-size: 0.8em;
    }

    .link {
        font-weight: normal;
        text-decoration: underline;
    }
}
