.container {
    --card-color: var(--brand-text-color);

    min-height: 100vh;
    background: var(--page-background);
}

.filters {
    padding: 0 var(--side-spacing);
    margin-bottom: var(--spacing);
}
