.container {
    --login-inner-max-width: 400px;

    position: relative;
    min-height: 100svh;
    background: var(--page-background);
    transition: background-color 0.5s ease-out;

    .button {
        position: absolute;
        top: var(--spacing);
        left: var(--spacing);
        z-index: 2;
        height: var(--menu-urbania-size);
        opacity: 0;
        transition: opacity 0.5s ease-out;
    }

    .logo {
        display: block;
        width: auto;
        height: 100%;
    }

    .wrapper {
        display: flex;
        width: 100%;
        min-height: 100svh;
        flex-direction: column;

        &.mount,
        &.leave {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .inner {
        position: relative;
        width: 100%;
        max-width: var(--login-inner-max-width);
        padding: 60px var(--side-spacing);
        margin: auto;
    }

    .title {
        margin-bottom: var(--spacing);
        font-size: var(--header-font-size-title);
    }

    .description {
        margin-bottom: var(--spacing-medium);
        font-size: var(--header-font-size-description);
        line-height: 1.25;
    }

    .form {
        margin-bottom: var(--spacing);
    }

    .field {
        width: 100%;
    }

    .ssoContainer {
        transition: opacity 0.2s ease-out;
    }

    .continue {
        display: flex;
        margin-bottom: var(--spacing);

        .line {
            width: 100%;
            height: 0.5em;
            border-bottom: 1px solid var(--color-black);
        }

        .label {
            flex-grow: 1;
            flex-shrink: 0;
            padding: 0 1em;
            text-align: center;
        }
    }

    .ssoButtons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: var(--spacing-medium);
        margin: -5px;
    }

    .ssoButton {
        margin: 5px;
    }

    .toggleContainer {
        font-size: var(--header-font-size-description);
        text-align: center;
        transition: opacity 0.2s ease-out;
    }

    .toggle {
        padding-left: 0.2em;
        font-size: var(--header-font-size-description);
        font-weight: 700;
        text-decoration: none;
    }

    &.isRegister {
        /* background-color: var(--color-urbania-yellow); */
    }

    &.isVerifying {
        .ssoContainer,
        .toggleContainer {
            opacity: 0;
            pointer-events: none;
        }
    }

    &.entered,
    &.entering {
        .button {
            opacity: 1;
        }
    }

    &.leaving {
        .button {
            opacity: 0;
        }
    }

    @media (--medium-viewport) {
        .title {
            margin-bottom: var(--spacing);
            font-size: var(--header-font-size-title-medium);
        }

        .form {
            margin-bottom: var(--spacing-medium);
        }

        .continue {
            margin-bottom: var(--spacing-medium);
        }

        .ssoButtons {
            padding-bottom: var(--spacing-medium);
        }
    }

    @media (--large-viewport) {
        .title {
            font-size: var(--header-font-size-title-large);
        }
    }
}
