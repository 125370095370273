.container {
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
}

.link {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.frame {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    border: 0 !important;
    margin: 0 !important;
    clip: rect(0, auto, auto, 0) !important;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%) !important;
}

.imageContainer {
    position: fixed;
    top: 0;
    /* left: 0; */
    width: 100vw;
    height: 100vh;
    transform: translateZ(0);
    transform-origin: 0 0;
}

.image {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    transform: translateZ(0);
    transform-origin: 0 0;
}

.background {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    filter: blur(200px);
}
