.container {
    --control-background: var(--control-translucent-background-color);

    .fieldGroup {
        position: relative;
    }

    .button {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 1em var(--spacing);
        border-bottom-right-radius: var(--control-border-radius);
        border-top-right-radius: var(--control-border-radius);
    }

    .icon {
        display: block;
        width: 1em;
        height: 1em;
    }
}
