.container {
}

.items {
    display: flex;
    overflow: hidden;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow-x: auto;
    scrollbar-width: none;
}

.items::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
}

.item {
    display: flex;
    flex-direction: column;
    margin-right: var(--row-spacing);

    &:last-child {
        margin-right: 0;
    }
}

.card {
    flex-grow: 1;
}

.scrollSnap {
    .items {
        scroll-snap-type: x mandatory;
    }

    .item {
        scroll-margin-left: var(--row-spacing);
        scroll-snap-align: start;
    }
}
