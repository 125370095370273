.container {
    font-size: 1rem;
}

.inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: calc(var(--spacing-small) * -1);
}

.button {
    /* margin-right: var(--spacing); */
    margin: var(--spacing-small);
    background: rgba(255 255 255 / 0.3);
    color: var(--color-black);
    transition: opacity 0.2s ease-out;

    &:last-child {
        margin-right: 0;
    }

    &.active {
        background: var(--color-white);
    }

    &.link {
        background: transparent;
        text-decoration: underline;
    }
}
