.container {
    position: relative;
    width: 100%;
    height: 90vh;

    .background {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .inner {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: var(--spacing-large);
    }

    .button {
        position: relative;
        width: 2.75rem;
        height: 2.75rem;
        border-radius: 50%;
        box-shadow: 0 4px 4px rgb(0 0 0 / 0.25);
    }

    .arrow{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
    }

    .coverImage {
        position: absolute;
        z-index: 0;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
    }

    .category {
        /* padding: calc(var(--spacing-small) + 0.1875rem) calc(var(--spacing) + 0.1875rem) var(--spacing-small); */

        padding: 0.375rem 0.75rem 0.1875rem;
        border: 1px solid;
        border-radius: var(--header-category-border-radius);
        font-size: var(--header-font-size-category);
        text-decoration: none
    }

    @media (--medium-viewport) {
        padding-bottom: 56.25%;

        .inner{
            padding: var(--spacing-medium);
        }

        .coverImage {
            position: absolute;
            z-index: 0;
            top: 0;
            left: 50%;
            width: auto;
            height: 100%;
            padding: var(--spacing-large) 0;
            transform: translateX(-50%);
        }

    }

}
