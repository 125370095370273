.container {
    position: fixed;
    z-index: var(--notifications-z-index);
    top: 0;
    left: 0;
    padding: var(--spacing);
    contain: layout style;
    pointer-events: all;

    .closeButton {
        position: absolute;
        z-index: 2;
        top: -5px;
        right: -5px;
        display: block;
        width: 40px;
        height: 40px;
        padding: 5px;
    }

    &.isTransitioning {
        overflow: hidden;
    }

    &.topLeft {
        top: 0;
        left: 0;
    }

    &.topRight {
        top: 0;
        right: 0;
        left: auto;
    }

    &.bottomRight {
        top: auto;
        right: 0;
        bottom: 0;
        left: auto;
    }

    &.bottomLeft {
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
    }
}
