.container {
    &.grid {
        .items {
            display: grid;
            align-items: center;
            gap: var(--grid-spacing);
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        }

        .block {
            width: 100%;
        }
    }
}
