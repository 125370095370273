.container {
    display: block;
    text-align: inherit;
    text-decoration: none;
}

.inner {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
}

.thumbnail {
    position: relative;

    .responsive & {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.label {
    flex-grow: 1;
}
