:root {
    --paper1: url('../assets/img/paper1.png');
    --paper2: url('../assets/img/paper2.png');
    --paper3: url('../assets/img/paper3.png');

    /* Page background */
    --page-background-color: #fff4e9;
    --page-background: var(--page-background-color) var(--paper1) top left / 200px 200px repeat;
    --page-max-width: 800px;
}
