.container {
    color: var(--color-white);

    .inner {
        transition: padding 0.2s ease-out;
    }

    .timeline {
        padding: var(--spacing);
        transition: padding 0.2s ease-out;
    }

    .progress {
        position: relative;
        display: block;
        width: 100%;
        height: 0.2em;
        background: rgba(255 255 255 / 0.35);
        transition: margin-bottom 0.2s ease-out, height 0.2s ease-out;
    }

    .bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: #fff;
    }

    .control {
        width: 3em;
        height: 3em;
        padding: 0.5em 0;
        margin-right: 0.5em;

        &:last-child {
            margin-right: 0;
        }
    }

    .time {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        margin-right: auto;

        .part {
            display: block;
            min-width: 2.3em;
            text-align: right;

            &:last-child {
                text-align: left;
            }
        }

        .slash {
            display: block;
            min-width: 0.8em;
            text-align: center;
        }
    }

    .controls,
    .compactControls {
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 3em;
        flex-direction: row;
        align-items: center;
    }

    .controls {
        padding: 0 var(--spacing);
        padding-bottom: var(--spacing);
        transition: height 0.2s ease-out, padding 0.2s ease-out;
    }

    .compactControls {
        position: absolute;
        z-index: 2;
        bottom: 0.5em;
        left: 0;
        width: auto;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease-out;

        .control {
            width: 4em;
            height: 4em;
            padding: 1em 0;
        }
    }

    &.compact {
        .inner {
            padding: 0;
        }

        .timeline {
            padding: 0;
        }

        .progress {
            height: 0.5em;
            margin-bottom: 0;
        }

        .controls {
            height: 0;
            padding-bottom: 0;
        }

        .compactControls {
            opacity: 1;
            pointer-events: all;
        }
    }

    &.urbania {
        .bar {
            background: var(--color-urbania);
        }
    }

    &.mollo {
        .bar {
            background: var(--color-mollo);
        }
    }

    &.quatre95 {
        .bar {
            background: var(--color-quatre95);
        }
    }

    &.dehors {
        .bar {
            background: var(--color-dehors);
        }
    }

    &.periodelibre {
        .bar {
            background: var(--color-periodelibre);
        }
    }
}
