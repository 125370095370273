.container {
    position: relative;
    width: auto;
    height: 1em;
    padding: 0;
    margin: 0;

    .logo {
        display: block;
        width: auto;
        height: 100%;
    }

    .logoContainer {
        display: block;
        width: auto;
        height: 100%;
    }

    .leave {
        position: absolute;
        top: 0;
        left: 50%;

        .logo {
            transform: translate(-50%, 0);
        }
    }

    .urbania {
        path {
            fill: inherit;
        }
    }

    .periodelibre {
        .logo {
            height: 90%;
            transform: translateY(5%);
        }
    }

    .dehors {
        .logo {
            transform: translateY(5%);
        }
    }
}
