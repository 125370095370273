.container {
    position: relative;
    min-height: 100svh;
    background: var(--page-background);
    transition: background-color 0.5s ease-out;

    .urbania {
        position: absolute;
        top: var(--spacing);
        left: var(--spacing);
        opacity: 0;
    }

    .inner {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 0 var(--side-spacing);
        padding-top: 80px;
        margin: 0 auto;
    }

    .title {
        margin-bottom: var(--spacing);
        font-size: 1.25rem;
        text-align: left;
        text-transform: none;
    }

    .sectionTitle {
        padding-top: 0;
        margin-bottom: 10px;
        text-transform: none;
    }

    .form {
        max-width: 800px;
        margin-bottom: var(--spacing-large);
    }

    .field {
        --control-background: rgba(255 255 255 / 0.5);

        width: 100%;
        font-size: 1rem;
    }

    .list {
        .items {
            padding: 0;
        }
    }

    .results {
        margin-bottom: var(--block-spacing);
    }

    &.entered,
    &.entering {
        .urbania {
            opacity: 1;
        }
    }

    &.leaving {
        .urbania {
            opacity: 0;
        }
    }

    @media (--medium-viewport) {
        /* .title {
            font-size: 2rem;
        } */

        .field {
            font-size: 1.5rem;
        }
    }
}
