.container {
    .inner {
        display: flex;
        flex-direction: row;
    }

    .logoContainer {
        position: relative;
        display: block;
    }

    .logo {
        position: relative;
        z-index: 2;
        display: block;
        width: 100%;
        height: auto;

        &.shadow {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
        }
    }
}
