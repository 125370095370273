.container {
    font-size: var(--card-font-size);

    .inner {
        align-items: flex-start;
    }

    .thumbnail {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .label {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
    }

    .title {
        color: var(--color-white);
        text-align: center;
        text-shadow: var(--card-text-shadow);
    }

    &.big {
        font-size: var(--card-font-size-big);
    }

    &.small {
        font-size: var(--card-font-size-small);
    }

    &.square {
        .inner {
            padding-bottom: 100%;
        }
    }

    &.squareDouble {
        .inner {
            padding-bottom: 200%;
        }
    }

    &.horizontal {
        .inner {
            padding-bottom: 80%;
        }
    }

    &.vertical {
        .inner {
            padding-bottom: 125%;
        }
    }

    &.micromag {
        .inner {
            padding-bottom: 150%;
        }
    }

    &.menu {
        padding-bottom: 0;
        border-bottom: none;

        .thumbnail {
            overflow: hidden;
            border-radius: var(--card-border-radius);

            &::after {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                box-shadow: var(--card-emboss-box-shadow);
                content: '';
            }
        }
    }
}
