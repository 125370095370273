.container {
    position: relative;

    .items {
        margin: -2.5px;
    }

    .item {
        margin: 2.5px;
    }
}
