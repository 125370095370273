:root {
    /* Body */
    --body-font-size: 1.2rem;
    --body-font-family: var(--typo-body-font-family);
    --body-font-weight: var(--typo-body-font-weight);
    --body-line-height: 1.3;
    --body-letter-spacing: var(--typo-body-font-letter-spacing);
    --block-max-width-text: 600px;
    --block-max-width-visual: 1000px;
    --block-max-width-small: 300px;

    /* Heading */
    --body-heading-font-family: var(--typo-body-heading-font-family);
    --body-heading-font-size: 0.9em;
    --body-heading-font-size-medium: 1em;
    --body-heading-font-weight: 700;
    --body-heading-text-transform: uppercase;
    --body-heading-line-weight: 1.1;

    /* Font size */
    --body-quote-font-family: var(--typo-header-title-font-family);
    --body-quote-font-size: 1.8em;
    --body-quote-font-size-medium: 2.5em;
    --body-quote-font-style: normal;
    --body-quote-font-weight: 700;
    --body-quote-letter-spacing: 0.005em;
    --body-quote-line-height: 0.95;
    --body-quote-text-transform: uppercase;
    --body-quote-caption-font-family: var(--typo-body-heading-font-family);
    --body-quote-caption-font-size: 1em;
    --body-quote-caption-name-font-size: 1.25em;
}
