.container {
    .image {
        width: auto;
        max-width: 200px;
        height: auto;
        max-height: 4em;
        margin-left: 0.5em;
    }

    .label {
        white-space: nowrap;
    }

    &.withImage {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &.link {
        text-decoration: none;
    }

    strong {
        /* white-space: nowrap; */
    }
}
