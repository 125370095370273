.container {
    overflow: hidden;
    border-radius: var(--card-border-radius);
    background: rgba(255, 255, 255, 0.5);
    font-size: var(--card-font-size-medium);

    .inner {
        align-items: flex-start;
    }

    .thumbnail {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .label {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        color: var(--color-white);
        text-align: center;
    }

    .title {
        padding: var(--spacing);
        padding-top: var(--spacing-large);
        padding-bottom: var(--spacing-medium);
        margin-top: auto;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    }

    &.big {
        font-size: var(--card-font-size-big);
    }

    &.small {
        font-size: var(--card-font-size-small);
    }

    &.square {
        .inner {
            padding-bottom: 100%;
        }
    }

    &.squareDouble {
        .inner {
            padding-bottom: 200%;
        }
    }

    &.horizontal {
        .inner {
            padding-bottom: 75%;
        }
    }

    &.vertical {
        .inner {
            padding-bottom: 125%;
        }
    }

    &.micromag {
        .inner {
            padding-bottom: 150%;
        }
    }

    &.withoutText,
    &.outline {
        overflow: hidden;
        border-radius: var(--card-border-radius);
    }

    &.menu {
        padding-bottom: 0;
        border-bottom: none;

        .thumbnail {
            overflow: hidden;
            border-radius: var(--card-border-radius);

            &::after {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                box-shadow: var(--card-emboss-box-shadow);
                content: '';
                transition: box-shadow 0.2s ease-in-out;
            }
        }

        &.withoutText {
            .thumbnail {
                &::after {
                    display: none;
                }
            }
        }

        &:active,
        &:hover {
            .thumbnail {
                &::after {
                    box-shadow: var(--card-emboss-down-box-shadow);
                }
            }
        }
    }
}
