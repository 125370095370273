.container {
    /* margin: 0 var(--side-spacing); */

    .inner {
        /* overflow: hidden; */
        padding: var(--spacing-medium) 0 var(--spacing);
        padding-top: 0;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 0 var(--side-spacing);
        margin-bottom: var(--spacing);
    }

    .summary {
        position: relative;
        z-index: 1;
        max-width: 30em;
        margin-right: auto;
        margin-bottom: 10px;

        .summaryImage {
            width: 4em;
            height: 4em;
        }

        .summaryDescription {
            width: 100%;
            margin-top: 0;

            p {
                margin-bottom: 0;
            }
        }

        &.withImage {
            .summaryInner {
                grid-template-columns: auto 100%;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .button {
        font-size: 1rem;
        white-space: nowrap;
    }

    .items {
        overflow: inherit;
        overflow-x: visible;
    }

    &.darkBackground {
        color: var(--color-white);
    }

    /* &.withBackground {
        .inner {
            background: linear-gradient(
                to bottom,
                rgba(0 0 0 / 0.6) 0%,
                rgba(0 0 0 / 0.4) 60%,
                rgba(0 0 0 / 0) 100%
            );
        }

        .background {
            &::before {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background: rgba(0 0 0 / 0.2);
                content: '';
            }
        }
    } */

    &.bottom {
        .inner {
            /* overflow: hidden; */
            padding: var(--spacing-medium) 0;
            padding-bottom: 0;
        }
    }

    &.center {
        .summaryInner {
            align-items: center;
        }

        .summaryDescription {
            text-align: center;
        }
    }

    &.rowList {
        position: relative;
        display: flex;
        overflow: hidden;
        width: 100%;
        padding: 0 var(--side-spacing);
        border-radius: 0;
        margin: 0;

        .inner {
            min-width: auto;

            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none;
            overflow-x: auto;
            scrollbar-width: none;

            ::-webkit-scrollbar {
                display: none;
                width: 0;
                height: 0;
            }
        }

        .summary {
            position: sticky;
            top: 0;
            left: 0;
        }

        .list {
            display: flex;
            width: auto;
            flex-direction: row;
        }

        .items {
            overflow: inherit;
            overflow-x: visible;
        }
    }

    &.inline {
        .inner {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding-top: var(--spacing-medium);
            padding-bottom: var(--spacing-medium);
        }

        .summary {
            position: static;
            width: 100%;
            min-width: 180px;
            max-width: 200px;
            padding: 0;
            padding-left: var(--side-spacing);
            /* padding: 0 var(--side-spacing); */
        }

        /* .summaryInner {
            justify-content: center;
        } */

        /* .summaryDescription {
            display: -webkit-box;
            overflow: hidden;
            height: 8em;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 8;
            text-overflow: ellipsis;
        } */

        .list {
            width: 100%;
        }
    }

    @media (--small-viewport) {
        .header {
            flex-direction: row;
        }

        .summary {
            margin-bottom: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .button {
            margin-left: 10px;
        }
    }

    @media (--medium-viewport) {
        .summary {
            max-width: 30em;
        }

        &.inline {
            .summary {
                max-width: 300px;
            }
        }
    }
}
