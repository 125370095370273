.container {
    --card-color: var(--brand-text-color);

    position: relative;
    padding-bottom: var(--block-spacing);
    color: var(--brand-text-color);

    .header {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 300px;
        max-width: 45%;
        padding: var(--side-spacing) 0 0 var(--side-spacing);
    }

    .blocks {
        --block-spacing: 60px;
    }

    .collectionsRow {
        --grid-spacing: 40px;
    }

    :global(.block-header_featured_list) {
        margin-bottom: var(--spacing-large);
    }

    .featuredBlock {
        position: relative;
        z-index: 1;
        --card-color: #fff;

        .featuredInner {
            padding: 0;
        }

        .featuredList {
            max-width: 1400px;
        }

        .featuredCard {
            height: 70vh;
            height: 70svh;
        }
    }

    .title {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .viewAllButton {
        margin-left: 10px;
        white-space: nowrap;
    }

    .sectionTitle {
        margin-right: auto;

        &.space {
            margin-bottom: var(--spacing-medium);
        }

        &.big {
            padding-top: 0.1em;
            border-top: none;
            margin-bottom: var(--spacing-medium);
            font-family: var(--font-garage);
            font-size: 3rem;

            &.space {
                margin-bottom: var(--spacing-large);
            }
        }

        &.small {
            font-size: 1rem;
            border-width: 1px;
        }

        &.sub {
            margin-top: 20px;
        }
    }

    @media (--small-viewport) {
        .featuredMicromag {
            --card-font-size-featured-title: 3rem;
            --card-font-size-featured-description: 1.25rem;

            padding: var(--side-spacing);
            border-radius: var(--card-spacing);
            background: var(--card-box-translucent-background);
        }
    }

    @media (--medium-viewport) {
        .featuredBlock {
            .featuredCard {
                min-height: 700px;
            }
        }
    }

    @media (--large-viewport) {
        .featuredBlock {
            .featuredCard {
                min-height: 800px;
            }
        }
    }
}
