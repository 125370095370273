:root {
    /*  Menu */
    --menu-emboss-box-shadow: -4px -4px 4px 0px rgba(0 0 0 / 0.25) inset,
        4px 4px 6px 0px rgba(245 245 245 / 0.24) inset;
    --menu-border-radius: 0.625rem;
    --menu-section-border: 1px solid rgba(34 34 34 / 0.3);
    --menu-section-border-light: 1px solid var(--card-border-color-light);
    --menu-section-border-dark: 1px solid rgba(255 255 255 / 0.40);;

    /* Menu button */
    --menu-button-spacing: 15px;
    --menu-button-size: 30px;
    --menu-urbania-size: 25px;

    /* Spacing */
    --menu-spacing: var(--spacing-small);
    --menu-spacing-large: var(--spacing);
    --menu-spacing-extra-large: var(--spacing-medium);
    --menu-section-spacing: var(--spacing-medium) 0;

    /* Header */
    --menu-header-top-color: var(--color-black);
    --menu-header-top-height: 30px;
    --menu-header-padding-top: 50px;
    --menu-header-sponsor-height: 1.75rem;
    --menu-header-tagline-font-size: 1.2em;
    --menu-header-pill-font-weight: 700;
    --menu-header-section-font-size: 1rem;
    --menu-header-index-font-size: 0.875rem;
    --menu-header-index-background-dark: rgba(34 34 34 / 0.1);
    --menu-header-index-background-light: rgba(255 255 255 / 0.2);
    --menu-header-index-color-light: var(--color-white);
    --menu-header-index-color-dark: var(--color-black);

    /*  Section title */
    --menu-section-title-padding: 0 0 var(--menu-spacing-extra-large);
    --menu-section-title-margin: 0;
    --menu-section-title-font-size: 1.25rem;

    /*  Sub section title */
    --menu-sub-section-title-padding: var(--menu-spacing) 0;
    --menu-sub-section-title-margin: 0;
    --menu-sub-section-title-font-size: 1rem;
}
