.container {
    height: calc(var(--control-padding, 0.5em) * 2 + 1em);
    padding: var(--control-padding, 0.5em);
    border: 0;
    border-radius: var(--control-border-radius);
    appearance: none;
    background: var(--control-background);
    color: var(--control-color);
    font-family: var(--control-font-family);
    font-size: inherit;
    font-weight: var(--control-font-weight);

    :global(.was-validated) &:invalid {
        --control-border-color: var(--control-errors-color);

        background: var(--control-errors-background-color);
    }

    &::placeholder {
        color: var(--control-placeholder-color);
        opacity: 1;
    }

    &.withBorder {
        border: var(--control-border);
    }

    &:focus {
        box-shadow: var(--control-focus-box-shadow);
        outline: 0;
    }
}
