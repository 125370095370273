.container {
    overflow: hidden;
    color: inherit;
    font-size: var(--card-font-size);
    text-decoration: none;

    /* .title {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
    } */
}
