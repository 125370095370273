.container {
    display: inline-block;
    width: 1em;
    height: 1em;
    background-image: url('../../assets/img/icons/types_circle_icons.svg');
    background-repeat: no-repeat;
    background-size: 900% 100%;
    background-position: 200% 0;

    &.video {
        background-position: 0 0;
    }

    &.newsletter {
        background-position: 12.5% 0;
    }

    &.micromag {
        background-position: 25% 0;
    }

    &.podcast {
        background-position: 37.5% 0;
    }

    &.location {
        background-position: 50% 0;
    }

    &.article {
        background-position: 62.5% 0;
    }

    &.withoutCircle {
        background-image: url('../../assets/img/icons/types_icons.svg');
    }
}
