.container {
    .date {
        display: flex;
        flex-direction: column;
        font-size: 1.5rem;
        text-transform: uppercase;

        .day {
            font-size: 4rem;
        }

        .month {
            margin-top: -0.5rem;
        }

    }
}
