.container {
    .video {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
        transition: opacity 0.2s ease-out;
    }

    &.urbania {
        background: #222;

        &.bumper2 {
            background: #222;
        }

        &.bumper3 {
            background: #202038;
        }
    }

    &.dehors {
        background: #c8dfff;
    }

    &.quatre95 {
        background: var(--color-quatre95);
    }

    &.mollo {
        background: var(--color-mollo);
    }

    &.periodelibre {
        background: var(--color-periodelibre);
    }

    &.france {
        background: var(--color-france);
    }

    /* &.loaded {
        .video {
            opacity: 1;
        }
    } */
}
