.container {
    position: relative;
    display: inline-flex;
    flex-direction: row;

    .searchIcon {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: var(--control-padding);
        width: 1em;
        height: 1em;
        color: var(--control-placeholder-color);
        pointer-events: none;
        transform: translate(0, -50%);
    }

    .input {
        position: relative;
        z-index: 1;
        width: 100%;
        padding-right: calc(var(--control-padding) * 2 + 0.5em);
        padding-left: calc(var(--control-padding) * 2 + 1em);
        border-radius: var(--control-search-border-radius);
        font-size: 1em;
    }

    .closeButton,
    .loading {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0;
    }

    .closeButton {
        z-index: 2;
        padding: calc(var(--control-padding) + 0.2em);
        border: 0;
        appearance: none;
        background: transparent;
        cursor: pointer;
        font-size: inherit;
        pointer-events: none;
    }

    .loading {
        z-index: 1;
        padding: var(--control-padding);
        pointer-events: none;
    }

    .closeIcon,
    .loadingIcon {
        display: block;
        color: var(--control-placeholder-color);
    }

    .closeIcon {
        width: 0.6em;
        height: 0.6em;
    }

    .loadingIcon {
        width: 1em;
        height: 1em;
    }

    &.hasValue {
        .searchIcon,
        .closeIcon {
            color: var(--control-color);
        }

        .closeButton {
            opacity: 1;
            pointer-events: all;
        }
    }
}
