.container {
    .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }

    .form {
        width: 100%;
    }

    .buttons {
        margin-top: var(--spacing);
    }

    .buttonsInner {
        display: flex;
        margin: calc(var(--form-button-spacing) * -1);

        .button {
            margin: var(--form-button-spacing);
            font-size: var(--form-button-font-size);
        }
    }

    .input {
        width: 100%;
    }

    .value {
        position: relative;
        display: flex;
        width: 100%;
        height: calc(var(--control-padding, 0.5em) * 2 + 1em);
        align-items: center;
        padding: var(--control-padding, 0.5em);
        border: var(--control-disabled-border);
        border-radius: var(--control-border-radius);
        background: var(--control-translucent-background-color);
        color: var(--control-color);
        font-family: var(--control-font-family);
        font-size: inherit;
        font-weight: var(--control-font-weight);

        .placeholder {
            overflow: hidden;
            margin-right: 4em;
            color: rgba(34 34 34 / 0.6);
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .button {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: flex-start;
            padding: var(--control-padding, 0.5em);
            text-align: right;

            &.phone {
                margin-left: 0;
            }
        }

        &.hasValue {
            .button {
                justify-content: flex-end;
            }
        }
    }

    .field {
        position: relative;

        &.hasStatus {
            .input {
                padding-right: calc(var(--control-padding) + 1em);
            }
        }
    }

    .status {
        position: absolute;
        z-index: 2;
        top: 50%;
        right: calc(var(--control-padding) / 2);
        transform: translateY(-50%);
    }

    .icon {
        margin-right: 0.325em;
    }

    .verify {
        width: 100%;
        margin-top: var(--list-spacing);
    }
}
