.container {
    padding: var(--spacing-medium) var(--side-spacing);
    font-size: var(--header-index-font-size);

    .bar {
        border-top: var(--header-index-border, double 6px var(--brand-primary-color, var(--color-black)));
    }

    .title {
        padding: 0.2em 0 0.1em;
        margin: 0;
        font-size: inherit;
    }

    @media (--medium-viewport) {
        font-size: var(--header-index-font-size-medium);
    }

    @media (--large-viewport) {
        font-size: var(--header-index-font-size-large);
    }
}
