.container {
    display: block;
    height: var(--button-urbania-size);
}

.logo {
    display: block;
    width: auto;
    height: 100%;
}
