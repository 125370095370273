.container {
    width: 100%;
    padding: 0.8em;
    border: 1px solid var(--color-black);
    border-radius: var(--menu-border-radius);
    background: linear-gradient(180deg, rgb(255 255 255 / 0.30) 0%, rgb(255 255 255 / 0.00) 100%), #222;
    color: var(--color-white);
    font-size: .875rem;
    font-weight: 700;
    text-decoration: none;

    &.yellowPink {
        background: linear-gradient(33deg, #FF94DB 0%, #FFFC00 100%);
        color: var(--color-black)
    }

    &.documentCustomHeader{
        background: linear-gradient(180deg, #FFFC00 0%, #F08D9B 100%);
    }
}




