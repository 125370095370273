.container {
    --card-font-size-featured-title: 3rem;
    --card-font-size-featured-description: 1.25rem;

    .summary {
        font-size: var(--card-font-size-featured-description);
    }

    .summaryTitle {
        font-family: var(--font-garage);
        font-size: var(--card-font-size-featured-title);
        line-height: 1;
        text-transform: uppercase;
    }

    .summaryButton {
        font-size: 0.85em;
    }

    @media (--small-viewport) {
        .summary {
            max-width: 400px;
        }
    }

    @media (--medium-viewport) {
        --card-font-size-featured-title: 4rem;
        --card-font-size-featured-description: 1.5rem;

        .label {
            width: auto;
        }

        .imageContainer {
            max-width: 400px;
            margin: auto;
            margin-right: 0;
        }
    }

    @media (--large-viewport) {
        .label {
            margin: auto !important;
        }

        .imageContainer {
            margin-right: auto;
        }
    }

    @media (--x-large-viewport) {
        .imageContainer {
            max-width: 500px;
        }
    }
}
