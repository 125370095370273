.container {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;

    .topics {
        position: absolute;
        z-index: 2;
        top: 16px;
        left: 8px;
        display: flex;
        width: 90%;
        align-items: center;

        .button {
            padding: 0.1875rem 0.5rem 0;
            border: 1px solid var(--color-black);
            border-radius: 2rem;
            margin-right: var(--spacing-small);
            margin-bottom: var(--spacing-small);
            background: var(--color-white);
            font-size: 0.875rem;
            font-weight: 700;
            text-transform: uppercase;
        }

    }


    @media (--medium-viewport) {


    }















}
