.container {
    font-family: var(--control-font-family);
    vertical-align: top;

    .input {
        display: inline-block;
        width: 1em;
        height: 1em;
        border: var(--control-border);
        border-radius: 0.2em;
        margin-right: var(--spacing);
        appearance: none;
        font-size: 1em;

        &:checked {
            position: relative;

            &::after {
                position: absolute;
                top: -0.1em;
                left: -0.05em;
                display: block;
                content: '✓';
                font-size: 1em;
                font-weight: bold;
                line-height: 1em;
            }
        }

        :global(.was-validated) &:invalid {
            background: var(--control-errors-background-color);
            border-color: var(--control-errors-color);
        }

        :global(.was-validated) &:invalid ~ .label {
            color: var(--control-errors-color);
        }
    }
}
