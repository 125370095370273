.control {
    display: flex;
    width: 1.5em;
    height: 1em;
    flex-direction: row;
    padding: 0.1em;
    border-radius: 0.5em;
    background: rgba(34, 34, 34, 0.3);
    transition: background 0.2s ease-out;
}

.knob {
    display: block;
    width: 0.8em;
    height: 0.8em;
    border-radius: 0.4em;
    background: rgba(255, 255, 255, 0.9);
    transition: transform 0.2s ease-out;
}

.loadingIcon {
    display: block;
    width: 0.6em;
    height: 0.6em;
    margin: 0.1em;
    color: rgba(0, 0, 0, 0.5);
}

.checked {
    .control {
        background: #0c9;
    }

    .knob {
        transform: translateX(0.5em);
    }
}
