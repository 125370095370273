.container {
    .items {
        display: flex;
        flex-direction: column;
    }

    .item {
        display: flex;
        /* border: solid 1px #c00; */
        /* padding: 0 var(--side-spacing); */
        margin-bottom: var(--grid-spacing);

        &:last-child {
            margin-bottom: 0;
        }
    }

    .card {
        flex-grow: 1;
    }

    &.layout1114 {
        @media (--small-viewport) {
            .item {
                padding: 0;
                margin-bottom: 0;
            }

            .items {
                display: grid;
                padding: 0 var(--side-spacing);
                gap: var(--grid-spacing);
                grid-template-areas:
                    'item_0 item_1'
                    'item_0 item_1'
                    'item_0 item_1'
                    'item_0 item_1'
                    'item_2 item_3'
                    'item_2 item_4'
                    'item_2 item_5'
                    'item_2 item_6';
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: auto;
            }
        }

        @media (--medium-viewport) {
            .items {
                grid-template-areas:
                    'item_0 item_0 item_1 item_1 item_2 item_2'
                    'item_3 item_3 item_3 item_4 item_4 item_4'
                    'item_5 item_5 item_5 item_6 item_6 item_6';
                grid-template-columns: repeat(6, 1fr);
                grid-template-rows: auto;
            }
        }

        @media (--large-viewport) {
            .items {
                grid-template-areas:
                    'item_0 item_0 item_0 item_0 item_1 item_1 item_1 item_1 item_2 item_2 item_2 item_2'
                    'item_3 item_3 item_3 item_4 item_4 item_4 item_5 item_5 item_5 item_6 item_6 item_6';
                grid-template-columns: repeat(12, 1fr);
                grid-template-rows: auto;
            }
        }

        @media (--x-large-viewport) {
            .items {
                grid-template-areas:
                    'item_0 item_1 item_2 item_3'
                    'item_0 item_1 item_2 item_4'
                    'item_0 item_1 item_2 item_5'
                    'item_0 item_1 item_2 item_6';
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: auto;
            }
        }
    }

    &.layout1141 {
        @media (--small-viewport) {
            .item {
                padding: 0;
                margin-bottom: 0;
            }

            .items {
                display: grid;
                padding: 0 var(--side-spacing);
                gap: var(--grid-spacing);
                grid-template-areas:
                    'item_0 item_1'
                    'item_0 item_1'
                    'item_0 item_1'
                    'item_0 item_1'
                    'item_2 item_6'
                    'item_3 item_6'
                    'item_4 item_6'
                    'item_5 item_6';
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: auto;
            }
        }

        @media (--medium-viewport) {
            .items {
                grid-template-areas:
                    'item_0 item_0 item_1 item_1 item_6 item_6'
                    'item_2 item_2 item_2 item_3 item_3 item_3'
                    'item_4 item_4 item_4 item_5 item_5 item_5';
                grid-template-columns: repeat(6, 1fr);
                grid-template-rows: auto;
            }
        }

        @media (--large-viewport) {
            .items {
                grid-template-areas:
                    'item_0 item_0 item_0 item_0 item_1 item_1 item_1 item_1 item_6 item_6 item_6 item_6'
                    'item_2 item_2 item_2 item_3 item_3 item_3 item_4 item_4 item_4 item_5 item_5 item_5';
                grid-template-columns: repeat(12, 1fr);
                grid-template-rows: auto;
            }
        }

        @media (--x-large-viewport) {
            .items {
                grid-template-areas:
                    'item_0 item_1 item_2 item_6'
                    'item_0 item_1 item_3 item_6'
                    'item_0 item_1 item_4 item_6'
                    'item_0 item_1 item_5 item_6';
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: auto;
            }
        }
    }

    &.layout1414 {
        @media (--small-viewport) {
            .item {
                padding: 0;
                margin-bottom: 0;
            }

            .items {
                display: grid;
                padding: 0 var(--side-spacing);
                gap: var(--grid-spacing);
                grid-template-areas:
                    'item_0 item_1'
                    'item_0 item_2'
                    'item_0 item_3'
                    'item_0 item_4'
                    'item_5 item_6'
                    'item_5 item_7'
                    'item_5 item_8'
                    'item_5 item_9';
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: auto;
            }
        }

        @media (--large-viewport) {
            .items {
                grid-template-areas:
                    'item_0 item_0 item_0 item_0 item_5 item_5 item_5 item_5 item_1 item_1 item_1 item_1'
                    'item_0 item_0 item_0 item_0 item_5 item_5 item_5 item_5 item_2 item_2 item_2 item_2'
                    'item_0 item_0 item_0 item_0 item_5 item_5 item_5 item_5 item_3 item_3 item_3 item_3'
                    'item_0 item_0 item_0 item_0 item_5 item_5 item_5 item_5 item_4 item_4 item_4 item_4'
                    'item_6 item_6 item_6 item_7 item_7 item_7 item_8 item_8 item_8 item_9 item_9 item_9';
                grid-template-columns: repeat(12, 1fr);
                grid-template-rows: auto;
            }
        }

        @media (--x-large-viewport) {
            .items {
                grid-template-areas:
                    'item_0 item_1 item_5 item_6'
                    'item_0 item_2 item_5 item_7'
                    'item_0 item_3 item_5 item_8'
                    'item_0 item_4 item_5 item_9';
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: auto;
            }
        }
    }
}
