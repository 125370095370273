.container {
    min-height: 100vh;
    background: var(--color-white);

    .header {
        margin-bottom: var(--block-spacing);
    }

    .filters {
        padding: 0 var(--side-spacing);
        margin-bottom: var(--spacing);
    }
}
