.container {
    position: relative;
    display: block;
    width: 100%;
    font-size: var(--summary-font-size);
    text-decoration: inherit;

    .inner {
        position: relative;
        display: grid;
        max-width: 1200px;
        grid-template-areas:
            'image'
            'surtitle'
            'title'
            'description'
            'sponsor'
            'children'
            'button';
    }

    .children {
        margin-top: var(--spacing);
        grid-area: children;
    }

    .image {
        position: relative;;
        max-width: 100px;
        max-height: 100px;
        margin-bottom: var(--spacing);
        grid-area: image;
    }

    .icon {
        position: relative;
        margin-bottom: var(--spacing);
        font-size: var(--summary-font-size-icon);
        grid-area: image;

        > * {
            display: block;
        }
    }

    .surtitle {
        font-size: var(--summary-font-size-surtitle);
        grid-area: surtitle;
        text-transform: uppercase;
    }

    .title {
        font-size: var(--summary-font-size-title);
        grid-area: title;
    }

    .description {
        max-width: 580px;
        margin-top: var(--spacing-small);
        font-size: var(--summary-font-size-description);
        grid-area: description;

        p {
            margin-bottom: 0.5em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .sponsor {
        margin-top: var(--spacing);
        grid-area: sponsor;
    }

    .sponsorImage {
        height: 2em;

        [data-theme="dark"] & {
            filter: grayscale(100%) invert(1);
        }
    }

    .button {
        position: relative;
        width: fit-content;
        margin-top: var(--spacing);
        font-size: var(--summary-font-size-button);
        grid-area: button;
    }

    &.inline {
        .inner {
            display: grid;
            grid-template-areas:
                'image surtitle'
                'image title'
                'description description'
                'sponsor sponsor'
                'children children'
                'button button';
            grid-template-columns: 3rem 1fr;
        }

        .image {
            max-width: 3rem;
            max-height: 3rem;
            margin-bottom: 0;
        }

        .surtitle,
        .title {
            display: flex;
            width: 100%;
            align-items: center;
            padding-top: 0;
            padding-left: var(--spacing);
        }

        &.withIcon {
            .inner {
                grid-template-columns: 2rem 1fr;
            }

            .icon {
                max-width: 2rem;
                max-height: 2rem;
                margin-bottom: 0;
                font-size: 2rem;
            }
        }

        &.withoutImage {
            .inner {
                grid-template-areas:
                    'surtitle'
                    'title'
                    'description'
                    'sponsor'
                    'children'
                    'button';
                grid-template-columns: auto;
            }

            .title {
                padding-left: 0;
            }
        }
    }

    &.right {
        .inner {
            grid-template-areas:
                'surtitle image'
                'title image'
                'description image'
                'sponsor image'
                'children image'
                'button image';
            grid-template-columns: auto 100%;
            grid-template-rows: auto;
        }

        .image {
            max-width: none;
            max-height: none;
            margin-bottom: 0;
            grid-area: image;
        }

        .surtitle,
        .title,
        .description,
        .sponsor,
        .button {
            margin-right: var(--spacing);
            margin-bottom: 0;
        }

        &.withoutImage {
            .inner {
                grid-template-areas:
                    'surtitle'
                    'title'
                    'description'
                    'sponsor'
                    'children'
                    'button';
                grid-template-columns: auto;
            }
        }
    }

    &.left {
        .inner {
            grid-template-areas:
                'image surtitle'
                'image title'
                'image description'
                'image sponsor'
                'image children'
                'image button';
            grid-template-columns: auto 100%;
            grid-template-rows: auto;
        }

        .image {
            max-width: none;
            max-height: none;
            margin-bottom: 0;
            grid-area: image;
        }

        .surtitle,
        .title,
        .description,
        .sponsor,
        .button {
            margin-left: var(--spacing);
            margin-bottom: 0;
        }

        &.withoutImage {
            .inner {
                grid-template-areas:
                    'surtitle'
                    'title'
                    'description'
                    'sponsor'
                    'children'
                    'button';
                grid-template-columns: auto;
            }
        }
    }
}
