.container {
    container-name: grid;
    container-type: inline-size;
}

.items {
    display: grid;
    gap: var(--grid-spacing);
    grid-template-columns: repeat(1, 1fr);

    &.min2columns {
        grid-template-columns: repeat(2, 1fr);
    }

    &.min3columns {
        grid-template-columns: repeat(3, 1fr);

        &.count8 {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @container grid (min-width: 575px) {
        grid-template-columns: repeat(2, 1fr);

        &.min2columns,
        &.min3columns {
            grid-template-columns: repeat(3, 1fr);

            &.count8 {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    @container grid (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);

        &.count8 {
            grid-template-columns: repeat(2, 1fr);
        }

        &.count4 {
            grid-template-columns: repeat(2, 1fr);
        }

        &.max3columns {
            grid-template-columns: repeat(2, 1fr);
        }

        &.min2columns {
            grid-template-columns: repeat(4, 1fr);

            &.count8 {
                grid-template-columns: repeat(4, 1fr);
            }

            &.max3columns {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        &.min3columns {
            grid-template-columns: repeat(4, 1fr);

            &.count8 {
                grid-template-columns: repeat(4, 1fr);
            }

            &.max3columns {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }

    @container grid (min-width: 1200px) {
            grid-template-columns: repeat(4, 1fr);

            &.count8 {
                grid-template-columns: repeat(4, 1fr);
            }

            &.max3columns {
                grid-template-columns: repeat(3, 1fr);
            }

            &.min2columns {
                grid-template-columns: repeat(4, 1fr);

                &.count8 {
                    grid-template-columns: repeat(4, 1fr);
                }

                &.max3columns {
                    grid-template-columns: repeat(3, 1fr);
                }
            }

            &.min3columns {
                grid-template-columns: repeat(4, 1fr);

                &.count8 {
                    grid-template-columns: repeat(4, 1fr);
                }

                &.max3columns {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
    }

    @container grid (min-width: 1400px) {
        grid-template-columns: repeat(6, 1fr);

        &.count8 {
            grid-template-columns: repeat(8, 1fr);
        }

        &.min2columns {
            grid-template-columns: repeat(6, 1fr);
        }

        &.min3columns {
            grid-template-columns: repeat(6, 1fr);
        }

        &.max3columns {
            grid-template-columns: repeat(3, 1fr);
        }

        &.max4columns {
            grid-template-columns: repeat(4, 1fr);
        }

        &.max5columns {
            grid-template-columns: repeat(4, 1fr);
        }

        &.max6columns {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @supports not (contain: inline-size) {
        @media (--small-viewport) {
            grid-template-columns: repeat(2, 1fr);

            &.min2columns,
            &.min3columns {
                grid-template-columns: repeat(3, 1fr);

                &.count8 {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }

        @media (--medium-viewport) {
            grid-template-columns: repeat(3, 1fr);

            &.count8 {
                grid-template-columns: repeat(2, 1fr);
            }

            &.count4 {
                grid-template-columns: repeat(2, 1fr);
            }

            &.max3columns {
                grid-template-columns: repeat(2, 1fr);
            }

            &.min2columns {
                grid-template-columns: repeat(4, 1fr);

                &.count8 {
                    grid-template-columns: repeat(4, 1fr);
                }

                &.max3columns {
                    grid-template-columns: repeat(3, 1fr);
                }
            }

            &.min3columns {
                grid-template-columns: repeat(4, 1fr);

                &.count8 {
                    grid-template-columns: repeat(4, 1fr);
                }

                &.max3columns {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }

        @media (--large-viewport) {
            grid-template-columns: repeat(4, 1fr);

            &.count8 {
                grid-template-columns: repeat(4, 1fr);
            }

            &.max3columns {
                grid-template-columns: repeat(3, 1fr);
            }

            &.min2columns {
                grid-template-columns: repeat(4, 1fr);

                &.count8 {
                    grid-template-columns: repeat(4, 1fr);
                }

                &.max3columns {
                    grid-template-columns: repeat(3, 1fr);
                }
            }

            &.min3columns {
                grid-template-columns: repeat(4, 1fr);

                &.count8 {
                    grid-template-columns: repeat(4, 1fr);
                }

                &.max3columns {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }

        @media (--x-large-viewport) {
            grid-template-columns: repeat(6, 1fr);

            &.count8 {
                grid-template-columns: repeat(8, 1fr);
            }

            &.min2columns {
                grid-template-columns: repeat(6, 1fr);
            }

            &.min3columns {
                grid-template-columns: repeat(6, 1fr);
            }

            &.max3columns {
                grid-template-columns: repeat(3, 1fr);
            }

            &.max4columns {
                grid-template-columns: repeat(4, 1fr);
            }

            &.max5columns {
                grid-template-columns: repeat(4, 1fr);
            }

            &.max6columns {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}

.item {
    display: flex;
    flex-direction: column;
}

.card {
    flex-grow: 1;
}
