.container {
    padding: 0;
    margin: 0;
    font-family: var(--typo-header-title-font-family);
    font-weight: var(--typo-header-title-font-weight);
    letter-spacing: var(--typo-header-title-letter-spacing);
    line-height: 1;
    text-transform: uppercase;

    &.quatre95 {
        font-family: var(--typo-header-title-font-family-quatre95);
        font-weight: var(--typo-header-title-font-weight-quatre95);
        letter-spacing: var(--typo-header-title-letter-spacing-quatre95);
        line-height: var(--typo-header-title-line-height-quatre95);
    }

    &.dehors {
        font-family: var(--typo-header-title-font-family-dehors);
        font-weight: var(--typo-header-title-font-weight-dehors);
        line-height: var(--typo-header-title-line-height-dehors);
    }

    &.mollo {
        font-family: var(--typo-header-title-font-family-mollo);
        font-weight: var(--typo-header-title-font-weight-mollo);
        line-height: var(--typo-header-title-line-height-mollo);
    }
}
