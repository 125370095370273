.container {
    position: relative;
    display: inline-block;
    font-family: var(--font-tight);

    .status {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;

        &:first-child {
            position: relative;
        }
    }

    .icon {
        display: block;
        width: 1em;
        height: 1em;
    }

    .message {
        margin-left: 0.5em;
    }

    &.iconRight {
        .status {
            right: 0;
            left: auto;
            flex-direction: row-reverse;
        }

        .message {
            margin-right: 0.5em;
            margin-left: 0;
        }
    }
}
