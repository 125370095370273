.container {
    color: var(--card-color, inherit);
    font-size: var(--card-font-size);

    .inner {
        align-items: flex-start;
    }

    .thumbnail {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .thumbnailIcon {
        width: 1.25em;
        height: 1.25em;
    }

    .label {
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-top: var(--card-spacing-label);

        > .time {
            margin-bottom: var(--spacing);
        }
    }

    .footer {
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .category {
        margin-bottom: var(--card-spacing-category);
        font-size: var(--card-font-size-category);
    }

    .surtitle {
        margin-bottom: var(--card-spacing-surtitle);
        font-size: var(--card-font-size-surtitle);
        opacity: var(--card-surtitle-opacity);
    }

    .title {
        margin-bottom: var(--card-spacing-large);
        font-size: var(--card-font-size-title);
    }

    .withTime {
        .title {
            margin-bottom: 0.4em;
        }
    }

    .time {
        font-size: var(--card-font-size-time);
        /* text-transform: uppercase; */
    }

    .meta {
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-end;
        margin-top: auto;

        > * {
            opacity: var(--card-meta-opacity);
        }

        .time {
            margin-left: auto;
        }
    }

    .author {
        align-items: center;
        margin-right: 1em;
        font-size: var(--card-font-size-author);
        transform: translateY(0.1em);
    }

    .sponsor {
        width: 100%;
        flex-grow: 1;
        font-size: var(--card-font-size-sponsor);
        line-height: 1;
    }

    .duration {
        position: absolute;
        z-index: 2;
        right: var(--spacing);
        bottom: var(--spacing);
        padding: var(--card-duration-padding);
        border-radius: var(--card-duration-border-radius);
        background: var(--card-duration-background);
        color: var(--color-white);
        font-size: var(--card-font-size-duration);
    }

    &.small {
        --card-font-size: var(--card-font-size-small);
        --card-font-size-duration: var(--card-font-size-duration-small);
        --card-font-size-author: var(--card-font-size-author-small);
        --card-font-size-sponsor: var(--card-font-size-sponsor-small);
        --card-font-size-time: var(--card-font-size-time-small);
        --spacing: var(--spacing-small);
        --card-spacing-large: var(--card-spacing);

        &.hasSurtitle {
            .category {
                display: none;
            }
        }
    }

    &.big {
        --card-font-size: var(--card-font-size-big);
        --card-font-size-category: var(--card-font-size-category-big);
        --card-font-size-duration: var(--card-font-size-duration-big);
        --card-font-size-author: var(--card-font-size-author-big);
        --card-font-size-sponsor: var(--card-font-size-sponsor-big);
        --card-font-size-time: var(--card-font-size-time-big);
        --card-spacing-label: var(--card-spacing-large);
    }

    @media (--mobile-viewport) {
        &.responsive {
            --card-font-size: var(--card-font-size-small);
            --card-font-size-duration: var(--card-font-size-duration-small);
            --card-font-size-author: var(--card-font-size-author-small);
            --card-font-size-sponsor: var(--card-font-size-sponsor-small);
            --card-font-size-time: var(--card-font-size-time-small);
            --spacing: var(--spacing-small);
            --card-spacing-large: var(--card-spacing);

            &.big {
                --card-font-size: var(--card-font-size-big);
                --card-font-size-category: var(--card-font-size-category-big);
                --card-font-size-duration: var(--card-font-size-duration-big);
                --card-font-size-author: var(--card-font-size-author-big);
                --card-font-size-sponsor: var(--card-font-size-sponsor-big);
                --card-font-size-time: var(--card-font-size-time-big);
            }

            &.hasSurtitle {
                .category {
                    display: none;
                }
            }
        }
    }

    &.hasSponsor {
        .meta {
            padding: var(--card-sponsor-padding);
            background: var(--card-sponsor-background);
            opacity: var(--card-sponsor-opacity);
        }

        .time {
            display: none;
        }

        &.small {
            .title {
                margin-bottom: var(--card-spacing);
            }
        }
    }

    &.withBorder {
        border-bottom: var(--card-border-bottom);

        .meta {
            padding-bottom: var(--card-spacing-bottom);
        }

        &.hasSponsor {
            /* border-bottom: 0; */

            .meta {
                padding: var(--card-sponsor-padding);
            }
        }
    }

    &.suggestion {
        font-size: var(--card-font-size-suggestion);

        .label {
            padding-top: var(--spacing);
        }

        .title {
            display: -webkit-box;
            overflow: hidden;
            /* height: 3em;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; */
        }
    }

    &.box {
        position: relative;
        overflow: hidden;
        width: 100%;
        border-radius: var(--card-border-radius);
        background-color: var(--color-white);
        color: var(--card-color, var(--color-black));

        .label {
            min-height: 3.5em;
            padding-right: var(--card-spacing-label);
            padding-bottom: var(--card-spacing);
            padding-left: var(--card-spacing-label);
        }

        &.hasSponsor {
            .label {
                padding: var(--card-spacing-label) 0 0;

                > * {
                    padding-right: var(--card-spacing-label);
                    padding-left: var(--card-spacing-label);
                }
            }

            .meta {
                padding: var(--card-spacing) var(--card-spacing-label);
                background: var(--card-sponsor-background-white);
            }

            &.small {
                .meta {
                    padding: var(--spacing-small) var(--card-spacing-label);
                }
            }
        }

        /* &.square {
            padding-bottom: 100%;
        }

        &.horizontal {
            padding-bottom: 80%;
        }

        &.vertical {
            padding-bottom: 125%;
        }

        &.micromag {
            padding-bottom: 150%;
        } */

        &.responsive {
            min-height: 15em;
            padding-bottom: 0;

            .label {
                min-height: 5em;
            }

            .meta {
                min-height: 0;
            }
        }
    }

    &.boxTranslucent {
        background-color: var(--card-box-translucent-background);
        color: var(--card-color, var(--color-black));
    }

    &.boxTranslucentDark {
        background-color: var(--card-box-translucent-dark-background);
        color: var(--card-color, var(--color-black));

        &.hasSponsor {
            .meta {
                background: var(--card-sponsor-background);
            }
        }
    }

    &.boxDark {
        background-color: var(--color-black);
        color: var(--card-color, var(--color-grey));
    }

    &.boxBrand {
        background-color: var(--brand-primary-color, var(--color-white));
        color: var(--card-color, var(--text-on-primary-color, var(--color-black)));
    }

    &.rounded,
    &.menu,
    &.suggestion {
        padding-bottom: 0;

        .thumbnail {
            overflow: hidden;
            border-radius: var(--card-border-radius);
        }
    }

    &.menu,
    &.suggestion {
        .thumbnail {
            &::after {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                box-shadow: var(--card-emboss-box-shadow);
                content: '';
                transition: box-shadow 0.2s ease-in-out;
            }
        }

        .title {
            overflow-y: hidden;
        }

        &:active,
        &:hover {
            .thumbnail {
                &::after {
                    box-shadow: var(--card-emboss-down-box-shadow);
                }
            }
        }
    }
}
