:root {
    /* Cards */
    --card-border-color: rgba(34 34 34 / 0.4);
    --card-border-color-light: rgba(0 0 0/ 0.05);
    --card-border-bottom: 1px solid var(--card-border-color);
    --card-border-radius: 10px;
    --card-border-large-radius: 15px;
    --card-box-shadow: 0 1px 4px 0px rgba(0 0 0 / 0.25);
    --card-box-shadow-down: 0 0 4px 0 rgba(0 0 0 / 0.25);
    --card-text-shadow: 0 0.2em 0.5em rgb(0 0 0 / 0.9);

    /* Colors */
    --card-meta-opacity: 0.7;
    --card-surtitle-opacity: 0.7;
    --card-sponsor-opacity: 1;

    /* Spacing */
    --card-spacing: var(--spacing);
    --card-spacing-large: 15px;
    --card-spacing-title: 5px;
    --card-spacing-surtitle: 0.1em;
    --card-spacing-category: 0.6em;
    --card-spacing-featured-title: 0.5em;
    --card-spacing-bottom: var(--spacing-small);
    --card-spacing-featured: 10px;
    --card-spacing-label: var(--card-spacing);

    /* Font size */
    --card-font-size-small: 0.875rem;
    --card-font-size: 1.125rem;
    --card-font-size-medium: 1.25rem;
    --card-font-size-big: 1.4rem;

    /* Elements font-size */
    --card-font-size-title: 1em;
    --card-font-size-surtitle: 0.85em;
    --card-font-size-subtitle: 0.75em;
    --card-font-size-description: 0.85em;
    --card-font-size-category: 0.6em;
    --card-font-size-sponsor: 0.7rem;
    --card-font-size-author: 0.7rem;
    --card-font-size-duration: 0.7rem;
    --card-font-size-time: 0.7rem;
    --card-font-size-initials: 1.25rem;
    --card-font-size-icon-count: 0.7em;
    --card-font-size-sponsor-small: 0.6rem;
    --card-font-size-author-small: 0.6rem;
    --card-font-size-duration-small: 0.6rem;
    --card-font-size-time-small: 0.6rem;
    --card-font-size-category-big: 0.75rem;
    --card-font-size-author-big: 0.55em;
    --card-font-size-sponsor-big: 0.55em;
    --card-font-size-duration-big: 0.5em;
    --card-font-size-time-big: 0.5em;

    /* Horizontal Font size */
    --card-font-size-horizontal-small: 0.85rem;
    --card-font-size-horizontal: 1rem;
    --card-font-size-horizontal-big: 1.5rem;
    --card-font-size-horizontal-category-small: 0.6rem;
    --card-font-size-horizontal-surtitle-small: 0.9em;
    --card-font-size-horizontal-author-small: 0.6rem;
    --card-font-size-horizontal-sponsor-small: 0.6rem;
    --card-font-size-horizontal-duration-small: 0.6rem;
    --card-font-size-horizontal-time-small: 0.6rem;
    --card-font-size-horizontal-category-big: 0.75em;
    --card-font-size-horizontal-surtitle-big: 0.85em;
    --card-font-size-horizontal-author-big: 0.7em;
    --card-font-size-horizontal-sponsor-big: 0.7em;
    --card-font-size-horizontal-duration-big: var(--card-font-size-duration-big);
    --card-font-size-horizontal-time-big: var(--card-font-size-time-big);

    /* Over Font size */
    --card-font-size-over-small: 1rem;
    --card-font-size-over: 1.2rem;
    --card-font-size-over-big: 1.5rem;
    --card-font-size-over-category: 0.75rem;
    --card-font-size-over-surtitle: 0.75em;
    --card-font-size-over-sponsor: 0.85rem;
    --card-font-size-over-author: 0.85rem;
    --card-font-size-over-duration: 0.85rem;

    /* Menu Font size */
    --card-font-size-menu: 0.8rem;

    /* Featured font size */
    --card-font-size-featured-big: 4rem;
    --card-font-size-featured: 3rem;
    --card-font-size-featured-small: 2.5rem;
    --card-font-size-featured-category: 1rem;
    --card-font-size-featured-surtitle: 1.5rem;
    --card-font-size-featured-title: 1em;
    --card-font-size-featured-date: 0.75rem;
    --card-font-size-featured-description: 1rem;
    --card-font-size-featured-author: 0.75rem;
    --card-font-size-featured-sponsor: 1rem;
    --card-font-size-featured-category-small: 1rem;
    --card-font-size-featured-surtitle-small: 1rem;
    --card-font-size-featured-date-small: 0.75rem;
    --card-font-size-featured-description-small: 0.75rem;
    --card-font-size-featured-author-small: 0.75rem;
    --card-font-size-featured-sponsor-small: 0.75rem;
    --card-font-size-featured-author-medium: 0.85rem;
    --card-font-size-featured-author-large: 1rem;

    /* Brand font size */
    --card-font-size-brand-description-large: 1.125rem;

    /* Suggestion font size */
    --card-font-size-suggestion: 0.875rem;

    /* Author */
    --card-author-gradient: linear-gradient(
        to bottom right,
        rgb(228 206 255 / 1),
        rgb(0 255 194 / 1)
    );

    /* Author */
    --card-box-translucent-background: rgba(255 255 255 / 0.4);
    --card-box-translucent-dark-background: rgba(34 34 34 / 0.05);
    --card-box-background-hover-dark: rgba(34 34 34 / 0.57);

    /* Sponsors */
    --card-sponsor-padding: 0.3em var(--card-spacing-bottom) 0.2em;
    --card-sponsor-background: rgba(0 0 0 / 0.05);
    --card-sponsor-background-white: rgba(255 255 255 / 0.4);
    --card-sponsor-background-translucent: rgba(255 255 255 / 0.3);

    /* Duration */
    --card-duration-padding: 0.3em 0.5em 0.2em;
    --card-duration-background: rgba(0 0 0 / 0.4);
    --card-duration-border-radius: 0.2em;

    /* Emboss */
    --card-emboss-box-shadow: -4px -4px 4px 0px rgba(0 0 0 / 0.25) inset,
        4px 4px 6px 0px rgba(245 245 245 / 0.24) inset;
    --card-emboss-down-box-shadow: -4px -4px 4px 0px rgba(245 245 245 / 0.24) inset,
        4px 4px 6px 0px rgba(0 0 0 / 0.25) inset;

    /* Featured */
    --card-featured-text-shadow: 0 0 0.1em rgb(0 0 0 / 0.5);
    --card-featured-text-shadow-light: 0 0.05em 0.05em rgba(0 0 0 / 0.25);

    /* Locations */
    --card-locations-count-color: #f4e6d6;

    /* Subscription */
    --card-subscription-icon-size: 1.25em;
}
