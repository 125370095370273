:root {
    --color-black: #222;
    --color-yellow: #fd0;
    --color-white: #fff;
    --color-beige: #fff0e0;
    --color-grey: #ddd;
    --color-red: #c00;


    /* URBANIA */
    --color-urbania: var(--color-yellow);
    --color-urbania-text: var(--color-black);

    /* Dehors */
    --color-dehors: #566C30;
    --color-dehors-text: #1b331b;

    /* Quatre95 */
    --color-quatre95: #667AFF;
    --color-quatre95-text: #45a;
    --color-quatre95-very-light-blue: #e5eaff;

    /* Mollo */
    --color-mollo: #9916FF;
    --color-mollo-text: #608;

    /* Période Libre */
    --color-periodelibre: #FF662B;
    --color-periodelibre-text: #a30;

    /* France */
    --color-france: #1D3BF4;
    --color-france-text: #010065;

    /* Color header */

    /* --color-dehors-turquoise: #0c9;
    --color-dehors-beige: #f4e3de;
    --color-dehors-dark-green: #566c30;
    --color-quatre95-red: #f04e3e;
    --color-quatre95-pink: #f6b6c0;
    --color-quatre95: #5e79ff;
    --color-quatre95-light-blue: #79f;
    --color-quatre95-very-light-blue: #e5eaff;
    --color-urbania-yellow: #fffc00;
    --color-urbania-black: #222;
    --color-urbania-white: #fff;
    --color-mollo-dark-purple: #9747ff;
    --color-mollo: #9916ff;
    --color-mollo-beige: #f5ddd0;
    --color-france-blue: #1d3bf4;
    --color-france-dark-blue: #010065;
    --color-periodelibre-red: #ff662b;
    --color-periodelibre-orange: #ff662b;
    --color-periodelibre-brown */
}
