.container {
    position: relative;
    width: 100vw;
    flex-grow: 1;
    padding: 0 !important;
    border: 0 !important;
    margin: 0 !important;
    clip: rect(0, auto, auto, 0) !important;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%) !important;
}

.inner {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    transform: translateZ(0);
    transform-origin: 0 0;
}

.transform {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    transform: translateZ(0);
    transform-origin: 0 0;
}

.blurBackground {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    filter: blur(200px);
}
