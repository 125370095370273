.container {
    display: block;
    overflow: hidden;

    .label {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 10px;
    }

    .name {
        margin-bottom: 0.2em;
    }

    .description {
        opacity: var(--card-meta-opacity);
    }

    .button {
        margin-top: var(--spacing);
    }

    .toggle {
        margin-top: var(--spacing);
        font-size: 2em;
    }

    .unsubscribeButton {
        padding-right: 0;
        padding-left: 0;
        text-decoration: underline;
    }

    .icon {
        display: block;
        width: var(--card-subscription-icon-size);
        height: var(--card-subscription-icon-size);
        align-self: center;
        margin-left: var(--card-spacing);
        transition: opacity 0.2s ease-out;
    }

    .loadingIcon {
        color: var(--control-placeholder-color);
    }

    .checkIcon {
        color: #0c9;
        opacity: 0;
    }

    &.subscribed {
        .checkIcon {
            opacity: 1;
        }
    }

    &.isBrand {
        .thumbnail {
            max-width: 100px;
        }
    }

    &.box {
        padding: var(--card-spacing-large);
        border-radius: var(--card-border-radius);
        background-color: var(--color-white);
        transition: background 0.2s ease-out;

        .inner {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            /* margin: var(--card-spacing-subscription) auto; */
        }
    }

    &.notification {
        padding: var(--card-spacing-large);
        border-radius: var(--card-border-radius);
        background-color: rgba(0, 0, 0, 0.05);
        transition: background 0.2s ease-out;

        .inner {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            /* margin: var(--card-spacing-subscription) auto; */
        }
    }
}
