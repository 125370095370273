.container {
    min-height: 100vh;
    min-height: 100svh;
    padding: 60px 0 20px;
    background: var(--page-background);

    .menu {
        max-width: 100%;
        margin-bottom: var(--block-spacing);
    }

    .inner {
        position: relative;
        width: 100%;
        max-width: 800px;
        padding: 0 var(--side-spacing);
        margin: 0 auto;
    }

    .panel {
        margin-bottom: var(--spacing);
    }

    .urbania {
        position: absolute;
        top: var(--spacing);
        left: var(--spacing);
        opacity: 0;
    }

    .title {
        padding: 0 var(--spacing-medium);
        margin-bottom: var(--block-spacing);
    }

    &.entered,
    &.entering {
        .urbania {
            opacity: 1;
        }
    }

    &.leaving {
        .urbania {
            opacity: 0;
        }
    }
}
