.container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-decoration: none;

    .prefix {
        /* align-self: center; */
        padding-top: 0.25em;
        margin-right: 0.4em;
        font-weight: 400;
    }

    .label {
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
        padding-top: 0.25em;

        .prefix {
            /* align-self: center; */
            padding-top: 0;
            margin-right: 0;
            margin-right: 0.2em;
        }
    }

    .image {
        width: 1.5em;
        height: 1.5em;
        margin-right: 0.4em;
    }

    .name {
        padding-top: 0.05em;
    }

    > .name {
        padding-top: 0.25em;
    }

    .role {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: var(--spacing-small);
    }

    .role::after {
        display: block;
        margin-bottom: 0.1em;
        margin-left: var(--spacing-small);
        content: '|';
        font-size: 0.7em;
        text-transform: none;
    }

    &.withBox {
        position: relative;
        align-items: center;
        justify-content: flex-start;

        .image {
            position: absolute;
            z-index: 2;
            top: 50%;
            right: inherit;
            left: 0.25em;
            width: 2.5em;
            height: 2.5em;
            transform: translateY(-50%);
        }

        .label {
            z-index: 1;
            padding: 0.25em 0.4em 0.1em 3em;
            border-radius: 0.25em;
            background: white;
            color: black;
            text-shadow: none;
        }

        &.right {
            justify-content: flex-end;

            .image {
                right: 0.25em;
                left: auto;
                margin-right: 0;
                margin-left: 0.4em;
            }

            .label {
                padding: 0.25em 3em 0.1em 0.4em;
            }
        }

        &.withoutImage {
            .label {
                padding-left: 0.4em;
            }
        }
    }
}
