.container {
    --promotion-border-radius: 10px;

    width: 100%;

    .card {
        max-width: 400px;
        margin-left: auto;
        box-shadow: var(--dialog-box-shadow);
        font-size: 1rem;
    }

    @media (--small-viewport) {
        width: auto;
    }
}
