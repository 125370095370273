.container {
    .dialog {
       width: 100%;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .title {
        margin: 0;
        margin-bottom: var(--consent-modal-spacing);
        font-size: var(--consent-modal-font-size-title);
        text-transform: uppercase;
    }

    .description {
        font-size: var(--consent-modal-font-size-description);
        font-weight: 400;

        p {
            margin-top: 0;
            margin-bottom: var(--spacing);

            &:last-child {
                margin: 0;
            }
        }

        strong {
            font-weight: 500;
        }

        &.quiet {
            font-size: var(--consent-modal-font-size-description-quiet);
            font-weight: 400;
        }
    }

    .form {
        margin-top: var(--spacing-medium);
        font-size: var(--consent-modal-font-size-form);
    }

    .spacer {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: var(--consent-modal-spacing);
        margin-bottom: var(--consent-modal-spacing);
    }

    .or {
        display: inline-block;
        padding: 0 5px;
        margin: 0 auto;
        font-family: var(--font-tight);
        line-height: 1;
        text-transform: lowercase;
    }

    .line {
        display: inline-block;
        height: 1px;
        flex-grow: 1;
        background-color: var(--color-black);
    }

    .button {
        /* display: block;
        width: 100%;
        padding: 0.75em 1em;
        margin-bottom: var(--spacing);
        background-color: var(--color-black);
        color: var(--color-white);
        font-size: 18px; */
        width: 100%;
        text-decoration: underline;
    }
}
