.container {
    overflow: hidden;
    min-height: 100vh;
    background: var(--page-background);

    .header {
        margin-bottom: var(--spacing-large);
    }

    .featuredHeader {
        --card-color: #fff;
    }

    .bumper {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        height: 100lvh;
        opacity: 0;
        pointer-events: none;
        touch-action: none;
        transform: scale(2);
        transform-origin: 50% 50%;
        transition:
            opacity 0.2s ease-in,
            transform 0.1s ease-in;
    }

    .block {
        margin-bottom: var(--block-spacing);
    }

    &.bumperIsVisible {
        .bumper {
            /* display: block; */
            opacity: 1;
            transform: scale(1);
        }
    }
}
