.container {
    padding: 0;
    margin: 0;
    font-family: var(--typo-sponsor-content-font-family);
    font-weight: var(--typo-sponsor-content-font-weight);
    letter-spacing: 0.035em;
    line-height: 1.2;

    span,
    strong {
        font-weight: var(--typo-sponsor-content-strong);
    }
}
