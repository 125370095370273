.container {
    position: relative;

    .pin {
        display: block;
        width: 100%;
        height: auto;
        color: var(--color-beige);
        transition: color 0.5s ease-out;
    }

    &.toyota {
        color: #fff;

        .pin {
            color: #f00;
        }
    }

    .icons {
        position: absolute;
        top: 10%;
        left: 50%;
        display: flex;
        width: 60%;
        height: 44%;
        flex-direction: row;
        flex-wrap: wrap;
        transform: translate(-50%, 0);

        .icon {
            display: block;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            transition: color 0.5s ease-out;

            &.destinationsADecouvrir {
                transform: translate(0, 10%);
            }

            &.toyota {
                transform: scale(1.15);
            }
        }

        &.many {
            .icon {
                display: block;
                width: 50%;
                height: 50%;

                &.destinationsADecouvrir {
                    transform: translate(0, 0);
                }
            }
        }

        &.two {
            .icon {
                width: 100%;

                &.destinationsADecouvrir {
                    transform: translate(0, 0);
                }
            }
        }

        &.three {
            .icon:last-child {
                width: 100%;

                &.destinationsADecouvrir {
                    transform: translate(0, 0);
                }
            }
        }
    }
}
