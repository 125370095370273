.container {
    font-size: inherit;

    &.loading {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        padding-right: 0.5em;

        .loadingIcon {
            display: inline-block;
            margin-left: 0.5em;
        }
    }

    /* &.loading {
        position: relative;

        .loadingIcon {
            position: absolute;
            top: 50%;
            right: 0.5em;
            display: block;
            width: 1em;
            height: 1em;
            font-size: 1em;
            transform: translateY(-50%);
        }
    } */
}
