.container {
    position: relative;

    .shape {
        /* position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; */
    }

    .picture {
        position: absolute;
        z-index: 2;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        transition: opacity 0.5s ease-out;
    }

    &.top {
        object-position: top center;
    }

    &.topLeft {
        object-position: top left;
    }

    &.topRight {
        object-position: top right;
    }

    &.bottom {
        object-position: bottom center;
    }

    &.bottomLeft {
        object-position: bottom left;
    }

    &.bottomRight {
        object-position: bottom right;
    }

    &.left {
        object-position: center left;
    }

    &.right {
        object-position: center right;
    }

    &.placeholder {
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: pulse;
        animation-play-state: running;
        animation-timing-function: ease-in-out;
        background: rgba(200 200 200 / 0.6);
    }

    &.loaded {
        .picture {
            opacity: 1;
        }

        .placeholder {
            display: none;
        }
    }

    &.isEditor {
        .picture {
            position: absolute !important;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
        }

        img {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 1;
            transition: opacity 0.5s ease-out;
        }
    }

    [data-transitioning] &.placeholder {
        animation-play-state: paused;
    }
}

@keyframes pulse {
    0% {
        background: rgba(200 200 200 / 0.6);
    }

    50% {
        background: rgba(200 200 200 / 0.4);
    }

    100% {
        background: rgba(200 200 200 / 0.6);
    }
}
