.container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    min-height: 100svh;
    background: black;

    .video {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }
}
