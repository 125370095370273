.container {
    display: block;
    text-align: inherit;
    text-decoration: none;

    .inner {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .thumbnail {
        position: relative;
        z-index: 1;
        width: 100%;
    }

    .footer,
    .label {
        position: relative;
        z-index: 2;
        display: block;
        width: 100%;
    }
}
