.container {
    font-size: var(--card-font-size);

    .summary {
        margin-bottom: var(--spacing-medium);
        color: var(--card-brand-summary-color, var(--brand-primary-color));
    }

    .summaryDescription {
        margin-top: 0;
        font-size: 1.25rem;
        text-align: center;

        p {
            display: none;
            margin-bottom: 0;

            &:first-child {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
            }
        }
    }

    .title {
        margin-bottom: var(--spacing-small);
    }

    .list {
        --grid-spacing: var(--spacing);
    }

    .summaryImage {
        width: auto;
        max-width: none;
        height: 120px;
        max-height: none;
        margin: 0 auto;
    }

    .summaryPicture {
        position: relative;
        object-fit: cover;
    }

    /* &.boxTranslucent {
        padding: var(--spacing);
        border-radius: var(--card-border-radius);
        background: rgba(255, 255, 255, 0.25);
    } */
}
