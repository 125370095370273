.container {
    --control-background: var(--control-translucent-background-color);
    max-width: 40em;

    .form {
        padding-bottom: var(--spacing);

        &:last-of-type {
            padding-bottom: 0;
        }
    }

    .rowWrapper {
        margin-bottom: var(--block-spacing);
    }

    .row {
        display: flex;
        flex-direction: column;

        @media (--small-viewport) {
            flex-direction: row;
            margin: calc(var(--control-spacing) * -1);

            .form {
                width: 100%;
                max-width: var(--control-max-width);
                padding-bottom: 0;
                margin: var(--control-spacing);
            }
        }
    }

    .input {
        width: 100%;
        /* max-width: var(--control-max-width); */
    }

    .button {
        padding: 0;
        margin-top: var(--block-spacing);
        text-decoration: underline;
    }
}
