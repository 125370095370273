.container {
    overflow: hidden;
    border-radius: 50%;
    background: linear-gradient(180deg, #d9d9d9 0%, #fff 100%);

    .picture {
        border-radius: 50%;
    }

    .circle {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
    }

    &.withoutCircle {
        overflow: visible;
        border-radius: 0;
        background: transparent;

        .picture {
            border-radius: 0;
        }
    }
}
