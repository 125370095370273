.container {
    overflow: hidden;
    color: inherit;
    font-size: var(--card-font-size);
    text-decoration: none;

    .inner {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .thumbnail {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .title {
        position: relative;
        display: flex;
        width: 100%;
        padding-top: var(--card-spacing);
    }

    &.withBorder {
        border-bottom: var(--card-border-bottom);
    }

    &.big {
        font-size: var(--card-font-size-big);
    }

    &.small {
        font-size: var(--card-font-size-small);
    }

    &.labelOnTop {
        padding-top: 0;
        padding-bottom: var(--card-spacing);
        border-bottom: 0;

        .title {
            position: relative;
            display: flex;
            width: 100%;
        }
    }

    &.suggestion {
        border-bottom: 0;
        font-size: var(--card-font-size-suggestion);

        .thumbnail {
            overflow: hidden;
            border-radius: var(--card-border-radius);

            &::after {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                box-shadow: var(--card-emboss-box-shadow);
                content: '';
            }
        }
    }
}
