.container {
    color: var(--color-black);
    --promotion-background: rgba(255 255 255 / 0.6);

    .card {
        padding: var(--spacing-medium) var(--spacing);
        font-size: 1rem;
    }
    @media (--small-viewport) {
        .card {
            .inner {
                grid-template-areas:
                    'image content'
                    'image cta';
            }
        }
    }
}
