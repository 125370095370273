.container {
    .messages {
        position: fixed;
        z-index: var(--messages-z-index);
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        user-select: none;
    }

    .message {
        /* max-width: 20em; */
        padding: var(--spacing);
        border-radius: 10px;
        margin: var(--spacing);
        margin-bottom: var(--spacing);
        background: var(--messages-background, #fff);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        color: var(--messages-color, #000);
        text-align: center;

        &[data-theme="dialog"] {
            --messages-background: var(--dialog-background);
            --messages-color: var(--dialog-color);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
