.container {
    display: flex;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--ad-background, rgba(200 200 200 / 0.2));

    .inner {
        display: flex;
        overflow: hidden;
        width: auto;
        min-width: 100%;
        max-width: 100vw;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        padding: var(--spacing-small) var(--spacing) var(--spacing);
    }

    .title {
        margin-bottom: var(--spacing-small);
        color: #666;
        font-family: var(--typo-ad-font-family);
        font-size: 0.6rem;
        letter-spacing: 0.05em;
        line-height: 1.1;
        text-align: center;
        text-transform: uppercase;
    }

    .center {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
    }

    .adContainer {
        margin: 0 auto;
        will-change: width, height;
    }

    .richAdIframe {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        width: 1px;
        height: 1px;
        pointer-events: none;
        transform: translate(-50%, -50%);

        iframe {
            width: 1px;
            height: 1px;
        }
    }

    &.isFluid,
    &.hasWrapper {
        width: 100% !important;

        .inner {
            padding: 5px 0 0;
        }
    }

    &.isDisabled {
        display: none;
        visibility: hidden;
    }

    &.isEmpty {
        opacity: 0;

        .ad {
            overflow: hidden;
            height: 0;
            padding-bottom: 0;
            opacity: 0;
            will-change: auto;
        }

        .title {
            display: none;
        }

        .inner {
            width: 100%;
            padding: 0;
        }
    }

    &.withoutBorder {
        .inner {
            padding: 5px 0 0;
        }
    }

    &.isRendered {
        &.isDisabled {
            display: flex;
        }

        .ad {
            position: relative;
            will-change: auto;
        }

        &.isFluid {
            .inner {
                width: 100%;
            }

            .center {
                width: 100%;
            }

            .adContainer {
                width: 100%;
            }
        }
    }
}
