.container {
    .inner {
        display: flex;
        flex-direction: column;
        padding: 0 var(--side-spacing) 0;
    }

    .logoContainer,
    .sponsorContainer {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .logoContainer {
        margin: 0 auto;
        margin-top: 60px;
    }

    .urbania {
        position: absolute;
        top: var(--spacing);
        left: var(--spacing);
        display: block;
        width: auto;
        height: auto;
    }

    .urbaniaLogo {
        display: block;
        width: auto;
        height: var(--menu-urbania-size);
    }

    .sectionsMenu {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        margin-top: var(--spacing-large);
        font-size: 1.15rem;
    }

    .sections {
        max-width: 40em;
        justify-content: center;
        margin: -0.2em 0;
    }

    .section {
        display: flex;
        align-items: center;
        margin: 0.2em 0;
        color: var(--brand-primary-color);

        &::before {
            display: none;
        }

        &::after {
            display: block;
            margin: 0 0.5em;
            content: '·';
            font-size: 1em;
            font-weight: bold;
            line-height: 0.5em;
        }

        &:last-child::after {
            display: none;
        }
    }

    .logo {
        display: block;
        width: 100%;
        max-width: 600px;
        height: auto;
    }

    .sponsor {
        display: block;
        width: 80%;
        max-width: 300px;
        height: auto;
        margin-top: var(--spacing);
    }

    .button {
        font-family: var(--typo-section-title-font-family);
        font-style: var(--typo-section-title-font-style, normal);
        font-weight: var(--typo-section-title-font-weight);
        line-height: 1;
        text-decoration: none;
        text-transform: var(--typo-section-title-text-transform, uppercase);
    }

    &.hasVerticalLogo {
        .logo {
            display: none;
        }

        .logoVertical {
            display: block;
        }
    }

    [data-brand='urbania'] & {
        .logo {
            max-width: 200px;
        }
    }

    [data-brand='quatre95'] & {
        .logoContainer {
            margin-top: 80px;
        }

        .sponsorContainer {
            position: absolute;
            top: var(--spacing);
            left: var(--spacing);
            width: calc(100% - var(--spacing));
            justify-content: flex-start;
        }

        .sponsor {
            width: auto;
            height: 25px;
            margin-top: 0;
        }

        .sectionsMenu {
            margin-top: 30px;
        }
    }

    [data-brand='periodelibre'] & {
        .logoContainer {
            width: 80%;
            max-width: 400px;
        }
    }

    [data-brand='dehors'] & {
        .sponsor {
            width: 140px;
            margin-top: 20px;
        }
    }

    @media (--small-viewport) {
        .logoContainer {
            margin-top: 0;
            margin-top: 60px;
        }

        [data-brand='quatre95'] & {
            .inner {
                padding-top: 0;
            }
        }
    }

    @media (--medium-viewport) {
        .logoContainer {
            margin-top: 40px;
        }

        &.hasVerticalLogo {
            .logo {
                display: block;
            }

            .logoVertical {
                display: none;
            }
        }

        .sectionsMenu {
            font-size: 1.25rem;
        }

        [data-brand='mollo'] & {
            .logoContainer {
                margin-top: 20px;
            }
        }

        [data-brand='dehors'] & {
            .logoContainer {
                margin-top: 30px;
            }

            .sectionsMenu {
                font-size: 1.2rem;
            }
        }

        [data-brand='periodelibre'] & {
            .logoContainer {
                max-width: none;
                margin-top: 60px;
            }

            .logo {
                max-width: 800px;
            }
        }
    }

    @media (--large-viewport) {
        [data-brand='dehors'] & {
            .inner {
                position: relative;
            }

            .sponsor {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 140px;
                margin-top: 20px;
                transform: translate(250%, -66%);
            }
        }
    }
}
