.container {
    display: block;
    width: 100%;
    font-size: var(--card-font-size);
    text-decoration: none;

    .thumbnail {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        border-radius: 0;
    }

    .title {
        display: block;
        width: 100%;
        padding-top: var(--card-spacing);
        text-align: left;
    }

    &.withBorder {
        border-bottom: var(--card-border-bottom);
    }

    &.big {
        font-size: var(--card-font-size-big);
    }

    &.small {
        font-size: var(--card-font-size-small);
    }

    &.menu {
        display: block;
        width: 100%;
        border-bottom: 0;
        font-size: var(--card-font-size-menu);
        text-decoration: none;

        .thumbnail {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            border-radius: 50%;
        }

        .title {
            text-align: center;
        }
    }

    &.labelOnTop {
        .title {
            padding-top: 0;
            padding-bottom: var(--card-spacing);
        }
    }
}
