.container {
    font-size: 1rem;
}

.inner {
    display: flex;
    flex-direction: row;
}

.filter {
    margin-right: var(--spacing-small);

    &:last-child {
        margin-right: 0;
    }
}
