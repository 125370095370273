.container {
    position: relative;
    display: flex;
    min-height: 100svh;
    flex-direction: column;
    background: var(--page-background);
    transition: background-color 0.5s ease-out;

    .inner {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: var(--spacing-large) var(--side-spacing) var(--side-spacing);
    }

    .title {
        margin-top: auto;
        font-size: 2.5rem;
        text-align: center;
        text-transform: uppercase;
    }

    .description {
        margin-bottom: auto;
        font-size: 1.5rem;
        text-align: center;
    }

    .list {
        margin-top: var(--spacing-large);

        .items {
            padding: 0;
        }
    }

    @media (--medium-viewport) {
        .inner {
            padding: var(--side-spacing);
        }

        .title {
            font-size: 4rem;
        }

        .description {
            font-size: 2rem;
        }
    }
}
